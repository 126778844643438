/** @jsxRuntime classic */
/** @jsxFrag React.Fragment */
/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import { colors } from "../../helpers/styles";
import { CSSProperties } from "react";

export default function Check({
  checked,
  isCircle,
  handleClick,
  customStyle,
  theme,
}: {
  checked: boolean;
  isCircle?: boolean;
  handleClick?: () => void;
  customStyle?: CSSProperties;
  theme?: {
    primaryColor: string;
    secondaryColor?: string;
    size?: string;
  };
}) {
  let primaryColor = colors.blue;
  if (theme && theme.primaryColor) {
    primaryColor = theme.primaryColor;
  }
  let style = { size: "16px", fontSize: "19px", marginTop: "-3px" };
  if (theme && theme.size) {
    if (theme.size === "small") {
      style = { size: "14px", fontSize: "17px", marginTop: "-2px" };
    }
  }

  return (
    <div
      css={css`
        display: flex;
        width: ${style.size};
        height: ${style.size};
        line-height: ${style.size};
        font-size: ${style.fontSize};
        color: white;
        background: ${checked ? primaryColor : "white"};
        text-align: center;
        border-radius: ${isCircle ? style.size : "3px"};
        border: 1px solid ${checked ? primaryColor : "rgba(0,0,0,.38)"};
        transition: all 0.2s ease-in-out;
        box-sizing: border-box;
        :active {
          transform: scale(0.9);
        }
        :hover {
          cursor: pointer;
        }
      `}
      style={customStyle}
      onClick={handleClick}
    >
      {checked && (
        <span
          css={css`
            margin-top: ${style.marginTop};
            margin-left: -6.5px;
          `}
          className="icon-check"
        />
      )}
    </div>
  );
}
