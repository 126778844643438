import React, { CSSProperties } from "react";
import { css, select as $, media } from "glamor";
import { colors } from "../../helpers/styles";

export default function Search(props: {
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  name: string;
  placeholder: string;
  customStyle: CSSProperties;
  customIconStyle: CSSProperties;
  margin?: string;
  width?: string;
  theme?: {
    primaryColor: string;
  };
}) {
  const {
    value,
    onChange,
    name,
    placeholder,
    customStyle,
    customIconStyle,
    margin,
    width,
    theme,
  } = props;

  let primaryColor = colors.blue;
  if (theme && theme.primaryColor) {
    primaryColor = theme.primaryColor;
  }

  return (
    <div
      {...css({
        float: "left",
        position: "relative",
        color: "rgba(0, 0, 0, 0.38)",
        boxSizing: "border-box",
        lineHeight: "initial",
        margin,
        width,
      })}
    >
      <input
        autoComplete="off"
        {...css(
          {
            float: "left",
            width: "100%",
            paddingLeft: 43,
            borderRadius: 23,
            border: "1px solid #E2E2E2",
            lineHeight: "46px",
            fontSize: 16,
            fontWeight: 300,
            color: primaryColor,
            textShadow: "0px 0px 0px rgba(0, 0, 0, 0.38)",
            WebkitTextFillColor: "transparent",
            height: 46,
            boxSizing: "border-box",
            background: "#F9F9F9",
          },
          customStyle,
          $(":focus", {
            borderColor: primaryColor,
            outline: 0,
            textShadow: "0px 0px 0px rgba(0, 0, 0, 0.87)",
            background: "white",
          }),
          $("::placeholder", {
            textShadow: "none",
            WebkitTextFillColor: "initial",
          }),
          media("(max-width: 600px)", {
            height: 40,
            lineHeight: "40px",
            background: "#F9F9F9",
          }),
          $(":focus + span", {
            color: "rgba(0, 0, 0, 0.54)",
          })
        )}
        value={value}
        onChange={onChange}
        name={name}
        placeholder={placeholder}
        aria-label="search"
      />
      <span
        {...css(
          {
            position: "absolute",
            top: 12,
            left: 11,
            fontSize: 20,
            color: "rgba(0, 0, 0, 0.38)",
          },
          customIconStyle,
          media("(max-width: 600px)", {
            top: 8,
          })
        )}
        className="bi_interface-search"
      />
    </div>
  );
}
