// @flow
import React from "react";
import Moment from "moment-timezone";
import { getMonday, getWeek } from "../../helpers/functions";
import { colors } from "../../helpers/styles";
import { css, select as $, media } from "glamor";

const weekRow = {
  ...css(
    {
      float: "left",
      width: "24px",
      height: "42px",
      lineHeight: "42px",
      color: "rgba(0, 0, 0, 0.54)",
      textAlign: "center",
      fontSize: "12px",
      fontStyle: "italic",
      textDecoration: "underline",
      fontWeight: 300,
    },
    media("(max-width: 600px)", {
      display: "none",
    })
  ),
};

const dayRowStyle = {
  ...css(
    { float: "left", width: "calc(100% - 24px)" },
    media("(max-width: 600px)", {
      width: "100%",
    })
  ),
};

class Calendar extends React.Component {
  state = {
    calendarDate: this.props.initialDate ? this.props.initialDate : Date.now(),
  };

  getNextWeek = (prevMonday) => {
    const day = prevMonday;
    const nextWeekDay = Moment(day).add(7, "days");
    return nextWeekDay;
  };

  getRow = (week) => {
    let primaryColor = colors.blue;
    if (this.props.theme) {
      primaryColor = this.props.theme.primaryColor;
    }

    const row = week.map((d) => {
      const today = Moment();
      const selected = Moment(this.props.screenDate);

      const selectedDays = this.props.specificDaysSelected.filter((day) => {
        return Moment(d).isSame(day, "day");
      });

      let currentDayStyle;
      let currentMonthStyle;
      let selectedDayStyle;
      let selectedWeekStyle;
      if (Moment(d).isSame(today, "day")) {
        currentDayStyle = { fontWeight: "500 !important" };
      }
      if (selectedDays.length > 0) {
        selectedDayStyle = {
          background: primaryColor,
          color: "white !important",
          fontWeight: "500 !important",
        };
      }
      Moment(d).isSame(selected, "isoweek")
        ? (selectedWeekStyle = { background: "" })
        : (selectedWeekStyle = "");
      Moment(d).isSame(this.state.calendarDate, "month")
        ? (currentMonthStyle = "")
        : (currentMonthStyle = { color: "rgba(0,0,0,0.23) !important" });

      const dot = this.props.dots
        ? this.props.dots.includes(Moment(d).format("DD-MM-YYYY"))
        : false;

      let isDisabled = false;
      if (this.props.disablePast && d.isBefore(Moment(), "day")) {
        isDisabled = true;
      }

      return (
        <li
          key={d.format("D")}
          onClick={() => {
            if (!isDisabled) {
              this.props.setSpecificDay(
                d,
                selectedDays.length > 0 ? "remove" : "add"
              );
            }
          }}
          {...css(
            {
              ...currentMonthStyle,
              ...selectedWeekStyle,
              borderRadius: "5px",
              marginRight: this.props.size === "large" && 10,
              textAlign: "center",
              position: "relative",
              color: isDisabled ? "rgba(0,0,0,0.23) !important" : "inherit",
              ...currentDayStyle,
              ...selectedDayStyle,
            },
            $(":last-child", {
              marginRight: this.props.size === "large" && 0,
            }),
            $(":hover", {
              background:
                selectedDays.length > 0 || isDisabled
                  ? ""
                  : "rgba(0, 0, 0, 0.05)",
              cursor: isDisabled ? "default" : "pointer",
            })
          )}
        >
          {d.format("D")}
          {dot && (
            <span
              {...css({
                position: "absolute",
                background: colors.red,
                width: 4,
                height: 4,
                borderRadius: 4,
                left: 20,
                top: 32,
              })}
            />
          )}
        </li>
      );
    });
    return row;
  };

  switchMonths = (direction) => {
    const currentMonth = this.state.calendarDate;
    let newMonth;
    direction === "next"
      ? (newMonth = Moment(currentMonth).add(1, "months"))
      : (newMonth = Moment(currentMonth).subtract(1, "months"));
    const unixMonth = +newMonth;
    this.setState({ calendarDate: unixMonth });
  };

  render() {
    const date = new Date(this.state.calendarDate);

    const currentMonth = Moment(date).format("MMM");
    const currentYear = Moment(date).format("Y");

    const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    const firstM = getMonday(firstDay);

    const firstMonday = Moment(firstM);
    const secondMonday = this.getNextWeek(firstMonday);
    const thirdMonday = this.getNextWeek(secondMonday);
    const fourthMonday = this.getNextWeek(thirdMonday);
    const fifthMonday = this.getNextWeek(fourthMonday);

    const firstWeekNumber = Moment(firstMonday).format("WW");
    const secondWeekNumber = Moment(secondMonday).format("WW");
    const thirdWeekNumber = Moment(thirdMonday).format("WW");
    const fourthWeekNumber = Moment(fourthMonday).format("WW");
    const fifthWeekNumber = Moment(fifthMonday).format("WW");

    const firstWeek = getWeek(firstMonday);
    const secondWeek = getWeek(secondMonday);
    const thirdWeek = getWeek(thirdMonday);
    const fourthWeek = getWeek(fourthMonday);
    const fifthWeek = getWeek(fifthMonday);

    const firstRow = this.getRow(firstWeek);
    const secondRow = this.getRow(secondWeek);
    const thirdRow = this.getRow(thirdWeek);
    const fourthRow = this.getRow(fourthWeek);
    const fifthRow = this.getRow(fifthWeek);

    return (
      <div
        {...css(
          { float: "left", width: "100%", boxSizing: "border-box" },
          this.props.customStyle,
          media("(max-width: 600px)", {
            paddingLeft: this.props.size !== "medium" && "11px",
            paddingRight: this.props.size !== "medium" && "11px",
          })
        )}
      >
        <div
          className="filterbar_menu_calendar_header"
          {...css(
            {
              padding: "0px !important",
              paddingBottom: this.props.size === "large" && "10px !important",
              lineHeight: "39px !important",
              textAlign: "center",
            },
            media("(max-width: 600px)", {
              lineHeight: "60px !important",
              paddingBottom: "12px !important",
            })
          )}
        >
          <div
            className="filterbar_menu_calendar_header_left"
            {...css(
              {
                float: "left",
                border: "1px solid #E2E2E2",
                marginTop: 3,
                width: 32,
                height: 32,
                borderRadius: 3,
                boxSizing: "border-box",
                lineHeight: "32px",
              },
              media("(max-width: 600px)", {
                marginTop: 13,
              })
            )}
          >
            <span
              onClick={() => this.switchMonths("prev")}
              className="bi_interface-left"
              {...css({ color: "rgba(0,0,0,0.54)", cursor: "pointer" })}
            />
          </div>
          {currentMonth} {currentYear}
          <div
            className="filterbar_menu_calendar_header_right"
            {...css(
              {
                float: "right",
                border: "1px solid #E2E2E2",
                marginTop: 3,
                width: 32,
                height: 32,
                borderRadius: 3,
                boxSizing: "border-box",
                lineHeight: "32px",
              },
              media("(max-width: 600px)", {
                marginTop: 13,
              })
            )}
          >
            <span
              onClick={() => this.switchMonths("next")}
              className="bi_interface-right"
              {...css({ color: "rgba(0,0,0,0.54)", cursor: "pointer" })}
            />
          </div>
        </div>
        <div
          {...css(
            $(
              " ul",
              {
                margin: "0",
                padding: "0",
                listStyle: "none",
                float: "left",
                width:
                  this.props.size === "large"
                    ? "100%"
                    : this.props.size === "medium"
                    ? "calc(100% - 22px)"
                    : "auto",
              },
              media("(max-width: 600px)", {
                width:
                  this.props.size === "large" || this.props.size === "medium"
                    ? "100%"
                    : "auto",
              }),
              $(
                " li",
                {
                  display: "inline-block",
                  width: "42px",
                  height: "42px",
                  lineHeight: "42px",
                  fontSize: "14px",
                  fontWeight: "300",
                  color: "rgba(0,0,0,0.54)",
                  marginRight: this.props.size === "medium" && 10,
                },
                $(":last-child", {
                  marginRight: 0,
                }),
                media(
                  "(max-width: 600px)",
                  {
                    width: this.props.size === "medium" ? "13%" : "42px",
                    fontSize: "14px",
                    marginRight: this.props.size === "medium" ? "1.5%" : "4px",
                  },
                  $(":last-child", {
                    marginRight: "0px",
                  })
                ),
                media("(max-width: 370px)", {
                  fontSize: "14px",
                  marginRight: "3px",
                })
              )
            )
          )}
        >
          <div
            {...css(
              {
                float: "left",
                width: "100%",
                borderBottom: "1px solid #E2E2E2",
                color: "rgba(0,0,0,0.54)",
                paddingLeft: this.props.size === "medium" && 6,
                boxSizing: "border-box",
              },
              media("(max-width: 600px)", {
                paddingLeft: this.props.size === "medium" && 0,
              })
            )}
          >
            <div
              {...css(
                {
                  float: "left",
                  width: "24px",
                  fontSize: "12px",
                  height: "28px",
                  lineHeight: "28px",
                  marginRight: this.props.size === "large" && 10,
                  marginLeft: this.props.size === "large" && 6,
                  textAlign:
                    this.props.size === "large" || this.props.size === "medium"
                      ? "right"
                      : "",
                  textDecoration: "underline",
                },
                media("(max-width: 600px)", {
                  display: "none",
                })
              )}
            >
              wk
            </div>
            <ul
              {...css(
                {
                  float: "left",
                  width:
                    this.props.size === "medium"
                      ? "calc(100% - 24px) !important"
                      : "auto",
                },
                media("(max-width: 600px)", {
                  width: this.props.size === "medium" && "100% !important",
                  fontSize: 12,
                }),
                $(
                  " li",
                  {
                    lineHeight: "28px !important",
                    height: "28px !important",
                    border: "0",
                    fontSize: "12px !important",
                    marginRight:
                      this.props.size === "large"
                        ? 10
                        : this.props.size === "medium" && 5,
                    textAlign: "center",
                    fontWeight: "500 !important",
                  },
                  $(":last-child", {
                    marginRight: this.props.size === "large" && 0,
                  }),
                  media("(max-width: 600px)", {
                    fontSize: "12px !important",
                    height: "28px !important",
                    lineHeight: "28px !important",
                    width: this.props.size === "medium" ? "13%" : "42px",
                    marginRight: this.props.size === "medium" ? "1.5%" : "4px",
                  })
                )
              )}
            >
              <li>Mo</li>
              <li>Tu</li>
              <li>We</li>
              <li>Th</li>
              <li>Fr</li>
              <li>Sa</li>
              <li>Su</li>
            </ul>
          </div>
          <div
            {...css(
              {
                float: "left",
                width: "100%",
                marginBottom: this.props.size === "large" && 6,
                paddingLeft: this.props.size === "medium" && 6,
              },
              media("(max-width: 600px)", {
                paddingLeft: this.props.size === "medium" && 0,
              })
            )}
          >
            <div
              {...css(weekRow, {
                paddingLeft: this.props.size === "large" && "6px !important",
                cursor: "default",
              })}
            >
              {firstWeekNumber}
            </div>
            <div
              {...css(dayRowStyle, {
                width:
                  this.props.size === "large" && "calc(100% - 30px) !important",
              })}
            >
              <ul
                {...css({
                  paddingLeft: this.props.size === "large" && "10px !important",
                })}
              >
                {firstRow}
              </ul>
            </div>
          </div>
          <div
            {...css(
              {
                float: "left",
                width: "100%",
                marginBottom: this.props.size === "large" && 6,
                paddingLeft: this.props.size === "medium" && 6,
              },
              media("(max-width: 600px)", {
                paddingLeft: this.props.size === "medium" && 0,
              })
            )}
          >
            <div
              {...css(weekRow, {
                paddingLeft: this.props.size === "large" && "6px !important",
                cursor: "default",
              })}
            >
              {secondWeekNumber}
            </div>
            <div
              {...css(dayRowStyle, {
                width:
                  this.props.size === "large" && "calc(100% - 30px) !important",
              })}
            >
              <ul
                {...css({
                  paddingLeft: this.props.size === "large" && "10px !important",
                })}
              >
                {secondRow}
              </ul>
            </div>
          </div>
          <div
            {...css(
              {
                float: "left",
                width: "100%",
                marginBottom: this.props.size === "large" && 6,
                paddingLeft: this.props.size === "medium" && 6,
              },
              media("(max-width: 600px)", {
                paddingLeft: this.props.size === "medium" && 0,
              })
            )}
          >
            <div
              {...css(weekRow, {
                paddingLeft: this.props.size === "large" && "6px !important",
                cursor: "default",
              })}
            >
              {thirdWeekNumber}
            </div>
            <div
              {...css(dayRowStyle, {
                width:
                  this.props.size === "large" && "calc(100% - 30px) !important",
              })}
            >
              <ul
                {...css({
                  paddingLeft: this.props.size === "large" && "10px !important",
                })}
              >
                {thirdRow}
              </ul>
            </div>
          </div>
          <div
            {...css(
              {
                float: "left",
                width: "100%",
                marginBottom: this.props.size === "large" && 6,
                paddingLeft: this.props.size === "medium" && 6,
              },
              media("(max-width: 600px)", {
                paddingLeft: this.props.size === "medium" && 0,
              })
            )}
          >
            <div
              {...css(weekRow, {
                paddingLeft: this.props.size === "large" && "6px !important",
                cursor: "default",
              })}
            >
              {fourthWeekNumber}
            </div>
            <div
              {...css(dayRowStyle, {
                width:
                  this.props.size === "large" && "calc(100% - 30px) !important",
              })}
            >
              <ul
                {...css({
                  paddingLeft: this.props.size === "large" && "10px !important",
                })}
              >
                {fourthRow}
              </ul>
            </div>
          </div>
          <div
            {...css(
              {
                float: "left",
                width: "100%",
                marginBottom: this.props.size === "large" && 6,
                paddingLeft: this.props.size === "medium" && 6,
              },
              media("(max-width: 600px)", {
                paddingLeft: this.props.size === "medium" && 0,
              })
            )}
          >
            <div
              {...css(weekRow, {
                paddingLeft: this.props.size === "large" && "6px !important",
                cursor: "default",
              })}
            >
              {fifthWeekNumber}
            </div>
            <div
              {...css(dayRowStyle, {
                width:
                  this.props.size === "large" && "calc(100% - 30px) !important",
              })}
            >
              <ul
                {...css({
                  paddingLeft: this.props.size === "large" && "10px !important",
                })}
              >
                {fifthRow}
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Calendar;
