import { useContext, useState } from "react";
import { gql, useMutation } from "@apollo/client";
import Moment from "moment";
import { AnimatePresence, motion } from "framer-motion";
import produce from "immer";

import Button from "../toolBox/Button";
import Input from "../toolBox/Input";
import InputField from "../toolBox/InputField";
import Modal from "../toolBox/Modal";

import styles from "./Forecast.module.css";
import { colors } from "../../helpers/styles";

import { GET_DATASET } from "./Dataset";
import { UserContext } from "../App";

const tooltip = {
  customStyle: {
    width: 240,
    left: "-40px !important",
    top: "25px !important",
    boxSizing: "border-box",
  },
  customArrowStyle: {
    left: "45px !important",
  },
};

const targetServiceLevel = {
  label: "Target Service Level",
  value: 80,
  patternSignifier: "%",
  tooltip: {
    text: "The percentage of interactions to handle within your defined threshold. For example, if your threshold is 20 seconds, 80% means resolving 80% of interactions within that time.",
    ...tooltip,
  },
  validation: {
    required: true,
    conditions: [
      {
        condition: (value: number) => value < 0 || value > 100,
        text: "This value must be between 0% and 100%.",
      },
    ],
  },
};
const serviceLevelThreshold = {
  label: "Service Level Treshold",
  value: 20,
  patternSignifier: "sec",
  tooltip: {
    text: "The time to handle a percentage of interactions. For example, if your service level target is 80%, 20 seconds means resolving 80% of interactions within that time.",
    ...tooltip,
  },
  validation: {
    required: true,
    conditions: [
      {
        condition: (value: number) => value < 1,
        text: "This value must be 1 or greater.",
      },
    ],
  },
};
const averageHandlingTime = {
  label: "Average Handling Time",
  value: 300,
  patternSignifier: "sec",
  tooltip: {
    text: "The average time it takes to handle one interaction, including talk time, hold time, and post-interaction tasks like follow-ups and note-taking.",
    ...tooltip,
    customStyle: {
      ...tooltip.customStyle,
      left: "-50px !important",
    },
    customArrowStyle: {
      ...tooltip.customArrowStyle,
      left: "55px !important",
    },
  },
  validation: {
    required: true,
    conditions: [
      {
        condition: (value: number) => value < 1,
        text: "This value must be 1 or greater.",
      },
    ],
  },
};
const averageHandlingTimeLinear = {
  label: "Average Handling Time",
  value: 300,
  patternSignifier: "min",
  tooltip: {
    text: "The average time it takes to handle one interaction, including talk time, hold time, and post-interaction tasks like follow-ups and note-taking.",
    ...tooltip,
    customStyle: {
      ...tooltip.customStyle,
      left: "-50px !important",
    },
    customArrowStyle: {
      ...tooltip.customArrowStyle,
      left: "55px !important",
    },
  },
  validation: {
    required: true,
  },
};
const averagePatienceTime = {
  label: "Average Patience Time",
  value: 120,
  patternSignifier: "sec",
  tooltip: {
    text: "The average time a customer is willing to wait before abandoning the queue.",
    ...tooltip,
    customStyle: {
      ...tooltip.customStyle,
      left: "-50px !important",
    },
    customArrowStyle: {
      ...tooltip.customArrowStyle,
      left: "55px !important",
    },
  },
  validation: {
    required: true,
    conditions: [
      {
        condition: (value: number) => value < 1,
        text: "This value must be 1 or greater.",
      },
    ],
  },
};
const concurrency = {
  label: "Concurrency",
  value: 1,
  patternSignifier: "#",
  tooltip: {
    text: "The number of interactions an agent can handle simultaneously (e.g., 1 for calls, 2-3 for chat).",
    ...tooltip,
  },
  validation: {
    required: true,
    conditions: [
      {
        condition: (value: number) => value < 1 || value > 10,
        text: "This value must be between 1 and 10.",
      },
    ],
  },
  warnings: [
    {
      text: "Handling more than 5 concurrent interactions may impact service quality and agent performance.",
      condition: (value: number) => value > 5 && value < 11,
    },
  ],
};
const relativeOccupancy = {
  label: "Relative Occupancy",
  value: 85,
  patternSignifier: "%",
  tooltip: {
    text: "The percentage of time agents spend actively handling each individual interaction, accounting for breaks and recovery time.",
    ...tooltip,
  },
  validation: {
    required: true,
    conditions: [
      {
        condition: (value: number) => value < 1 || value > 100,
        text: "This value must be between 1% and 100%.",
      },
    ],
  },
  warnings: [
    {
      text: "This is a high occupancy level. Consider reducing for better service quality.",
      condition: (value: number) => value > 85 && value < 101,
    },
  ],
};
const shrinkage = {
  label: "Shrinkage",
  value: 30,
  patternSignifier: "%",
  tooltip: {
    text: "The percentage of agent time lost to non-productive activities like training, breaks, or meetings.",
    ...tooltip,
  },
  validation: {
    required: true,
    conditions: [
      {
        condition: (value: number) => value < 0 || value > 100,
        text: "This value must be between 0% and 100%.",
      },
    ],
  },
  warnings: [
    {
      text: "Shrinkage is unusually low. Ensure all factors like breaks, training, and absenteeism are accounted for.",
      condition: (value: number) => value < 15 && value >= 0,
    },
    {
      text: "Shrinkage is higher than typical. Review efficiency factors to identify potential issues.",
      condition: (value: number) => value > 35 && value < 41,
    },
    {
      text: "Shrinkage is critically high. Productivity may be significantly impacted.",
      condition: (value: number) => value > 40 && value < 101,
    },
  ],
};
const targetResponseTime = {
  label: "Target Response Time",
  value: 120,
  patternSignifier: "min",
  tooltip: {
    text: "The maximum time allowed to respond to a case. Optional for work without strict response time requirements.",
    ...tooltip,
  },
  validation: {
    required: false,
    conditions: [
      {
        condition: (value: number) => value < 1,
        text: "This value must be 1 or greater.",
      },
    ],
  },
};
const productivityFactor = {
  label: "Productivity Factor",
  value: 80,
  patternSignifier: "%",
  tooltip: {
    text: "The efficiency rate at which agents complete work compared to standard expectations. Accounts for experience levels, tool efficiency, and process maturity.",
    ...tooltip,
  },
  validation: {
    required: true,
    conditions: [
      {
        condition: (value: number) => value < 0 || value > 100,
        text: "This value must be between 0% and 100%.",
      },
    ],
  },
  warnings: [
    {
      text: "Productivity factor above 95% may be unrealistic as it assumes near-perfect efficiency.",
      condition: (value: number) => value > 95 && value < 101,
    },
    {
      text: "Productivity factor below 60% suggests operational issues. Review training, processes, or tools.",
      condition: (value: number) => value < 60 && value > 0,
    },
  ],
};
const minimumStaffing = {
  label: "Minimum Staffing",
  value: null,
  patternSignifier: "FTE",
  tooltip: {
    text: "The minimum number of staff required during operating hours, regardless of volume. This is an optional setting to ensure baseline coverage.",
    ...tooltip,
  },
  validation: {
    required: false,
    conditions: [
      {
        condition: (min: number, max: number) => min && max && min > max,
        text: "Min. staffing cannot be greater than max. staffing.",
      },
      {
        condition: (value: number | null) => value < 1 && value !== null,
        text: "This value must be 1 or greater.",
      },
    ],
  },
};
const maximumStaffing = {
  label: "Maximum Staffing",
  value: null,
  patternSignifier: "FTE",
  tooltip: {
    text: "The maximum number of staff permitted during operating hours to cap resource allocation. This is an optional setting.",
    ...tooltip,
  },
  validation: {
    required: false,
    conditions: [
      {
        condition: (max: number, min: number) => max && min && max < min,
        text: "Max. staffing must be greater than or equal to min. staffing.",
      },
      {
        condition: (value: number | null) => value < 1 && value !== null,
        text: "This value must be 1 or greater.",
      },
    ],
  },
};

const STAFFING_PARAMETERS = {
  erlang_c: [
    {
      type: "header",
      name: "Service Level Goals",
    },
    targetServiceLevel,
    serviceLevelThreshold,
    {
      type: "header",
      name: "Channel Settings",
    },
    averageHandlingTime,
    concurrency,
    {
      type: "header",
      name: "Efficiency Settings",
    },
    relativeOccupancy,
    shrinkage,
    {
      type: "header",
      name: "Staffing Constraints",
    },
    minimumStaffing,
    maximumStaffing,
  ],
  erlang_a: [
    {
      type: "header",
      name: "Service Level Goals",
    },
    targetServiceLevel,
    serviceLevelThreshold,
    {
      type: "header",
      name: "Channel Settings",
    },
    averageHandlingTime,
    averagePatienceTime,
    concurrency,
    {
      type: "header",
      name: "Efficiency Settings",
    },
    relativeOccupancy,
    shrinkage,
    {
      type: "header",
      name: "Staffing Constraints",
    },
    minimumStaffing,
    maximumStaffing,
  ],
  linear: [
    {
      type: "header",
      name: "Channel Settings",
    },
    averageHandlingTimeLinear,
    targetResponseTime,
    concurrency,
    {
      type: "header",
      name: "Efficiency Settings",
    },
    productivityFactor,
    shrinkage,
    {
      type: "header",
      name: "Staffing Constraints",
    },
    minimumStaffing,
    maximumStaffing,
  ],
};

export default function StaffingParameters(props: {
  id: string;
  handleClose: () => void;
  staffingParameters: any;
  refetchVariables: {
    id: string;
    startDate: string;
    endDate: string;
    comparisonStartDate: string;
    comparisonEndDate: string;
  };
}) {
  const currentUser = useContext(UserContext);
  const [staffingParameters, setStaffingParameters] = useState(
    props.staffingParameters
  );
  const [discardModal, setDiscardModal] = useState(false);
  const [updateDataset, { loading }] = useMutation(UPDATE_DATASET, {
    refetchQueries: [
      {
        query: GET_DATASET,
        variables: props.refetchVariables,
      },
    ],
    awaitRefetchQueries: true,
  });

  function handleClose() {
    if (hasChanges) {
      setDiscardModal(true);
    } else {
      props.handleClose();
    }
  }

  async function handleUpdate() {
    const copy = { ...staffingParameters };
    copy.updated_at = new Date().toISOString();
    copy.updated_by = currentUser.name + " (" + currentUser.email + ")";
    await updateDataset({
      variables: {
        id: props.id,
        staffingParameters: copy,
      },
    });
  }

  const calculationMethod = staffingParameters.calculation_method
    .toLowerCase()
    .replace(" ", "_");

  function handleChange(value: string, label: string) {
    const labelLower = label.toLowerCase().replaceAll(" ", "_");
    const updatedStaffingParameters = produce(staffingParameters, (draft) => {
      const isNumber = !isNaN(Number(value));
      if (isNumber) {
        draft[calculationMethod][labelLower] =
          value || value === "0" ? Number(value) : "";
      }
    });
    setStaffingParameters(updatedStaffingParameters);
  }

  const parameters = STAFFING_PARAMETERS[
    calculationMethod as keyof typeof STAFFING_PARAMETERS
  ].map((parameter) => {
    const label = parameter.label?.toLowerCase().replaceAll(" ", "_");
    const value = staffingParameters[calculationMethod][label];
    let isInvalid = false;
    let hasWarning = false;
    let metaText = "";
    let isOptional = false;
    let placeholder = "Enter value";
    if (parameter.validation) {
      const { required, conditions } = parameter.validation;
      if (!required) {
        placeholder = "-";
        isOptional = true;
      }
      isInvalid = required && !value && value !== 0;
      if (isInvalid) {
        metaText = "This field is required.";
      } else {
        if (conditions) {
          let secondValue = null;
          if (parameter.label === "Minimum Staffing") {
            secondValue =
              staffingParameters[calculationMethod]["maximum_staffing"];
          }
          if (parameter.label === "Maximum Staffing") {
            secondValue =
              staffingParameters[calculationMethod]["minimum_staffing"];
          }
          const condition = conditions.filter((condition) =>
            condition.condition(
              value ? Number(value) : null,
              secondValue ? Number(secondValue) : null
            )
          );
          isInvalid = condition[0]?.text ? true : false;
          metaText = condition[0]?.text || metaText;
        }
      }
    }
    if (parameter.warnings && !metaText) {
      const warning = parameter.warnings.filter((warning) =>
        warning.condition(Number(value))
      );
      hasWarning = warning[0]?.text ? true : false;
      metaText = warning[0]?.text || metaText;
    }
    return {
      ...parameter,
      placeholder,
      value,
      isInvalid,
      hasWarning,
      metaText,
      isOptional,
    };
  });

  let hasChanges = false;
  Object.keys(STAFFING_PARAMETERS).forEach((calculationMethod) => {
    for (const parameter of STAFFING_PARAMETERS[
      calculationMethod as keyof typeof STAFFING_PARAMETERS
    ]) {
      const label = parameter.label?.toLowerCase().replaceAll(" ", "_");
      const currentValue = staffingParameters[calculationMethod][label]
        ? Number(staffingParameters[calculationMethod][label])
        : null;
      const propsValue = props.staffingParameters[calculationMethod][label]
        ? Number(props.staffingParameters[calculationMethod][label])
        : null;

      if (currentValue !== propsValue) {
        hasChanges = true;
      }
    }
  });
  if (
    staffingParameters.calculation_method !==
    props.staffingParameters.calculation_method
  ) {
    hasChanges = true;
  }

  const isDisabled =
    parameters.some((parameter) => parameter.isInvalid) || !hasChanges;

  return (
    <>
      <AnimatePresence>
        <motion.div
          style={{
            width: 375,
            position: "relative",
            background: "white",
            borderLeft: "1px solid #E0E0E0",
            boxSizing: "border-box",
          }}
          initial={{ right: -70 }}
          animate={{ right: 0 }}
          exit={{ right: -70 }}
          transition={{
            duration: 0.5,
            ease: [0, 1, 0.5, 1],
          }}
        >
          <div className={styles["staffing-parameters-header"]}>
            Staffing parameters
            <span className="bi_interface-cross" onClick={handleClose} />
          </div>
          <div className={styles["staffing-parameters-body"]}>
            <InputField
              label="Calculation Method"
              value={staffingParameters.calculation_method}
              options={[
                "Erlang C",
                "Erlang A",
                // "Linear" // temporarily disabled, as we don't have a clear use-case right now
              ].map((key) => ({
                id: key.replace("_", " "),
                name: key,
                action: () => {
                  const copy = { ...staffingParameters };
                  copy.calculation_method = key;
                  setStaffingParameters(copy);
                },
                selected: key === staffingParameters.calculation_method,
              }))}
              // disabled
            />
            {parameters.map((parameter) => {
              if (parameter.type === "header") {
                return (
                  <div
                    key={parameter.name}
                    style={{ fontSize: 14, fontWeight: "bold" }}
                  >
                    {parameter.name}
                  </div>
                );
              }

              let label = parameter.label;
              if (parameter.isOptional) {
                label = label + " (optional)";
              }
              return (
                <Input
                  key={parameter.label}
                  label={label}
                  value={parameter.value}
                  placeholder={parameter.placeholder}
                  patternSignifier={parameter.patternSignifier}
                  tooltip={parameter.tooltip ? parameter.tooltip : undefined}
                  handleChange={(e) => {
                    handleChange(e.target.value, parameter.label);
                  }}
                  isInvalid={parameter.isInvalid}
                  hasWarning={parameter.hasWarning}
                  metaText={parameter.metaText}
                  // disabled={true}
                />
              );
            })}
            {staffingParameters.updated_by && staffingParameters.updated_at && (
              <div
                style={{
                  fontSize: 14,
                  fontWeight: 300,
                  lineHeight: "17px",
                  color: "rgba(0,0,0,0.87",
                }}
              >
                Last edited by {staffingParameters.updated_by} on{" "}
                {Moment(staffingParameters.updated_at).format(
                  `ddd, D MMM, ${currentUser.timeFormat === "12-hour" ? "h:mma" : "HH:mm"}`
                )}
              </div>
            )}
          </div>
          <div
            style={{
              position: "absolute",
              bottom: 0,
              width: "100%",
              height: 70,
              borderTop: "1px solid #E0E0E0",
              boxSizing: "border-box",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "0 16px",
              background: "white",
            }}
          >
            <Button
              name="Cancel"
              theme="grey-border"
              size="large"
              action={handleClose}
            />
            <Button
              name="Update"
              theme="blue"
              size="large"
              disabled={isDisabled}
              action={handleUpdate}
              loading={loading}
            />
          </div>
        </motion.div>
      </AnimatePresence>
      {discardModal && (
        <Modal
          title="Discard updates"
          body={
            <p>
              Are you sure you want to discard the updates made to the staffing
              parameters?
              <br />
              <span style={{ fontWeight: "500", textDecoration: "underline" }}>
                You can't undo this action.
              </span>
            </p>
          }
          color={colors.red}
          buttonOneName="Cancel"
          buttonTwoName="Discard"
          buttonOneAction={() => setDiscardModal(false)}
          buttonTwoAction={props.handleClose}
          buttonOneTheme={"grey-border"}
          buttonTwoTheme={"red"}
        />
      )}
    </>
  );
}

const UPDATE_DATASET = gql`
  mutation UpdateDataset($id: ID!, $staffingParameters: JSON) {
    updateDataset(id: $id, staffingParameters: $staffingParameters) {
      id
      staffingParameters
    }
  }
`;
