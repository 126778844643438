import React from "react";
import { css, select as $ } from "glamor";
import { colors } from "../../helpers/styles";
import ColorCube from "./ColorCube";

import ToolTip from "./ToolTip";
import OutsideAlerter from "../clickOutside/OutsideAlerter";

const errorText = {
  position: "absolute",
  left: 0,
  top: 34,
  fontSize: "14px",
  lineHeight: "17px",
  fontWeight: "500",
  height: "20px",
  color: colors.red,
};

class Dropdown extends React.Component {
  render() {
    const {
      options,
      handleFocus,
      emptyText,
      customDropdownStyle,
      primaryColor,
    } = this.props;
    const list = options.map((item) => {
      return (
        <li
          key={item.id}
          {...css(
            {
              float: "left",
              width: "100%",
              boxSizing: "border-box",
              padding: "3px 6px",
              lineHeight: "20px",
              fontSize: 14,
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
            },
            $(":hover", {
              background: "rgba(0, 0, 0, 0.05)",
              borderRadius: 3,
              cursor: "pointer",
              fontWeight: 500,
            }),
            customDropdownStyle
          )}
          onClick={() => {
            item.action(item.name);
            handleFocus();
          }}
          style={{
            color: item.selected ? primaryColor : "rgba(0, 0, 0, 0.87)",
            fontWeight: item.selected && 500,
          }}
        >
          {item.color && (
            <ColorCube color={item.color} marginTop={1} marginLeft={2} />
          )}
          {item.name}
        </li>
      );
    });
    return (
      <div
        {...css({
          position: "absolute",
          width: "100%",
          background: "white",
          borderRadius: "5px",
          zIndex: 1,
          color: "rgba(0,0,0,0.87)",
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
          top: 37,
          left: 0,
          fontWeight: 300,
        })}
      >
        {options.length > 0 || !emptyText ? (
          <ul
            {...css(
              {
                float: "left",
                width: "100%",
                listStyle: "none",
                padding: 6,
                boxSizing: "border-box",
                margin: 0,
                maxHeight: 200,
                overflowY: "scroll",
              },
              $("::-webkit-scrollbar", {
                display: "none",
              })
            )}
            onScroll={(e) => e.stopPropagation()}
          >
            {list}
          </ul>
        ) : (
          <div>{emptyText}</div>
        )}
      </div>
    );
  }
}

class InputField extends React.Component {
  state = {
    focus: false,
    dropdownMenu: false,
  };

  onBlur = () => {
    this.setState({ focus: false });
    if (this.props.handleBlur) this.props.handleBlur();
  };

  onFocus = () => {
    this.props.error && this.props.handleFocus && this.props.handleFocus();
    this.setState({ focus: true });
  };

  copyLink = () => {
    let textArea = document.createElement("textarea");
    textArea.innerText = this.props.copyLink;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand("copy");
    textArea.remove();
  };

  render() {
    const {
      customStyle,
      customInputStyle,
      customDropdownStyle,
      error,
      disabled,
      options,
      handleClick,
      autoFocus,
      emptyText,
      noArrow,
      notDisabled,
      noTyping,
      removeButton,
      externalFocus,
      cursor,
      toolTip,
      toolTipLink,
      description,
      copyLink,
      ariaLabel,
      paddingRight,
      intercomTarget,
      theme,
    } = this.props;

    const count = this.props.value && this.props.value.length;
    let charLimit;
    if (this.props.charLimit) {
      charLimit = true;
      if (this.props.charLimitOnlyWhenExceeded) {
        charLimit = count > this.props.charLimit;
      }
    }

    let isDisabled = false;
    if (disabled) {
      isDisabled = true;
    }
    if (options || noTyping) {
      isDisabled = true;
    }
    if (notDisabled) {
      isDisabled = false;
    }

    const { focus, dropdownMenu } = this.state;

    let primaryColor = colors.blue;
    if (theme && theme.primaryColor) {
      primaryColor = theme.primaryColor;
    }

    return (
      <div
        {...css(
          { float: "left", width: "100%", height: 50 },
          customStyle,
          $(":hover .input-field-arrow", {
            color: "rgba(0,0,0,0.87)",
          })
        )}
      >
        <label
          {...css({
            fontSize: 14,
            lineHeight: "17px",
            height: 17,
            fontWeight: 500,
            color: error
              ? colors.red
              : focus || dropdownMenu || externalFocus
              ? primaryColor
              : "rgba(0, 0, 0, 0.54)",
          })}
        >
          {this.props.label}{" "}
          {toolTip && (
            <ToolTip
              text={toolTip}
              link={toolTipLink}
              customStyle={{
                marginTop: 22,
                marginLeft: "-20px",
                width: 240,
              }}
              customArrowStyle={{
                left: 17,
              }}
            />
          )}
        </label>
        {copyLink && (
          <span
            {...css(
              {
                float: "right",
                fontSize: 14,
                lineHeight: "17px",
                color: primaryColor,
                cursor: "pointer",
              },
              $(":hover", {
                textDecoration: "underline",
              })
            )}
            onClick={this.copyLink}
          >
            Copy
          </span>
        )}
        <div {...css({ position: "relative" })}>
          {this.props.children ? this.props.children : ""}
          {this.props.immutable || this.props.readOnly ? (
            <div
              {...css({
                borderBottom: this.props.error
                  ? `1px solid ${colors.red}`
                  : !this.props.readOnly && "1px solid rgba(226, 226, 226, 1)",
                height: 33,
                lineHeight: "33px",
                color: "rgba(0,0,0,0.87)",
                fontWeight: 300,
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              })}
            >
              {this.props.value}
            </div>
          ) : (
            <div
              onClick={
                options
                  ? () => {
                      handleClick && !disabled && handleClick();
                      !disabled && this.setState({ dropdownMenu: true });
                    }
                  : handleClick && !disabled
                  ? () => handleClick()
                  : () => {}
              }
            >
              <input
                data-intercom-target={intercomTarget}
                autoComplete="off"
                aria-label={ariaLabel}
                autoFocus={autoFocus}
                onFocus={this.onFocus}
                onBlur={this.onBlur}
                name={this.props.fieldName}
                type={this.props.type}
                onChange={this.props.changeInput}
                value={this.props.value}
                placeholder={this.props.placeholder}
                disabled={isDisabled}
                {...css(
                  {
                    float: "left",
                    border: 0,
                    borderBottom: this.props.error
                      ? `1px solid ${colors.red}`
                      : dropdownMenu || externalFocus
                      ? `1px solid ${primaryColor}`
                      : "1px solid rgba(226, 226, 226, 1)",
                    fontFamily: "Museo Sans",
                    fontSize: 16,
                    color: "rgba(0,0,0,0.87)",
                    width: "100%",
                    height: 33,
                    fontWeight: 300,
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    boxSizing: "border-box",
                    paddingRight: paddingRight
                      ? paddingRight
                      : removeButton
                      ? 50
                      : options
                      ? 25
                      : 0,
                    cursor: cursor
                      ? cursor
                      : options && !disabled
                      ? "pointer"
                      : "text",
                  },
                  $("::-webkit-input-placeholder", {
                    textShadow: "none",
                    WebkitTextFillColor: "initial",
                  }),
                  $(":focus", {
                    outline: 0,
                    borderBottom: this.props.error
                      ? `1px solid ${colors.red}`
                      : `1px solid ${primaryColor}`,
                  }),
                  $(":disabled", {
                    background: "white",
                  }),
                  customInputStyle
                )}
                onClick={() => {
                  if (copyLink && isDisabled) {
                    this.copyLink();
                  }
                }}
                data-testid="showDropdown"
              />
              {isDisabled && (
                <div
                  {...css({
                    position: "absolute",
                    width: "100%",
                    height: 33,
                    cursor: cursor ? cursor : "pointer",
                  })}
                  onClick={
                    options
                      ? () => {
                          handleClick && !disabled && handleClick();
                          !disabled && this.setState({ dropdownMenu: true });
                        }
                      : handleClick && !disabled
                      ? () => handleClick()
                      : () => {}
                  }
                />
              )}
              {removeButton && !disabled && this.props.value && (
                <div
                  className="tooltip"
                  {...css({
                    position: "absolute",
                    right: 25,
                    top: 5,
                  })}
                >
                  <span
                    {...css(
                      {
                        fontSize: 18.75,
                        cursor: "pointer",
                        color: "rgba(0, 0, 0, 0.38)",
                      },
                      $(":hover", {
                        color: colors.red,
                      })
                    )}
                    className="bi_interface-circle-cross"
                    onClick={(e) => {
                      e.stopPropagation();
                      removeButton();
                    }}
                  />
                  <span
                    {...css({ marginLeft: "-33px !important", marginTop: 5 })}
                    className="tooltiptext leave-details-remove-tooltip"
                  >
                    Remove
                  </span>
                </div>
              )}
              {options && !noArrow && !disabled && (
                <span
                  {...css({
                    position: "absolute",
                    right: -4,
                    top: 9,
                    fontSize: 18.75,
                    cursor: "pointer",
                    color: "rgba(0,0,0,0.54)",
                  })}
                  className="bi_interface-bottom input-field-arrow"
                />
              )}
            </div>
          )}
          {dropdownMenu && (
            <OutsideAlerter
              action={() => this.setState({ dropdownMenu: false })}
            >
              <Dropdown
                options={options}
                handleFocus={() => this.setState({ dropdownMenu: false })}
                emptyText={emptyText}
                customDropdownStyle={customDropdownStyle}
                primaryColor={primaryColor}
              />
            </OutsideAlerter>
          )}
          {this.props.error && (
            <div {...css(errorText)}>{this.props.error}</div>
          )}
          {charLimit && (
            <div
              {...css({
                position: "absolute",
                right: 0,
                top: 34,
                fontSize: "14px",
                color:
                  count > this.props.charLimit
                    ? colors.red
                    : "rgba(0,0,0,0.54)",
              })}
            >
              {}
              {count ? count : 0} / {this.props.charLimit}
            </div>
          )}
          {this.props.rightLink && (
            <div
              {...css(
                {
                  position: "absolute",
                  right: 0,
                  bottom: 2,
                  fontSize: "14px",
                  color: primaryColor,
                  cursor: this.props.rightLink.disabled ? "default" : "pointer",
                  textDecoration: this.props.rightLink.disabled
                    ? "none"
                    : "underline",
                  height: "fit-content",
                  opacity: this.props.rightLink.disabled ? 0.8 : 1,
                },
                $(":hover", {
                  textDecoration: !this.props.rightLink.disabled && "none",
                }),
                this.props.rightLink.customStyle
              )}
              onClick={this.props.rightLink.action}
            >
              {this.props.rightLink.name}
            </div>
          )}
        </div>
        <small
          {...css({
            color: "rgba(0,0,0,0.54)",
            fontWeight: 300,
            fontSize: 14,
            lineHeight: "17px",
            float: "left",
            marginTop: 6,
          })}
        >
          {description}
        </small>
      </div>
    );
  }
}

export default InputField;
