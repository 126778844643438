import { useState } from "react";
import { gql, useMutation } from "@apollo/client";

import { ThumbsDownIcon, ThumbsUpIcon } from "@hugeicons/react";
import { SentIcon } from "../../helpers/icons";
import styles from "./ToolBox.module.css";
import TextArea from "./TextArea";
import Button from "./Button";
import { colors } from "../../helpers/styles";
import loader from "../../assets/images/loader.svg";
import toast from "react-hot-toast";
import ToolTipWrapper from "./ToolTipWrapper";

export default function Feedback(props: { screen?: string; context?: any }) {
  const [feedbackDismissed, setFeedbackDismissed] = useState(false);
  const [feedbackId, setFeedbackId] = useState<string | null>(null);
  const [placeholder, setPlaceholder] = useState("");
  const [createFeedback] = useMutation(CREATE_FEEDBACK);
  const [loading, setLoading] = useState("");

  async function handleCreateFeedback(params: { type: string }) {
    setPlaceholder(
      params.type === "RESULT_GOOD"
        ? "What did you like about the result?"
        : "What did you not like about the result?"
    );
    setLoading(params.type);
    const { data } = await createFeedback({
      variables: {
        type: params.type,
        screen: props.screen,
        context: {
          url: window.location.href,
          ...props.context,
        },
      },
    });
    setFeedbackId(data.createFeedback.id);
    setLoading("");
    toast.success("Feedback submitted");
  }

  if (feedbackDismissed) {
    return null;
  }

  return (
    <div>
      {feedbackId ? (
        <FeedbackForm
          id={feedbackId}
          handleDismiss={() => setFeedbackDismissed(true)}
          screen={props.screen}
          placeholder={placeholder}
        />
      ) : (
        <div className={styles["feedback-ask"]}>
          <div>Happy with the result?</div>
          <div className={styles["feedback-buttons"]}>
            <div>
              <FeedbackButton
                icon={<ThumbsUpIcon width={16} height={16} />}
                label="Yes"
                onClick={() => {
                  handleCreateFeedback({ type: "RESULT_GOOD" });
                }}
                loading={loading === "RESULT_GOOD"}
                disabled={loading !== ""}
              />
            </div>
            <div>
              <FeedbackButton
                icon={<ThumbsDownIcon width={16} height={16} />}
                label="No"
                onClick={() => {
                  handleCreateFeedback({ type: "RESULT_BAD" });
                }}
                loading={loading === "RESULT_BAD"}
                disabled={loading !== ""}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

function FeedbackButton({
  icon,
  label,
  onClick,
  loading,
  disabled,
}: {
  icon: React.ReactNode;
  label: string;
  onClick: () => void;
  loading: boolean;
  disabled: boolean;
}) {
  return (
    <div
      className={styles["feedback-button"]}
      onClick={onClick}
      style={{
        cursor: disabled ? "not-allowed" : "pointer",
        pointerEvents: disabled ? "none" : "auto",
        backgroundColor: disabled ? "#f9f9f9" : "white",
        color: disabled ? "rgba(0, 0, 0, 0.17)" : "inherit",
      }}
    >
      {loading ? (
        <img src={loader} alt="loading" width={16} height={16} />
      ) : (
        icon
      )}
      {label}
    </div>
  );
}

export function FeedbackForm(props: {
  id?: string;
  handleDismiss: () => void;
  hasSwitcher?: boolean;
  screen?: string;
  isFloating?: boolean;
  placeholder?: string;
  style?: React.CSSProperties;
}) {
  const [createFeedback, { loading: createFeedbackLoading }] =
    useMutation(CREATE_FEEDBACK);
  const [updateFeedback, { loading: updateFeedbackLoading }] =
    useMutation(UPDATE_FEEDBACK);
  const [input, setInput] = useState("");
  const [type, setType] = useState("GENERAL");

  async function handleCreateFeedback() {
    await createFeedback({
      variables: {
        content: input.trim().slice(0, 1500),
        type: type,
        screen: props.screen,
        context: {
          url: window.location.href,
        },
      },
    });
    toast.success("Feedback submitted");
    props.handleDismiss();
  }

  async function handleUpdateFeedback(params: {
    id: string;
    content: string;
    type: string;
  }) {
    await updateFeedback({
      variables: {
        id: props.id,
        content: params.content.trim().slice(0, 1500),
        type: params.type,
      },
    });
    toast.success("Feedback submitted");
    props.handleDismiss();
  }

  let placeholder = "";
  if (props.placeholder) {
    placeholder = props.placeholder;
  } else if (type === "GENERAL") {
    placeholder =
      "All feedback is welcome! Please share what’s working well or needs improvement…";
  } else if (type === "BUG") {
    placeholder =
      "Oh no! Please share what happened and any steps to reproduce it…";
  }

  return (
    <div
      className={styles["feedback-form"]}
      style={{
        boxShadow: props.isFloating ? "0px 2px 4px rgba(0, 0, 0, 0.25)" : "",
        border: props.isFloating ? "0px" : "1px solid #e2e2e2",
        ...props.style,
      }}
    >
      <div className={styles["feedback-form-header"]}>
        <span>Share Feedback</span>
        <ToolTipWrapper
          customStyle={{
            top: "24px !important",
            right: "-2px !important",
          }}
          customArrowStyle={{ left: 32 }}
          text="Close"
        >
          <span className={styles["feedback-form-header-close"]}>
            <span
              className="bi_interface-cross"
              onClick={props.handleDismiss}
            />
          </span>
        </ToolTipWrapper>
      </div>
      <div className={styles["feedback-form-body"]}>
        {props.hasSwitcher && (
          <div className={styles["feedback-form-body-switcher"]}>
            <div
              className={styles["feedback-form-body-switcher-item"]}
              data-active={type === "GENERAL"}
              onClick={() => setType("GENERAL")}
            >
              General
            </div>
            <div
              className={styles["feedback-form-body-switcher-item"]}
              data-active={type === "BUG"}
              onClick={() => setType("BUG")}
            >
              Bug
            </div>
          </div>
        )}
        <div>
          <TextArea
            label={
              <span>
                Describe your feedback
                <span style={{ color: colors.red }}> *</span>
              </span>
            }
            value={input}
            handleChange={(e) => setInput(e.target.value)}
            placeholder={placeholder}
            customStyle={{
              height: props.isFloating ? 181 : 225,
            }}
          />
        </div>
        <div>
          <Button
            name="Submit"
            iconElement={
              <SentIcon width={16} height={16} color={colors.white} />
            }
            theme="black"
            size="medium"
            customStyle={{ width: "100%" }}
            loading={createFeedbackLoading || updateFeedbackLoading}
            disabled={input.length === 0}
            action={() => {
              if (props.id) {
                handleUpdateFeedback({
                  id: props.id,
                  content: input,
                  type: type,
                });
              } else {
                handleCreateFeedback();
              }
            }}
          />
        </div>
      </div>
    </div>
  );
}

export function FeedbackThumbs(props: { screen?: string; context?: any }) {
  const [feedbackDismissed, setFeedbackDismissed] = useState(false);
  const [feedbackId, setFeedbackId] = useState<string | null>(null);
  const [placeholder, setPlaceholder] = useState("");
  const [createFeedback] = useMutation(CREATE_FEEDBACK);
  const [loading, setLoading] = useState("");

  async function handleCreateFeedback(params: { type: string }) {
    setPlaceholder(
      params.type === "RESULT_GOOD"
        ? "What did you like about the result?"
        : "What did you not like about the result?"
    );
    setLoading(params.type);
    const { data } = await createFeedback({
      variables: {
        type: params.type,
        screen: props.screen,
        context: {
          url: window.location.href,
          ...props.context,
        },
      },
    });
    setFeedbackId(data.createFeedback.id);
    setLoading("");
    toast.success("Feedback submitted");
  }

  if (feedbackDismissed) {
    return null;
  }

  return (
    <>
      {feedbackId ? (
        <FeedbackForm
          id={feedbackId}
          handleDismiss={() => setFeedbackDismissed(true)}
          screen={props.screen}
          placeholder={placeholder}
          style={{
            marginTop: 16,
            width: "100%",
          }}
        />
      ) : (
        <div className={styles["feedback-thumbs"]}>
          <div>
            <ThumbsUpIcon
              width={16}
              height={16}
              loading={loading === "RESULT_GOOD"}
              disabled={loading !== ""}
              onClick={() => {
                handleCreateFeedback({ type: "RESULT_GOOD" });
              }}
            />
          </div>
          <div>
            <ThumbsUpIcon
              width={16}
              height={16}
              onClick={() => {
                handleCreateFeedback({ type: "RESULT_BAD" });
              }}
              loading={loading === "RESULT_BAD"}
              disabled={loading !== ""}
              style={{
                transform: "rotate(180deg)",
              }}
            />
          </div>
        </div>
      )}
    </>
  );
}

const CREATE_FEEDBACK = gql`
  mutation ($content: String, $type: String!, $screen: String, $context: JSON) {
    createFeedback(
      content: $content
      type: $type
      screen: $screen
      context: $context
    ) {
      id
    }
  }
`;

const UPDATE_FEEDBACK = gql`
  mutation ($id: ID!, $content: String, $screen: String, $context: JSON) {
    updateFeedback(
      id: $id
      content: $content
      screen: $screen
      context: $context
    ) {
      id
    }
  }
`;
