import React, { useState } from "react";
import { css, select as $ } from "glamor";
import Moment from "moment-timezone";
import DateRangePicker from "./DateRangePicker";

export default function PeriodSwitcher({
  setDate,
  setDateRange,
  startDate,
  endDate,
  switchMonths,
  mobile,
  customStyle,
  timezone,
  rangeType,
  setRangeType,
  updateUser,
}: {
  setDate?: (key: string, value: string) => void;
  setDateRange?: (dateRange: {
    startDate: string;
    endDate: string;
    rangeType: string;
  }) => void;
  startDate: string;
  endDate: string;
  switchMonths: (direction: "back" | "forward") => void;
  mobile: boolean;
  customStyle: any;
  timezone: string;
  rangeType: string;
  setRangeType: (rangeType: string) => void;
  updateUser: (
    rangeType: string,
    startDate: string,
    endDate: string,
    callback: () => void
  ) => void;
}) {
  const [showCalendar, setShowCalendar] = useState(false);
  const startFormat = Moment(startDate).isSame(new Date(), "year")
    ? "ddd, D MMM"
    : "ddd, D MMM YYYY";
  const endFormat = Moment(endDate).isSame(new Date(), "year")
    ? "ddd, D MMM"
    : "ddd, D MMM YYYY";
  return (
    <div
      {...css(
        {
          position: "relative",
          float: "left",
          height: 44,
          lineHeight: "44px",
          border: mobile ? 0 : "1px solid #E2E2E2",
          borderRadius: 23,
          fontSize: 14,
          fontWeight: 300,
          paddingLeft: mobile ? 0 : rangeType === "custom" ? 24 : 16,
          paddingRight: mobile ? 0 : rangeType === "custom" ? 24 : 16,
          width: mobile && "100%",
          textAlign: mobile && "center",
        },
        customStyle
      )}
    >
      {showCalendar && (
        <DateRangePicker
          startDate={startDate}
          endDate={endDate}
          rangeType={rangeType}
          setDate={setDate}
          setRangeType={setRangeType}
          customStyle={{
            position: "absolute",
            zIndex: 1,
            top: -2,
            left: -1,
          }}
          handleFocus={() => setShowCalendar(false)}
          exclude="date-range-list"
          applyFunction={(
            startDate: string,
            endDate: string,
            rangeType: string
          ) => {
            if (setDateRange) {
              setDateRange({ startDate, endDate, rangeType });
            } else if (setDate) {
              setDate("startDate", startDate);
              setDate("endDate", endDate);
              setRangeType(rangeType);
            }
            setShowCalendar(false);
            // save rangeType
            let startDateSelected;
            let endDateSelected;
            if (rangeType === "custom") {
              // save start/end date
              startDateSelected = startDate;
              endDateSelected = endDate;
            } else {
              // delete and start/end date
              startDateSelected = null;
              endDateSelected = null;
            }
            updateUser(rangeType, startDateSelected, endDateSelected, () =>
              console.log("callback!")
            );
          }}
        />
      )}

      {rangeType !== "custom" && (
        <span
          className="bi_interface-left"
          {...css({
            paddingRight: 12,
            fontSize: 17,
            color: "rgba(0,0,0,0.54)",
            cursor: "pointer",
          },
          $(":hover", {
            color: "rgba(0,0,0,0.87)",
          })
          )}
          onClick={() => switchMonths("back")}
          data-testid="rangeCustom"
        />
      )}
      <span
        {...css({ cursor: "pointer" })}
        onClick={() => {
          !mobile && setShowCalendar(true);
        }}
        data-testid="custom"
      >
        <span
          {...css(
            $(":hover", {
              textDecoration: !mobile && "underline",
            })
          )}
        >
          {Moment(startDate).format(startFormat)}
          {rangeType !== "day" && (
            <span data-testid="endDate">
              {" "}
              – {Moment(endDate).format(endFormat)}
            </span>
          )}
        </span>
        {timezone && (
          <span data-testid="timezone">
            <span {...css({ color: "#E2E2E2" })}> |</span> {timezone}
          </span>
        )}
      </span>
      {rangeType !== "custom" && (
        <span
          className="bi_interface-right"
          {...css({
            paddingLeft: 12,
            fontSize: 17,
            color: "rgba(0,0,0,0.54)",
              cursor: "pointer",
            },
            $(":hover", {
              color: "rgba(0,0,0,0.87)",
            })
          )}
          onClick={() => switchMonths("forward")}
          data-testid="rangeType"
        />
      )}
    </div>
  );
}
