import Moment from "moment";
import { colors } from "../../helpers/styles";

function getPlaceholderData(
  startDate: string,
  endDate: string,
  granularity: string
) {
  let start = Moment(startDate);
  let end = Moment(endDate);
  if (granularity === "day") {
    start = start.startOf("day");
    end = Moment(start).add(1, "day");
  } else if (granularity === "week") {
    start = start.startOf("isoWeek");
    end = Moment(start).add(1, "week");
  } else if (granularity === "month") {
    start = start.startOf("month");
    end = Moment(start).endOf("month").startOf("day");
  } else if (granularity === "quarter") {
    start = start.startOf("quarter");
    end = Moment(start).add(1, "quarter").subtract(1, "day");
  } else if (granularity === "year") {
    start = start.startOf("year");
    end = Moment(start).add(1, "year").subtract(1, "day");
  } else {
    const daysDiff = Moment(endDate).diff(startDate, "days");
    start = start.startOf("day");
    end = Moment(start).add(daysDiff + 1, "days");
  }
  const placeholderData = {
    id: "placeholder",
    label: "",
    data: [
      {
        x: start.toDate(),
        y: 0,
      },
      {
        x: end.toDate(),
        y: 6,
      },
    ],
    borderColor: colors.orange,
    borderWidth: 0,
    pointRadius: 0,
    hidden: false,
  };
  return placeholderData;
}

function handleSwitchDateRange(params: {
  direction: "forward" | "back";
  startDate: string;
  endDate: string;
  granularity: string;
  handleUpdateSearchParams: (params: {
    startDate: string;
    endDate: string;
    granularity: string;
  }) => void;
}) {
  let startDate = Moment(params.startDate);
  let endDate = Moment(params.endDate);

  let rangeType = "day";
  if (params.granularity === "week") {
    rangeType = "isoweek";
  } else if (params.granularity === "month") {
    rangeType = "month";
  } else if (params.granularity === "year") {
    rangeType = "year";
  } else if (params.granularity === "quarter") {
    rangeType = "quarter";
  }

  let momentGranularity = rangeType;
  if (momentGranularity === "week") {
    momentGranularity = "isoWeek";
  }

  if (params.direction === "forward") {
    startDate = startDate.add(1, momentGranularity).startOf(momentGranularity);
    endDate = endDate.add(1, momentGranularity).endOf(momentGranularity);
  } else {
    startDate = Moment(startDate)
      .subtract(1, momentGranularity)
      .startOf(momentGranularity);
    endDate = Moment(endDate)
      .subtract(1, momentGranularity)
      .endOf(momentGranularity);
  }
  params.handleUpdateSearchParams({
    startDate: startDate.unix().toString(),
    endDate: endDate.unix().toString(),
    granularity: params.granularity,
  });
}

function getInterval(interval: string) {
  if (interval === "15T") {
    return "15 min";
  }
  if (interval === "H") {
    return "1 hour";
  }
  if (interval === "D") return "1 day";
  return "";
}

const utils = {
  getPlaceholderData,
  handleSwitchDateRange,
  getInterval,
};

export default utils;
