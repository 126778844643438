import { css, media, select as $ } from "glamor";

export const colors = {
  red: "#ff3246",
  transparent_red: "rgba(255, 50, 70, 0.05)",
  dark_red: "#DC0029",
  light_red: "#FFEAE9",
  blue: "#28aaff",
  violet: "#5551FF",
  transparent_violet: "rgba(85, 81, 255, 0.05)",
  green: "#1ed25a",
  light_green: "#E2FAE4",
  dark_green: "#00851D",
  light_blue: "rgba(40, 170, 255, 0.10)",
  yellow: "#ffd200",
  purple: "#aa32dc",
  grey: "#E2E2E2",
  black: "#353535",
  orange: "#FF7828",
  transparent_orange: "rgba(255, 120, 40, 0.05)",
  te: "rgba(0,0,0,0.38)",
  ff: "rgba(0,0,0,0.54)",
  es: "rgba(0,0,0,0.87)",
};

export const font = "Museo Sans, Futura, Trebuchet MS, Arial, sans-serif";

export const fullRow = {
  float: "left",
  width: "100%",
};

export const headerTwo = {
  fontSize: "24px",
  fontWeight: "500",
  lineHeight: "29px",
};

export const bodyText = {
  fontSize: "16px",
  fontWeight: "300",
  lineHeight: "19px",
};

export const mobileShow600 = css(
  media("(min-width: 600px)", {
    display: "none",
  })
);

export const mobileNoShow600 = css(
  media("(max-width: 600px)", {
    display: "none",
  })
);

export const blackTransparentUnderlay = {
  position: "fixed",
  top: "0",
  left: "0",
  width: "100%",
  height: "100%",
  backgroundColor: "rgba(0, 0, 0, 0.2)",
};

export const getStatusBar = (responseGiven, customStyle) => {
  let response;
  const responseStyle = css(
    {
      fontWeight: "700",
      fontSize: "10px",
      padding: "2px 5px",
      borderRadius: "9px",
      border: "1px solid",
    },
    customStyle
  );
  switch (responseGiven) {
    case "ACCEPTED":
    case "APPROVED":
    case "ENABLED":
      response = (
        <span
          {...css(responseStyle, {
            background: colors.green,
            borderColor: colors.green,
            color: "white",
          })}
        >
          {responseGiven}
        </span>
      );
      break;
    case "PENDING":
      response = (
        <span
          {...css(responseStyle, {
            background: colors.yellow,
            borderColor: colors.yellow,
          })}
        >
          {responseGiven}
        </span>
      );
      break;
    case "INVITED":
      response = (
        <span
          {...css(responseStyle, {
            background: colors.yellow,
            borderColor: colors.yellow,
            color: "rgba(0,0,0,0.87)",
          })}
        >
          {responseGiven}
        </span>
      );
      break;
    case "DECLINED":
    case "REJECTED":
    case "DISABLED":
    case "ARCHIVED":
      response = (
        <span
          {...css(responseStyle, {
            background: "white",
            borderColor: colors.red,
            color: colors.red,
          })}
        >
          {responseGiven}
        </span>
      );
      break;
    case "COVER_DECLINED":
    case "DECLINED_CONFIRMED":
      response = (
        <span
          {...css(responseStyle, {
            background: "white",
            borderColor: colors.red,
            color: colors.red,
          })}
        >
          DECLINED
        </span>
      );
      break;
    case "CANCELLED":
      response = (
        <span
          {...css(responseStyle, {
            background: colors.red,
            color: "white",
            borderColor: colors.red,
          })}
        >
          {responseGiven}
        </span>
      );
      break;
    case "CANCELLATION_REQUESTED":
    case "CANCEL REQUESTED":
      response = (
        <span
          {...css(responseStyle, {
            background: "white",
            borderColor: colors.red,
            color: colors.red,
          })}
        >
          CANCEL REQUESTED
        </span>
      );
      break;
    case "DELETED":
      response = (
        <span
          {...css(responseStyle, {
            background: "white",
            borderColor: colors.red,
            color: colors.red,
          })}
        >
          {responseGiven}
        </span>
      );
      break;
    case "UNPUBLISHED":
    case "OPEN":
    case "DRAFT":
      response = (
        <span
          {...css(responseStyle, {
            background: "white",
            borderColor: colors.blue,
            color: colors.blue,
          })}
        >
          {responseGiven}
        </span>
      );
      break;
    case "ISSUE":
      response = (
        <span
          {...css(responseStyle, {
            background: colors.red,
            borderColor: colors.red,
            color: "white",
          })}
        >
          {responseGiven}
        </span>
      );
      break;
    case "READY":
    case "LIVE":
      response = (
        <span
          {...css(responseStyle, {
            background: colors.green,
            borderColor: colors.green,
            color: "white",
          })}
        >
          {responseGiven}
        </span>
      );
      break;
    case "FINISHED":
      response = (
        <span
          {...css(responseStyle, {
            background: colors.purple,
            borderColor: colors.purple,
            color: "white",
          })}
        >
          {responseGiven}
        </span>
      );
      break;
    case "CLOSED":
      response = (
        <span
          {...css(responseStyle, {
            background: colors.black,
            borderColor: colors.black,
            color: "white",
          })}
        >
          {responseGiven}
        </span>
      );
      break;
    case "ASSIGNED":
    case "CREATE":
      response = (
        <span
          {...css(responseStyle, {
            background: colors.blue,
            borderColor: colors.blue,
            color: "white",
          })}
        >
          {responseGiven}
        </span>
      );
      break;
    case "PUBLISHED":
      response = (
        <span
          {...css(responseStyle, {
            background: colors.blue,
            borderColor: colors.blue,
            color: "white",
          })}
        >
          {responseGiven}
        </span>
      );
      break;
    case "COVERED":
      response = (
        <span
          {...css(responseStyle, {
            background: colors.orange,
            borderColor: colors.orange,
            color: "white",
          })}
        >
          {responseGiven}
        </span>
      );
      break;
    case "COVER REQUESTED":
    case "COVER_REQUESTED":
      response = (
        <span
          {...css(responseStyle, {
            background: "white",
            borderColor: colors.orange,
            color: colors.orange,
          })}
        >
          COVER REQUESTED
        </span>
      );
      break;
    case "SWAP REQUESTED":
    case "SWAP_REQUESTED":
      response = (
        <span
          {...css(responseStyle, {
            background: "white",
            borderColor: colors.orange,
            color: colors.orange,
          })}
        >
          SWAP REQUESTED
        </span>
      );
      break;
    case "ACTIVE":
      response = (
        <span
          {...css(responseStyle, {
            background: colors.green,
            borderColor: colors.green,
            color: "white",
          })}
        >
          {responseGiven}
        </span>
      );
      break;
    case "CREATED":
      response = (
        <span
          {...css(responseStyle, {
            background: colors.blue,
            borderColor: colors.blue,
            color: "white",
          })}
        >
          {responseGiven}
        </span>
      );
      break;
    case "DEACTIVATED":
      response = (
        <span
          {...css(responseStyle, {
            background: colors.red,
            borderColor: colors.red,
            color: "white",
          })}
        >
          {responseGiven}
        </span>
      );
      break;
    case "PAUSED":
    case "LEAVE":
      response = (
        <span
          {...css(responseStyle, {
            background: "#E2E2E2",
            borderColor: "#E2E2E2",
            color: "rgba(0,0,0,0.87)",
          })}
        >
          {responseGiven}
        </span>
      );
      break;
    default:
      break;
  }
  return response;
};

export const dropdownStyle = css(
  {
    position: "absolute",
    background: "white",
    borderRadius: "5px",
    boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.25)",
    maxHeight: "174px",
    zIndex: "1",
    overflowY: "scroll",
  },
  $("::-webkit-scrollbar", {
    display: "none",
  }),
  $(
    " ul",
    {
      listStyle: "none",
      margin: "9px 6px",
      padding: "0px",
    },
    $(
      " li",
      {
        color: "rgba(0,0,0,0.54)",
        fontSize: "14px",
        lineHeight: "26px",
        fontWeight: "300",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflowX: "hidden",
        cursor: "pointer",
        // width: "60px",
        textAlign: "center",
      },
      $(":hover", {
        background: "rgba(0,0,0,0.05)",
        borderRadius: "3px",
        color: "rgba(0,0,0,0.54)",
        fontWeight: "500",
      })
    )
  )
);

export function formatHours(hours) {
  const stringHours = hours.toString();
  if (!hours) {
    return (
      <>
        0<span {...css({ fontSize: "24px" })}>.00</span>
      </>
    );
  } else if (stringHours.indexOf(".") > 0) {
    const split = stringHours.split(".");
    const decimals = split[1].length === 1 ? split[1] + "0" : split[1];
    return (
      <>
        {split[0]}
        <span {...css({ fontSize: "24px" })}>.{decimals}</span>
      </>
    );
  } else {
    return (
      <span>
        {hours}
        <span {...css({ fontSize: "24px" })}>.00</span>
      </span>
    );
  }
}

export const dot = {
  marginTop: "11px",
  marginLeft: "6px",
  width: "8px",
  height: "8px",
  borderRadius: "8px",
  display: "block",
};

export function getAvatarColor(firstName) {
  let number = firstName.toUpperCase().charCodeAt(0) - 65;
  if (number > 27) {
    number = firstName.length;
  }
  let background;
  switch (true) {
    case number < 6:
      background = colors.red;
      break;
    case number > 5 && number < 12:
      background = colors.blue;
      break;
    case number > 11 && number < 16:
      background = colors.purple;
      break;
    case number > 15 && number < 24:
      background = colors.yellow;
      break;
    case number > 23 && number < 28:
      background = colors.green;
      break;
    default:
      background = colors.grey;
      break;
  }
  return background;
}

export function labelMaker(conflicts) {
  const conflictLabels = [];
  let available;
  let alreadyScheduled;
  let overlappingEvents;
  let pendingLeave;
  let approvedLeave;
  let hourConflicts;
  let contractDayConflicts;
  if (conflicts) {
    available = conflicts.eventConflicts.available;
    alreadyScheduled = conflicts.eventConflicts.alreadyScheduled;
    overlappingEvents = conflicts.eventConflicts.overlappingEvents;
    pendingLeave = conflicts.leaveConflicts.pendingLeave;
    approvedLeave = conflicts.leaveConflicts.approvedLeave;
    hourConflicts = conflicts.hourConflicts;
    contractDayConflicts = conflicts.contractDayConflicts;

    if (alreadyScheduled) {
      conflictLabels.push({
        name: "Scheduled Events",
        color: colors.blue,
      });
    }
    if (overlappingEvents) {
      conflictLabels.push({
        name: "Overlapping Events",
        color: colors.blue,
      });
    }
    if (pendingLeave) {
      conflictLabels.push({ name: "Pending Leave", color: colors.blue });
    }
    if (approvedLeave) {
      conflictLabels.push({ name: "Approved Leave", color: colors.red });
    }
    if (hourConflicts) {
      conflictLabels.push({ name: "Hour Limit", color: colors.red });
    }
    if (contractDayConflicts) {
      conflictLabels.push({ name: "Contract Day", color: colors.red });
    }
  }
  return conflictLabels;
}

export const getPersonStyle = (
  requestStatus,
  eventStatus,
  activeChange,
  isAutoScheduled
) => {
  let personStyle;
  if (isAutoScheduled) {
    personStyle = {
      border: `1px solid ${colors.violet}`,
      paddingLeft: 3,
      paddingRight: 3,
      background: colors.transparent_violet,
    };
  } else if (activeChange) {
    personStyle = {
      background: activeChange.type === "CANCEL" ? colors.red : "white",
      paddingLeft: 3,
      paddingRight: 3,
      color: activeChange.type === "CANCEL" ? "white" : colors.orange,
      border: activeChange.type !== "CANCEL" && `1px solid ${colors.orange}`,
    };
  } else if (requestStatus === "PENDING") {
    personStyle = {
      background: colors.yellow,
      paddingLeft: 3,
      paddingRight: 3,
    };
  } else if (requestStatus === "DECLINED") {
    personStyle = {
      border: `1px solid ${colors.red}`,
      paddingLeft: 3,
      paddingRight: 3,
      color: colors.red,
    };
  } else if (requestStatus === "CANCELLATION_REQUESTED") {
    personStyle = {
      background: colors.red,
      paddingLeft: 3,
      paddingRight: 3,
      color: "white",
    };
  } else if (requestStatus === "CANCELLED") {
    // striketrough
    personStyle = {
      textDecoration: "line-through",
    };
  }
  return personStyle;
};
