import React, { forwardRef } from "react";
import styles from "./ToolBox.module.css";

const TextArea = forwardRef<
  HTMLTextAreaElement,
  {
    value: string;
    handleChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
    placeholder: string;
    customStyle?: React.CSSProperties;
    label?: string;
    id?: string;
    tooltip?: {
      text: string;
      customStyle?: React.CSSProperties;
      customArrowStyle?: React.CSSProperties;
    };
    labelIcon?: React.ReactNode;
  }
>(
  (
    {
      value,
      handleChange,
      placeholder,
      customStyle,
      label,
      id,
      tooltip,
      labelIcon,
    },
    ref
  ) => {
    return (
      <div className={styles["textarea-container"]}>
        <textarea
          ref={ref}
          value={value}
          onChange={handleChange}
          className={styles["textarea"]}
          style={customStyle}
          placeholder={placeholder}
        />
        {label && (
          <label htmlFor={id}>
            {label}{" "}
            {tooltip && (
              <ToolTip
                text={tooltip.text}
                customStyle={tooltip.customStyle}
                customArrowStyle={tooltip.customArrowStyle}
              />
            )}
            {labelIcon && <span style={{ marginLeft: 3 }}>{labelIcon}</span>}
          </label>
        )}
      </div>
    );
  }
);

TextArea.displayName = "TextArea";

export default TextArea;
