import { motion } from "framer-motion";
import OutsideAlerter from "../clickOutside/OutsideAlerter";
import loader from "../../assets/images/loader.svg";
import styles from "./ToolBox.module.css";
import Label from "./Label";

export default function OptionsMenuSubtitle({
  customStyle,
  items,
  loading,
  name,
  handleFocus,
  disableActionAutoClose,
}: {
  customStyle?: React.CSSProperties;
  items: {
    id: string;
    title: string;
    subtitle?: string;
    icon?: string;
    liStyle?: React.CSSProperties;
    titleStyle?: React.CSSProperties;
    action?: () => void;
    selected?: boolean;
    label?: string;
    labelContainerStyle?: React.CSSProperties;
    labelStyle?: React.CSSProperties;
    disableHandleFocus?: boolean;
  }[];
  loading: boolean;
  name: string;
  handleFocus: any;
  disableActionAutoClose?: boolean;
}) {
  const list = items.map((item) => {
    return (
      <motion.li
        initial={{ marginTop: -70, opacity: 0 }}
        animate={{ marginTop: 0, opacity: 1 }}
        exit={{ marginTop: 0, opacity: 1 }}
        transition={{
          duration: 0.3,
          ease: [0, 1, 0.5, 1],
        }}
        key={item.id}
        style={{
          padding: "0px 6px",
          ...item.liStyle,
        }}
        onClick={
          item.action && !loading
            ? () => {
                item.action && item.action();
                if (!disableActionAutoClose && !item.disableHandleFocus) {
                  handleFocus();
                }
              }
            : () => {}
        }
      >
        <div
          className={styles["options-menu-subtitle-item"]}
          style={{
            cursor: loading ? "default" : "pointer",
            opacity: loading ? 0.8 : 1,
          }}
        >
          <div
            style={{
              float: "left",
              lineHeight: "20px",
              height: "20px",
            }}
          >
            {item.icon && <span className={item.icon} />}{" "}
            <h2
              style={{
                display: item.icon && "inline-block",
                ...item.titleStyle,
              }}
              className={styles["options-menu-subtitle-item-header"]}
              data-type={item.selected ? "active" : "disabled"}
            >
              {item.title}
            </h2>
            {item.label && (
              <Label
                style={item.labelStyle ?? {}}
                containerStyle={item.labelContainerStyle ?? {}}
              >
                {item.label}
              </Label>
            )}
          </div>
          <div
            style={{
              float: "left",
              width: "100%",
              fontSize: "12px",
              lineHeight: "15px",
              fontWeight: 300,
              color: "rgba(0, 0, 0, 0.54)",
            }}
          >
            {item.subtitle}
          </div>
        </div>
      </motion.li>
    );
  });
  return (
    <OutsideAlerter action={handleFocus}>
      <div className={styles["options-menu-subtitle"]} style={customStyle}>
        <div
          style={{
            float: "left",
            width: "100%",
            lineHeight: "44px",
            borderBottom: "1px solid #E2E2E2",
            fontSize: "14px",
            fontWeight: "500",
            paddingLeft: "12px",
            boxSizing: "border-box",
            marginBottom: 3,
          }}
        >
          {name}

          {loading && (
            <div
              style={{
                float: "right",
                height: 20,
                marginTop: 4,
                marginRight: 12,
              }}
            >
              <img width="20px" height="20px" src={loader} alt="loading..." />
            </div>
          )}
        </div>
        <ul
          style={{
            float: "left",
            listStyle: "none",
            padding: "0",
            margin: 0,
            display: "flex",
            flexDirection: "column",
          }}
        >
          {list}
        </ul>
      </div>
    </OutsideAlerter>
  );
}
