import { css, select as $ } from "glamor";

export default function ToolTipWrapper({
  children,
  customStyle,
  customArrowStyle,
  arrowBelow,
  text,
}: {
  children: React.ReactNode;
  customStyle?: React.CSSProperties;
  customArrowStyle?: React.CSSProperties;
  arrowBelow?: boolean;
  text: string;
}) {
  let arrowStyle = {};
  if (arrowBelow) {
    arrowStyle = {
      borderTop: "4px solid black",
      borderBottom: "6px solid transparent",
    };
  }


  return (
    <div className="tooltip">
      {children}
      <span
        className="tooltiptext"
        {...css(
          {
            left: "auto !important",
            right: "0px !important",
            position: "absolute !important",
            width: "auto !important",
            fontSize: "14px",
            fontWeight: "500",
            lineHeight: "17px",
            textAlign: "left",
            color: "white !important",
            ...(customStyle ? customStyle : {}),
          },

          $("::after", {
            left: 0,
            ...arrowStyle,
            ...(customArrowStyle ? customArrowStyle : {}),
          })
        )}
      >
        {text}
      </span>
    </div>
  );
}
