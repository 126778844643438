import React, { Component, createContext } from "react";
import { graphql } from "@apollo/client/react/hoc";
import { gql, useQuery } from "@apollo/client";
import { flowRight as compose } from "lodash";
import { Redirect } from "react-router-dom";
import Moment from "moment-timezone";

import Loader from "./loader/Loader";

import Router from "./Router";
import Achievements from "./achievements/Achievements";
import NeedsVerification from "./authentication/NeedsVerification";
import Profitwell from "./toolBox/ProfitWell";
import ExplainerVideo from "./toolBox/ExplainerVideo";
import { handleAPIError } from "../helpers/handleErrors";
import AccountSetup from "./onBoarding/AccountSetup";
import { bootIntercom } from "../helpers/functions";
import NewFeature from "./toolBox/NewFeature";
import { APP_VERSION, LAUNCH_DATES } from "../helpers/globals";
import Explosion from "./toolBox/Confetti";
import { ConfettiProvider } from "./context/ConfettiContext";
import { PresenceProvider } from "./context/PresenceContext";
import { useServiceWorkerUpdate } from "../helpers/hooks";
import "chartjs-adapter-moment";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  TimeScale,
} from "chart.js";

import {
  drawRectanglePlugin,
  customXAxisLabels,
  customYAxisLabels,
  drawGridLines,
  CustomYAxis,
  RightYAxis,
} from "./toolBox/chart/Plugins";

ChartJS.defaults.font.family = "Museo Sans"; // Set default font family
ChartJS.defaults.font.size = 14; // Set default font size
ChartJS.defaults.color = "rgba(0,0,0,0.87)";

// chart.js defaults
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  TimeScale
);

// custom plugins
ChartJS.register(
  drawRectanglePlugin,
  customXAxisLabels,
  customYAxisLabels,
  drawGridLines,
  CustomYAxis,
  RightYAxis
);

export const UserContext = createContext({
  id: "",
  contractHours: 0,
  email: "",
  firstName: "",
  lastName: "",
  name: "",
  createdAt: "",
  updatedAt: "",
  avatar: "",
  lastSeen: "",
  timezone: "",
  availabilityTimezone: "",
  mobileSchedulePreference: "",
  timeFormat: "",
  teamClass: "",
  access: "",
  teamId: "",
  currency: "",
  spotlights: {},
  achievements: [],
});

export const TeamContext = createContext({
  id: "",
  createdAt: "",
  logo: "",
  currency: "",
  peoplePickerAdvanced: false,
  name: "",
  industry: "",
  size: "",
  defaultScreenDate: "",
  inviteLinkAdmin: "",
  inviteLinkMember: "",
  deleted: false,
  owner: {
    name: "",
  },
  billing: {
    id: "",
    stripeCustomerId: "",
  },
  leaveCategories: [],
  roles: [],
  groups: [],
});

export default function AppDataLoader() {
  const { loading, data, refetch } = useQuery(USER_QUERY, {
    errorPolicy: "all",
    fetchPolicy: "network-only",
  });

  const hasNewVersion = useServiceWorkerUpdate();

  function isSignedIn() {
    if (data.user) {
      const user = data.user;
      // check if user belongs to team
      if (user.teams.length === 0) {
        localStorage.removeItem("graphcoolToken");
        return false;
      }

      const teamId = user.teams[0].id;
      if (
        Window &&
        ["cjd4q8p37ed5c01712fkpu0bx", "cl4ohzvoy110798501nx4w54nfat"].includes(
          teamId
        )
      ) {
        Window.isPrivateMode = true;
      }

      return true;
    } else {
      localStorage.removeItem("graphcoolToken");
      return false;
    }
  }

  if (loading) {
    return <Loader />;
  } else {
    const signedIn = isSignedIn();
    const user = data.user;

    const currentUser = {
      id: user.id,
      contractHours: user.contractHours,
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
      name: user.name,
      createdAt: user.createdAt,
      updatedAt: user.updatedAt,
      avatar: user.avatar,
      lastSeen: user.lastSeen,
      timezone: user.timezone,
      availabilityTimezone: user.availabilityTimezone,
      mobileSchedulePreference: user.mobileSchedulePreference,
      timeFormat: user.timeFormat,
      teamClass: user.teamClasses[0].userIs,
      access: {},
      teamId: user.teams[0].id,
      currency: user.teams[0].currency,
      spotlights: user.spotlights,
      achievements: user.achievements,
    };

    let currentTeam;
    const team = user.teams[0];
    if (team) {
      currentTeam = {
        id: team.id,
        createdAt: team.createdAt,
        logo: team.logo,
        currency: team.currency,
        peoplePickerAdvanced: team.peoplePickerAdvanced,
        name: team.name,
        industry: team.industry,
        size: team.size,
        defaultScreenDate: team.defaultScreenDate,
        inviteLinkAdmin: team.inviteLinkAdmin,
        inviteLinkMember: team.inviteLinkMember,
        deleted: team.deleted,
        owner: {
          name: team.owner.name,
        },
        billing: {
          id: team.billing.id,
          stripeCustomerId: team.billing.stripeCustomerId,
        },
        leaveCategories: team.leaveCategories,
        roles: team.roles,
        groups: team.groups,
      };
    }

    if (signedIn) {
      return (
        <UserContext.Provider value={currentUser}>
          <TeamContext.Provider value={currentTeam}>
            <PresenceProvider currentUser={currentUser}>
              {/* {hasNewVersion && <SoonImproved action={() => {
                window.location.reload();
              }} />} */}
              <AppWithMutations user={user} refetch={refetch} />
            </PresenceProvider>
          </TeamContext.Provider>
        </UserContext.Provider>
      );
    } else {
      return <Redirect to="/signin" />;
    }
  }
}

const USER_QUERY = gql`
  query appUser {
    user {
      id
      contractHours
      email
      firstName
      lastName
      name
      createdAt
      avatar
      isVerified
      lastSeen
      timezone
      availabilityTimezone
      mobileSchedulePreference
      timeFormat
      emailNotifications
      spotlights
      googleCalendarConfig
      teamClasses {
        id
        userIs
        updatedAt
      }
      onBoarding {
        id
        name
        status
      }
      achievements {
        id
        status
        createdAt
        updatedAt
        isNew
        goal {
          id
          code
          description
        }
      }
      teams {
        id
        createdAt
        logo
        currency
        peoplePickerAdvanced
        name
        industry
        size
        defaultScreenDate
        inviteLinkAdmin
        inviteLinkMember
        deleted
        owner {
          name
        }
        billing {
          id
          stripeCustomerId
        }
        leaveCategories {
          id
          name
          icon
          description
          status
          createdAt
          updatedAt
        }
        roles {
          id
          name
        }
        groups {
          id
          name
          description
          users {
            id
            isVerified
            teamClasses {
              id
              userIs
            }
          }
        }
      }
    }
  }
`;

class App extends Component {
  state = {
    tempHidden: false,
  };
  componentDidMount() {
    // now we boot intercom, because the user is signed in
    const user = this.props.user;

    if (user) {
      let intercomData = {
        app_id: import.meta.env.VITE_APP_INTERCOM_APPID,
        api_base: "https://api-iam.intercom.io",
        user_id: user.id,
        userClass: user.teamClasses[0].userIs,
        name: user.name,
        first_name: user.firstName,
        last_name: user.lastName,
        email: user.email,
        created_at: user.createdAt,
        avatar: user.avatar,
        contract_hours: user.contractHours / 60,
        advanced_people_picker: user.teams[0].peoplePickerAdvanced,
        invite_link_admin: user.teams[0].inviteLinkAdmin,
        invite_link_member: user.teams[0].inviteLinkMember,
        appVersion: Number(
          APP_VERSION.replace(".", "").replace(".", "").substring(1)
        ),
      };
      if (user.isVerified) {
        const needsAccountSetup =
          user.onBoarding.filter(
            (o) => o.name === "ACCOUNT_SETUP" && o.status !== "FINISHED"
          )[0] && user.isVerified;

        if (needsAccountSetup) {
          // boot intercom for user that needs account setup
        } else {
          // user is verified and account has been setup
          intercomData = {
            ...intercomData,
            companies: user.teams,
            accountSetup: true,
            isVerified: true,
          };
        }
      } else {
        // boot intercom for unverified user
      }

      // we boot intercom if there is in fact a user
      bootIntercom({
        data: intercomData,
      });

      const lastSeen = user.lastSeen && new Date(user.lastSeen);
      const now = new Date();
      const fiveMinutesLater =
        lastSeen && new Date(lastSeen.getTime() + 5 * 60000);
      if (!lastSeen) {
        this.updateLastSeen(user.id);
      } else if (now > fiveMinutesLater) {
        this.updateLastSeen(user.id);
      }

      const displayTimezone = user.timezone;

      const availabilityTimezone = user.availabilityTimezone;
      if (!displayTimezone) {
        this.updateTimezone(user.id, Moment.tz.guess());
      }
      if (!availabilityTimezone) {
        this.updateTimezone(user.id, "", Moment.tz.guess());
      }
    }
  }

  updateLastSeen = (id) => {
    const now = new Date();
    const lastSeen = now.toISOString();
    this.props
      .updateLastSeen({ variables: { id, lastSeen } })
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        handleAPIError(err, {});
        console.log(err);
      });
  };

  updateTimezone = (id, displayTimezone, availabilityTimezone) => {
    let variables = {
      id,
    };
    if (displayTimezone) {
      variables.displayTimezone = displayTimezone;
    }
    if (availabilityTimezone) {
      variables.availabilityTimezone = availabilityTimezone;
    }

    this.props
      .updateTimezone({
        variables,
      })
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        handleAPIError(err, {});
        console.log(err);
      });
  };

  render() {
    const user = this.props.user;
    const userIs = user.teamClasses[0].userIs;
    if (!user.isVerified) {
      return <NeedsVerification user={user} refetch={this.props.refetch} />;
    }

    if (userIs === "DEACTIVATED_MEMBER" || userIs === "DEACTIVATED_ADMIN") {
      return <Redirect to="/deactivated" />;
    } else {
      const customerId = user.teams[0].billing
        ? user.teams[0].billing.stripeCustomerId
        : "";

      const needsAccountSetup =
        user.onBoarding.filter(
          (o) => o.name === "ACCOUNT_SETUP" && o.status !== "FINISHED"
        )[0] && user.isVerified;

      user.timezone && Moment.tz.setDefault(user.timezone);

      const LAUNCH_DATE = new Date(LAUNCH_DATES.templateGenerator);

      let showNewFeature = false;
      const userIs = user.teamClasses[0].userIs;
      const userIsUpdated = user.teamClasses[0].updatedAt;
      const userCreatedAt = user.createdAt;

      const newFeatureId = "templateGenerator";
      if (
        Moment(userCreatedAt).isBefore(LAUNCH_DATE) &&
        Moment(userIsUpdated).isBefore(LAUNCH_DATE)
      ) {
        if (["SUPERADMIN", "ADMIN"].includes(userIs)) {
          if (!user?.spotlights?.[newFeatureId]) {
            showNewFeature = true;
          }
        }
      }

      const checkForAchievements = userIs === "SUPERADMIN";

      return (
        <>
          <ConfettiProvider>
            <Router
              userClass={user.teamClasses[0].userIs}
              user={user}
              teamId={user.teams[0].id}
              timezone={user.timezone}
              refetch={this.props.refetch}
            />
            <Explosion />
            {checkForAchievements && <Achievements />}
          </ConfettiProvider>
          {needsAccountSetup && (
            <AccountSetup
              user={user}
              team={user.teams[0]}
              refetch={this.props.refetch}
            />
          )}
          {import.meta.env.VITE_APP_LOAD_PROFITWELL && (
            <Profitwell
              customerId={customerId}
              email={user.email}
              authToken={import.meta.env.VITE_APP_PROFITWELL_TOKEN}
            />
          )}
          {showNewFeature && (
            <NewFeature
              userId={user.id}
              featureId={newFeatureId}
              spotlights={user.spotlights}
              hideTemp={() => this.setState({ tempHidden: true })}
            />
          )}
          <ExplainerVideo needsAccountSetup={needsAccountSetup} />
        </>
      );
    }
  }
}

const updateLastSeen = gql`
  mutation ($id: ID!, $lastSeen: DateTime) {
    updateUser(id: $id, lastSeen: $lastSeen) {
      id
      lastSeen
    }
  }
`;

const updateTimezone = gql`
  mutation ($id: ID!, $displayTimezone: String, $availabilityTimezone: String) {
    updateUser(
      id: $id
      timezone: $displayTimezone
      availabilityTimezone: $availabilityTimezone
    ) {
      id
      timezone
      availabilityTimezone
    }
  }
`;

const AppWithMutations = compose(
  graphql(updateLastSeen, { name: "updateLastSeen" }),
  graphql(updateTimezone, { name: "updateTimezone" })
)(App);
