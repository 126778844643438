import { createPortal } from "react-dom";
import { gql, useMutation } from "@apollo/client";
import Embed from "./Embed";
import Button from "./Button";
import { useState, useContext, useEffect } from "react";
import styles from "./ToolBox.module.css";
import { UserContext } from "../App";

const EXPLAINERS = [
  {
    id: "board_explainer",
    url_match: [
      {
        value: "/b/",
        not: "/event/",
      },
    ],
    start_at: new Date("2025-02-21"),
    user_class: ["SUPERADMIN", "ADMIN"],
    user_rights: ["write"],
    video_url: "https://www.youtube.com/embed/kYEWaAC-Vrg",
    title: "Introducing the Scheduling Board",
    description:
      "The Scheduling Board is your team's central hub for planning and coordination. Schedule shifts, track time off, and manage intraday activities effortlessly in one powerful workspace.",
    hash: "board_explainer",
  },
  {
    id: "my_schedule_explainer",
    url_match: [
      {
        value: "/myschedule/",
        not: "/event/",
      },
    ],
    start_at: new Date("2025-02-21"),
    user_class: [],
    user_rights: [],
    video_url: "https://www.youtube.com/embed/Cmbgj74fT9M",
    title: "Your Schedule Overview",
    description:
      "With My Schedule, all your shifts and tasks are streamlined in one place. Easily request time off, propose shift changes, and update your availability to keep your schedule running smoothly.",
    hash: "my_schedule_explainer",
  },

  {
    id: "event_details_explainer",
    url_match: [
      {
        value: "/event/",
      },
    ],
    start_at: new Date("2025-02-21"),
    user_class: ["SUPERADMIN", "ADMIN"],
    user_rights: ["write"],
    video_url: "https://www.youtube.com/embed/s_ms2_rYb5Q",
    title: "Explore the Event Details",
    description:
      "Configure roles and determine the number of people per shift. Fine-tune settings like self-scheduling and WFH to tailor your team's schedule for maximum flexibility and efficiency.",
    hash: "event_details_explainer",
  },
  {
    id: "users_explainer",
    url_match: [
      {
        value: "/users/team",
      },
    ],
    start_at: new Date("2025-02-21"),
    user_class: ["SUPERADMIN", "ADMIN"],
    user_rights: [],
    video_url: "https://www.youtube.com/embed/OPe2JBKTtjY",
    title: "Your Team Management",
    description:
      "Create users first then invite them when you're ready. Here you can also set contract values, which is needed for the auto-scheduler and helps you in general.",
    hash: "users_explainer",
  },
];

export default function ExplainerVideo({
  needsAccountSetup,
}: {
  needsAccountSetup: boolean;
}) {
  const currentUser = useContext(UserContext);
  const [explainerVideoId, setExplainerVideoId] = useState<string | null>(
    localStorage.getItem("explainer_video_id") || null
  );
  const currentLocation = window.location.href;
  const spotlights = currentUser.spotlights;

  const [updateSpotlight] = useMutation(UPDATE_SPOTLIGHT);

  async function handleUpdateSpotlight(featureId: string) {
    await updateSpotlight({
      variables: {
        id: currentUser.id,
        spotlights: { ...spotlights, [featureId]: true },
      },
    });
  }
  console.log("i explainer video id", explainerVideoId);

  useEffect(() => {
    const handleStorageChange = (e: StorageEvent) => {
      // check local storage for explainer_video_id
      const newValue = localStorage.getItem("explainer_video_id");
      if (newValue) {
        setExplainerVideoId(newValue);
      } else {
        setExplainerVideoId(null);
      }
    };

    window.addEventListener("storage", handleStorageChange);
    return () => window.removeEventListener("storage", handleStorageChange);
  }, []);

  const explainers = EXPLAINERS.map((explainer) => {
    const hasSeen = spotlights?.[explainer.id];
    const isNewUser = explainer.start_at
      ? new Date(currentUser?.createdAt) > new Date(explainer.start_at)
      : false;
    let hasRights = true;

    const hasTeamClass =
      explainer.user_class?.length > 0
        ? explainer.user_class.includes(currentUser.teamClass)
        : true;

    const hasUserRights =
      explainer.user_rights?.length > 0
        ? explainer.user_rights.includes(currentUser?.access?.rights)
        : true;

    hasRights = hasTeamClass && hasUserRights;

    return {
      ...explainer,
      isNewUser,
      hasSeen,
      hasRights,
    };
  });

  let explainer = null;
  for (const video of explainers) {
    if (needsAccountSetup) {
      continue;
    }
    if (explainerVideoId === video.hash) {
      explainer = video;
      break;
    }
    if (video.hasSeen) {
      continue;
    }
    if (!video.isNewUser) {
      continue;
    }
    if (!video.hasRights) {
      continue;
    }
    if (video.url_match.length > 0) {
      for (const match of video.url_match) {
        let isMatch = currentLocation.includes(match.value);
        if (isMatch && match.not) {
          isMatch = !currentLocation.includes(match.not);
        }
        if (isMatch) {
          explainer = video;
          break;
        }
      }
    }
  }

  if (!explainer) {
    return null;
  }

  return createPortal(
    <>
      <div className={styles["explainer-video"]}>
        <div>
          <Embed
            embeds={[
              {
                type: "youtube",
                url: explainer.video_url,
              },
            ]}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "6px",
            padding: "0 12px",
          }}
        >
          <div
            style={{
              lineHeight: "30px",
              fontSize: "20px",
              fontWeight: "bold",
            }}
          >
            {explainer.title}
          </div>
          <div
            style={{
              fontSize: "16px",
              fontWeight: 300,
              lineHeight: "23.5px",
            }}
          >
            {explainer.description}
          </div>
        </div>
        <div style={{ padding: "0 12px" }}>
          <Button
            name="Got it"
            theme="black-border"
            size="large"
            action={() => {
              if (explainerVideoId) {
                localStorage.removeItem("explainer_video_id");
                setExplainerVideoId(null);
              } else {
                handleUpdateSpotlight(explainer.id);
              }
            }}
            customStyle={{
              width: "100%",
            }}
          />
        </div>
      </div>
      <div
        style={{
          position: "fixed",
          left: 0,
          top: 0,
          background: "rgba(0, 0, 0, 0.2)",
          width: "100%",
          height: "100%",
          zIndex: 3,
        }}
        onClick={() => {
          localStorage.removeItem("explainer_video_id");
          setExplainerVideoId(null);
        }}
      />
    </>,
    document.body
  );
}

const UPDATE_SPOTLIGHT = gql`
  mutation ($id: ID!, $spotlights: Json) {
    updateUser(id: $id, spotlights: $spotlights) {
      id
      spotlights
    }
  }
`;
