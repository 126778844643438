import Moment from "moment";
import { LinearScale } from "chart.js";
// Custom Y-Axis Scale
class CustomYAxis extends LinearScale {
  constructor(cfg) {
    super(cfg);
  }

  // Set data limits for the y-axis (based on the dataset values)
  determineDataLimits() {
    const datasets = this.chart.data.datasets;
    const min = 0;
    const ceiling = Math.ceil(
      Math.max(
        ...datasets
          .filter((dataset) => dataset.yAxisID === this.id)
          .map((dataset) => Math.max(...dataset.data.map((point) => point.y)))
      )
    );
    let max = ceiling;
    // if max is less than 6, set it to 6
    if (max < 6) {
      max = 6;
    }
    if (max > 6) {
      // make sure max is divisible by 6
      while (max % 6 !== 0) {
        max++;
      }
    }
    if (ceiling === max) {
      max = max + 6;
    }

    this.min = min;
    this.max = max;
  }

  // Build the ticks for the y-axis based on the min/max values
  buildTicks() {
    const ticks = [];
    let range = this.max - this.min;
    if (range < 6) {
      range = 6;
    }
    if (range > 6) {
      // make sure range is divisible by 6
      while (range % 6 !== 0) {
        range++;
      }
    }
    const stepSize = range / 6;
    for (let i = 0; i <= 6; i++) {
      ticks.push(this.min + i * stepSize);
    }
    return ticks.map((tick) => ({ value: tick }));
  }

  // Get the pixel position for a value (used for rendering data points)
  getPixelForValue(value) {
    const pixelRange = this.bottom - this.top; // Height of the y-axis
    const valueRange = this.max - this.min;
    return this.bottom - ((value - this.min) / valueRange) * pixelRange;
  }

  // Draw the custom y-axis labels inside the chart area
  draw(chartArea) {
    const { ctx } = this;

    // Style the labels
    ctx.save();
    ctx.font = "300 12px Museo Sans"; // Customize font style
    ctx.fillStyle = "rgba(0, 0, 0, 0.54)"; // Customize text color

    // Draw the grid lines and labels
    const ticks = this.buildTicks();
    const tickWidth = 24; // Adjust how far the label is from the chart content

    ticks.forEach((tick) => {
      const pixel = this.getPixelForValue(tick.value);

      // Draw the grid line at the tick position
      ctx.beginPath();
      ctx.lineWidth = 1;
      ctx.strokeStyle = "#EEE"; // Light grid line color
      ctx.moveTo(chartArea.left, pixel);
      ctx.lineTo(chartArea.right, pixel);
      ctx.stroke();

      // Draw the label inside the chart
      ctx.fillText(
        Math.round(tick.value).toString(),
        this.left + tickWidth,
        pixel + 18
      );
    });

    ctx.restore();
  }
}
CustomYAxis.id = "customYAxis";

// Custom Y-Axis Right
// Custom Y-Axis Scale for the right (0 to 60)
class RightYAxis extends LinearScale {
  constructor(cfg) {
    super(cfg);
  }

  // Set data limits for the right y-axis (0 to 60)
  determineDataLimits() {
    const datasets = this.chart.data.datasets;

    const min = 0;
    const ceiling = Math.ceil(
      Math.max(
        ...datasets
          .filter((dataset) => dataset.yAxisID === this.id)
          .map((dataset) => Math.max(...dataset.data.map((point) => point.y)))
      )
    );
    let max = ceiling;

    // if max is less than 6, set it to 6
    if (max < 6) {
      max = 6;
    }
    if (max > 6) {
      // make sure max is divisible by 6
      while (max % 6 !== 0) {
        max++;
      }
    }
    if (ceiling === max) {
      max = max + 6;
    }

    this.min = min;
    this.max = max;
  }

  // Build the ticks for the right y-axis
  buildTicks() {
    const leftYAxis = this.chart.scales.yLeft;
    const tickAmount = leftYAxis.ticks.length;
    const ticks = [];
    const stepSize = (this.max - this.min) / (tickAmount - 1); // Customize the number of ticks
    for (let i = 0; i <= tickAmount - 1; i++) {
      ticks.push(this.min + i * stepSize);
    }
    return ticks.map((tick) => ({ value: tick }));
  }

  // Get the pixel position for a value (used for rendering data points)
  getPixelForValue(value) {
    const pixelRange = this.bottom - this.top; // Height of the y-axis
    const valueRange = this.max - this.min;
    return this.bottom - ((value - this.min) / valueRange) * pixelRange;
  }

  // Draw the custom y-axis labels without grid lines
  draw() {
    const { ctx } = this;

    // Style the labels
    ctx.save();
    ctx.font = "300 12px Museo Sans"; // Customize font style
    ctx.fillStyle = "rgba(0, 0, 0, 0.54)"; // Customize text color
    ctx.textAlign = "right";

    // Draw the labels only (no grid lines)
    const ticks = this.buildTicks();
    const tickWidth = -45; // Adjust to position the label inside the chart from the right side

    ticks.forEach((tick) => {
      const pixel = this.getPixelForValue(tick.value);

      // Draw the label slightly below the grid line (adjust 10 pixels downward)
      ctx.fillText(
        Math.round(tick.value).toString(),
        this.right + tickWidth,
        pixel + 18
      ); // Add offset to position it below
    });

    ctx.restore();
  }
}

RightYAxis.id = "customYAxisRight";

const drawRectanglePlugin = {
  id: "drawRectanglePlugin",
  beforeDatasetsDraw: (chart, args, options) => {
    const {
      ctx,
      chartArea: { top, bottom },
      scales: { x },
    } = chart;

    // Convert start and end times into pixel values

    const rectXStart = x.getPixelForValue(new Date(options.startTime));
    const rectXEnd = x.getPixelForValue(new Date(options.endTime));

    // Set rectangle's height to cover the entire chart
    const rectHeight = bottom - top;

    const gradient = ctx.createLinearGradient(0, top, 0, bottom);
    gradient.addColorStop(1, "rgba(255, 120, 40, 0.10)"); // Top color
    gradient.addColorStop(0, "rgba(255, 120, 40, 0.00)"); // Bottom color

    // Draw the rectangle only if both start and end times are valid
    if (!isNaN(rectXStart) && !isNaN(rectXEnd)) {
      ctx.save();
      ctx.fillStyle = gradient; // Use the color provided in options

      // Draw the rectangle from the start time to the end time
      ctx.fillRect(rectXStart, top, rectXEnd - rectXStart, rectHeight);

      ctx.restore();
    }
  },
};

const crossHair = (event, elements, chart) => {
  const {
    ctx,
    chartArea: { top, bottom },
  } = chart;
  const canvasPosition = {
    x: event.native.offsetX, // Get x position of hover in pixels
  };

  // Clear the chart (to remove any previously drawn lines)
  chart.update("none");

  // Draw the hover line
  ctx.save();
  ctx.beginPath();
  ctx.moveTo(canvasPosition.x, top); // Start at the top of the chart
  ctx.lineTo(canvasPosition.x, bottom); // Draw to the bottom of the chart
  ctx.lineWidth = 1;
  ctx.strokeStyle = "#e2e2e2"; // Black color for the hover line
  ctx.stroke();
  ctx.restore();
};

function parseAndRound(value) {
  console.log("Original Value:", value);

  // Remove spaces
  value = value.trim();

  // Try to detect locale formatting issues
  if (/[,]/.test(value) && !/[.]/.test(value)) {
    // If only commas are present, assume comma is the decimal separator
    value = value.replace(",", ".");
  } else if (/\d{1,3}(,\d{3})*(\.\d+)?/.test(value)) {
    // If the number looks like "1,234.56", remove thousands separator
    value = value.replace(/,/g, "");
  } else if (/\d{1,3}(\.\d{3})*(,\d+)?/.test(value)) {
    // If the number looks like "1.234,56", assume dot is a thousands separator
    value = value.replace(/\./g, "").replace(",", ".");
  }

  console.log("Formatted Value:", value);

  // Convert to number
  let numberValue = Number(value);

  // Check if the conversion is valid
  if (isNaN(numberValue)) {
    console.error("Invalid number:", value);
    return null; // or handle appropriately
  }

  // Determine how to round the number based on decimal places
  const decimalPart = value.split(".")[1]; // Get decimal part if it exists

  if (decimalPart && decimalPart.length > 2) {
    // If more than two decimal places, treat as whole number
    const adjustedValue = Math.round(
      numberValue * Math.pow(10, decimalPart.length)
    );
    console.log("Transformed Value:", adjustedValue);
    return adjustedValue;
  } else {
    // Otherwise, round normally
    const roundedValue = Math.round(numberValue);
    console.log("Rounded Value:", roundedValue);
    return roundedValue;
  }
}

const tooltip = {
  enabled: false,
  external: (context) => {
    const tooltipData = context.chart.config.options.plugins.tooltipData || {};
    const format12h = tooltipData.format12h || false;
    const noVolumeStaffing = tooltipData.noVolumeStaffing || false;
    const granularity = tooltipData.granularity || "day";
    const tooltipModel = context.tooltip;
    const chartData = context.chart.data;

    // Select or create custom tooltip element
    let tooltipEl = document.getElementById("custom-tooltip");

    if (!tooltipEl) {
      tooltipEl = document.createElement("div");
      tooltipEl.id = "custom-tooltip";
      tooltipEl.innerHTML = "<span></span>";
      document.body.appendChild(tooltipEl);
    }
    // Hide if no tooltip
    if (tooltipModel.opacity === 0) {
      tooltipEl.style.opacity = 0;
      return;
    }

    // Set caret position
    tooltipEl.classList.remove("above", "below", "no-transform");
    if (tooltipModel.yAlign) {
      tooltipEl.classList.add(tooltipModel.yAlign);
    } else {
      tooltipEl.classList.add("no-transform");
    }

    // Set text content
    if (tooltipModel.body) {
      const titleLines = tooltipModel.title || [];
      const bodyLines = tooltipModel.body.map((b) => b.lines);

      let innerHtml = "<div>";

      titleLines.forEach(function (title: string) {
        let titleDate;
        if (granularity === "day") {
          titleDate = Moment(title, "MMM D, YYYY, h:mm:ss a").format(
            format12h ? "h:mma, ddd D MMM, YYYY" : "HH:mm, ddd D MMM, YYYY"
          );
        } else if (granularity === "week") {
          titleDate = Moment(title, "MMM D, YYYY, h:mm:ss a").format(
            format12h ? "h:mma, ddd D MMM, YYYY" : "HH:mm, ddd D MMM, YYYY"
          );
        } else if (granularity === "month") {
          titleDate = Moment(title, "MMM D, YYYY, h:mm:ss a").format(
            format12h ? "ddd D MMM, YYYY" : "ddd D MMM, YYYY"
          );
        } else {
          titleDate = Moment(title, "MMM D, YYYY, h:mm:ss a").format(
            format12h ? "ddd D MMM, YYYY" : "ddd D MMM, YYYY"
          );
        }
        innerHtml += `<div class="custom-tooltip-title" style="border-bottom: none;">${titleDate}</div>`;
      });
      innerHtml += "</div>";

      // innerHtml +=
      //   "<div class='custom-tooltip-body-row'>Product release<div>icon</div></div>";

      const bodyLinesNoDoubles = bodyLines
        .sort((a, b) => {
          const valueA = a[0].split(":")[1].trim();
          const valueB = b[0].split(":")[1].trim();
          return parseFloat(valueB) - parseFloat(valueA);
        })
        .reduce((acc, curr) => {
          const values = curr[0].split(":");
          const name = values[0].trim();
          const accName = acc[acc.length - 1]?.[0].split(":")[0].trim();

          if (accName !== name) {
            acc.push(curr);
          }
          return acc;
        }, []);

      const general: {
        name: string;
        value: string;
        lineColor: string;
      }[] = [];

      const staffingItems = [
        "Capacity",
        "Previous Capacity",
        "Required",
        "Previous Required",
        "Scheduled",
        "Available",
      ];
      const staffing: {
        name: string;
        value: string;
        lineColor: string;
      }[] = [];
      const staffingLabel = "Staffing";
      const volumeItems = [
        "Forecast Upper Bound",
        "Actual",
        "Forecast",
        "Previous Actual",
        "Forecast Lower Bound",
      ];
      const volume: {
        name: string;
        value: string;
        lineColor: string;
      }[] = [];
      const volumeLabel = "Volume";

      bodyLinesNoDoubles.forEach(function (body) {
        const values = body[0].split(":");
        const name = values[0].trim();
        const value = values[1].trim();
        // need to replace all instances of " " with ""
        const formattedName = name.replaceAll(" ", "").toLowerCase();
        const line = chartData.datasets.find(
          (dataset) => dataset.id.toLowerCase() === formattedName
        );
        const lineColor = line?.borderColor;
        const dashedBorder = line?.borderDash;

        const initialValue = value.replaceAll(".", "");
        const baseValue = initialValue.replaceAll(",", ".");
        const formattedValue = parseFloat(baseValue);
        const roundedValue = Math.round(formattedValue);
        const item = {
          name,
          value: roundedValue,
          lineColor,
          dashedBorder,
        };

        if (noVolumeStaffing) {
          general.push(item);
        } else if (staffingItems.includes(name)) {
          staffing.push(item);
        } else if (volumeItems.includes(name)) {
          volume.push(item);
        }
      });

      if (staffing.length > 0) {
        innerHtml += `<div class="custom-tooltip-label">${staffingLabel}</div>`;
        innerHtml += "<div class='custom-tooltip-body'>";
        for (const item of staffing) {
          const isDashed = item.dashedBorder;
          // if dashedBorder the background is a little transparent
          const dotColor = item.dashedBorder
            ? item.lineColor + "10"
            : item.lineColor;
          innerHtml += `<div class="custom-tooltip-row"><div class="custom-tooltip-dot" style="background: ${dotColor}; border: ${isDashed ? `1px dashed ${item.lineColor}` : "none"}"></div><div>${item.name}</div><div>${item.value}</div></div>`;
        }
        innerHtml += "</div>";
      }
      if (volume.length > 0) {
        innerHtml += `<div class="custom-tooltip-label">${volumeLabel}</div>`;
        innerHtml += "<div class='custom-tooltip-body'>";
        for (const item of volume) {
          innerHtml += `<div class="custom-tooltip-row"><div class="custom-tooltip-dot" style="background: ${item.lineColor}"></div><div>${item.name}</div><div>${item.value}</div></div>`;
        }
        innerHtml += "</div>";
      }
      if (general.length > 0) {
        innerHtml += "<div class='custom-tooltip-body with-border'>";
        for (const item of general) {
          const isDashed = item.dashedBorder;
          // if dashedBorder the background is a little transparent
          const dotColor = item.dashedBorder
            ? item.lineColor + "10"
            : item.lineColor;
          innerHtml += `<div class="custom-tooltip-row"><div class="custom-tooltip-dot" style="background: ${dotColor}; border: ${isDashed ? `1px dashed ${item.lineColor}` : "none"}"></div><div>${item.name}</div><div>${item.value}</div></div>`;
        }
        innerHtml += "</div>";
      }
      innerHtml += "</div>";

      // select ID
      const tableRoot = tooltipEl.querySelector("span");
      tableRoot.innerHTML = innerHtml;
    }

    // Set position
    const position = context.chart.canvas.getBoundingClientRect();

    tooltipEl.style.opacity = 1;

    tooltipEl.style.position = "absolute";
    tooltipEl.style.zIndex = "1000";

    tooltipEl.style.left =
      position.left + window.pageXOffset + tooltipModel.caretX + 12 + "px";
    tooltipEl.style.top =
      position.top + window.pageYOffset + tooltipModel.caretY + "px";

    // if it doesn't fit on the screen, flip it to the right
    if (tooltipEl.offsetLeft + tooltipEl.offsetWidth > window.innerWidth) {
      tooltipEl.style.left =
        position.left +
        window.pageXOffset +
        tooltipModel.caretX -
        tooltipEl.offsetWidth +
        -12 +
        "px";
    }

    tooltipEl.style.font = tooltipModel.options.bodyFont.string;
    tooltipEl.style.pointerEvents = "none"; // Disable pointer events
  },
};

const customXAxisLabels = {
  id: "customXAxisLabels",
  afterDraw(chart) {
    const { ctx, chartArea, scales } = chart;
    const config = chart.config.options.plugins.customXAxisLabels || {};
    const range = config.range || "month";
    const format12h = config.format12h || false;
    const xAxis = scales.x;

    ctx.save();
    ctx.textAlign = "center";
    ctx.textBaseline = "middle";
    ctx.font = "300 12px Museo Sans";
    ctx.fillStyle = "rgba(0, 0, 0, 0.54)";

    xAxis.ticks.forEach((tick, index) => {
      let x = xAxis.getPixelForTick(index);
      const date = Moment(tick.value);

      const isCurrentYear = date.isSame(Moment(), "year");

      // is final one
      const isFinalTick = index === xAxis.ticks.length - 1;
      let drawTick = true;

      // positions
      const initialPosition = chartArea.bottom + 17;
      const secondaryPosition = chartArea.bottom + 32;

      if (range === "year") {
        ctx.fillText(
          date.format(`MMM ${isCurrentYear ? "" : "YYYY"}`),
          x,
          initialPosition
        );
      } else if (range === "quarter") {
        // Draw day
        ctx.fillText(date.format("D"), x, initialPosition);

        // Draw month below
        ctx.fillText(
          date.format(`MMM ${isCurrentYear ? "" : "YYYY"}`),
          x,
          secondaryPosition
        );
      } else if (range === "month") {
        // Draw day
        ctx.fillText(date.format("D"), x, initialPosition);

        // Draw month below
        ctx.fillText(date.format("MMM"), x, secondaryPosition);
      } else if (range === "week") {
        if (!isFinalTick) {
          // Draw day
          ctx.fillText(date.format("ddd"), x, initialPosition);

          // Draw month below
          ctx.fillText(date.format("MMM D"), x, secondaryPosition);
        } else {
          drawTick = false;
        }
      } else if (range === "day") {
        if (!isFinalTick) {
          let formattedDate = date.format(format12h ? "h:mma" : "HH:mm");
          if (format12h) {
            // remove final letter if it's a
            formattedDate = formattedDate.slice(0, -1);
          }
          ctx.fillText(formattedDate, x, initialPosition);
        } else {
          drawTick = false;
        }
      } else {
        ctx.fillText(date.format("MMM D, YYYY"), x, initialPosition);
      }

      if (drawTick) {
        // draw small vertical line
        ctx.beginPath();
        ctx.moveTo(x, chartArea.bottom);
        ctx.lineTo(x, chartArea.bottom + 6);
        ctx.lineWidth = 1;
        ctx.strokeStyle = "rgba(0, 0, 0, 0.12)";
        ctx.stroke();
      }
    });

    ctx.restore();
  },
};

const customYAxisLabels = {
  id: "customYAxisLabels",
  afterDraw(chart) {
    const { ctx, chartArea, scales } = chart;
    const yAxis = scales.y;
    if (!yAxis) return;

    ctx.save();
    ctx.textAlign = "right";
    ctx.textBaseline = "middle";
    ctx.font = "300 12px Museo Sans";
    ctx.fillStyle = "rgba(0, 0, 0, 0.54)";
    yAxis.ticks.forEach((tick, index) => {
      const y = yAxis.getPixelForTick(index);
      ctx.fillText(tick.value, chartArea.left - 10, y);
    });

    ctx.restore();
  },
};

const drawGridLines = {
  id: "drawGridLines",
  beforeDraw(chart) {
    const { ctx, chartArea, scales } = chart;
    const yAxis = scales.y;
    if (!yAxis) return;

    yAxis.ticks.forEach((tick, index) => {
      const y = yAxis.getPixelForTick(index);

      ctx.strokeStyle = "#eee";
      ctx.beginPath();
      ctx.moveTo(chartArea.left, y);
      ctx.lineTo(chartArea.right, y);
      ctx.stroke();
    });
  },
};

export {
  crossHair,
  tooltip,
  drawRectanglePlugin,
  CustomYAxis,
  RightYAxis,
  customXAxisLabels,
  customYAxisLabels,
  drawGridLines,
};
