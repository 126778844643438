import React, { CSSProperties } from "react";
import { css, select as $ } from "glamor";

export default function ToolTip({
  text,
  link,
  icon,
  isAbove,
  customStyle,
  customArrowStyle,
}: {
  text?: string;
  link?: string;
  icon?: string;
  isAbove?: boolean;
  customStyle?: CSSProperties;
  customArrowStyle?: CSSProperties;
}) {
  const noTextBlock = link || !text;
  return (
    <div
      {...css(
        {
          display: "inline-block",
          cursor: link ? "pointer" : "default",
        },
        $(":hover .tt123", {
          visibility: !link ? "visible" : "",
        })
      )}
      onClick={() => {
        if (link) {
          window.open(link);
        }
      }}
    >
      <div {...css({ position: "relative" })}>
        {!noTextBlock && (
          <span
            className="tt123"
            {...css(
              {
                visibility: "hidden",
                background: "black",
                color: "white",
                padding: "6px 12px",
                borderRadius: 5,
                position: "absolute",
                zIndex: 1,
                textAlign: "left",
                top: !isAbove ? "100%" : "auto",
                bottom: !isAbove ? "auto" : "100%",
                left: "50%",
                fontSize: 14,
                fontWeight: 400,
                boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
                lineHeight: "17px",
                cursor: "default",
              },
              customStyle,
              $(
                "::after ",
                {
                  left: 0,
                  content: " ",
                  display: "block",
                  position: "absolute",
                  bottom: !isAbove ? "100%" : "auto",
                  top: !isAbove ? "auto" : "100%",
                  width: 0,
                  height: 0,
                  borderBottom: !isAbove
                    ? "4px solid #353535"
                    : "6px solid transparent",
                  borderTop: !isAbove
                    ? "6px solid transparent"
                    : "4px solid #353535",
                  borderLeft: "4px solid transparent",
                  borderRight: "4px solid transparent",
                },
                customArrowStyle
              )
            )}
          >
            {text}
          </span>
        )}
      </div>
      <span
        {...css({
          fontSize: 12,
          fontWeight: 900,
          width: 16,
          lineHeight: "16px",
          textAlign: "center",
          background: "#E2E2E2",
          borderRadius: 16,
          display: "inline-block",
          color: "rgba(0,0,0,0.87)",
        })}
      >
        {icon ? icon : "?"}
      </span>
    </div>
  );
}
