import React from "react";

type IconProps = React.SVGProps<SVGSVGElement> & {
  color?: string;
};

const Undo03Icon: React.FC<IconProps> = (props: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width={props.width ? props.width : 24}
    height={props.height ? props.height : 24}
    color={props.color ? props.color : "#000000"}
    fill={"none"}
    {...props}
  >
    <path
      d="M3 8H15C18.3137 8 21 10.6863 21 14C21 17.3137 18.3137 20 15 20H11"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7 12L3 8L7 4"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const Redo03Icon: React.FC<IconProps> = (props: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width={props.width ? props.width : 24}
    height={props.height ? props.height : 24}
    color={props.color ? props.color : "#000000"}
    fill={"none"}
    {...props}
  >
    <path
      d="M21 8H9C5.68629 8 3 10.6863 3 14C3 17.3137 5.68629 20 9 20H13"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17 12L21 8L17 4"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const SentIcon: React.FC<IconProps> = (props: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width={props.width ? props.width : 24}
    height={props.height ? props.height : 24}
    color={props.color ? props.color : "#000000"}
    fill={"none"}
    {...props}
  >
    <path
      d="M11.5 12.5L15 9"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.74669 8.40628C1.68668 8.78486 1.77814 10.3132 2.87573 10.5627L11.5 12.5L13.4373 21.1243C13.6868 22.2219 15.2151 22.3133 15.5937 21.2533L21.9322 3.50557C22.2514 2.61167 21.3883 1.74856 20.4944 2.06781L2.74669 8.40628Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const MentionIcon: React.FC<IconProps> = (props: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width ? props.width : 20}
    height={props.height ? props.height : 20}
    color={props.color ? props.color : "#000000"}
    fill={"none"}
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      d="M13 7.00027V10.7502C13 11.9929 14.0073 13.0002 15.25 13.0002C16.4927 13.0002 17.5 11.9929 17.5 10.7502V10C17.5 5.85787 14.1422 2.5 10 2.5C5.85787 2.5 2.5 5.85787 2.5 10C2.5 14.1422 5.85787 17.5 10 17.5C11.6887 17.5 13.2471 16.9418 14.5007 16M13 10.0003C13 11.6572 11.6568 13.0002 10 13.0002C8.34317 13.0002 7 11.6572 7 10.0003C7 8.34342 8.34317 7.00027 10 7.00027C11.6568 7.00027 13 8.34342 13 10.0003Z"
      stroke="currentColor"
      stroke-opacity="1"
      stroke-width="1.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

const MagicIcon: React.FC<IconProps> = (props: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    {...props}
  >
    <path
      d="M8.33333 5.83325L7.90346 6.99496C7.33978 8.51825 7.05794 9.27992 6.50232 9.83559C5.94669 10.3912 5.18503 10.673 3.66171 11.2368L2.5 11.6666L3.66171 12.0964C5.18503 12.6602 5.94669 12.942 6.50232 13.4976C7.05794 14.0533 7.33978 14.8149 7.90346 16.3382L8.33333 17.4999L8.76317 16.3382C9.32692 14.8149 9.60875 14.0533 10.1643 13.4976C10.72 12.942 11.4817 12.6602 13.0049 12.0964L14.1667 11.6666L13.0049 11.2368C11.4817 10.673 10.72 10.3912 10.1643 9.83559C9.60875 9.27992 9.32692 8.51825 8.76317 6.99496L8.33333 5.83325Z"
      stroke="url(#paint0_linear_10459_337516)"
      stroke-width="1.25"
      stroke-linejoin="round"
    />
    <path
      d="M15 2.5L14.8158 2.99788C14.5742 3.65073 14.4534 3.97716 14.2152 4.21528C13.9772 4.4534 13.6507 4.57419 12.9979 4.81577L12.5 5L12.9979 5.18423C13.6507 5.42581 13.9772 5.5466 14.2152 5.78472C14.4534 6.02284 14.5742 6.34927 14.8158 7.00212L15 7.5L15.1842 7.00212C15.4258 6.34927 15.5466 6.02284 15.7847 5.78472C16.0228 5.5466 16.3493 5.42581 17.0021 5.18423L17.5 5L17.0021 4.81577C16.3493 4.57419 16.0228 4.4534 15.7847 4.21528C15.5466 3.97716 15.4258 3.65073 15.1842 2.99788L15 2.5Z"
      stroke="url(#paint1_linear_10459_337516)"
      stroke-width="1.25"
      stroke-linejoin="round"
    />
    <defs>
      <linearGradient
        id="paint0_linear_10459_337516"
        x1="14.1667"
        y1="17.4999"
        x2="1.1843"
        y2="15.7832"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FF7828" />
        <stop offset="0.34" stop-color="#FF3246" />
        <stop offset="0.68" stop-color="#AA32DC" />
        <stop offset="1" stop-color="#28AAFF" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_10459_337516"
        x1="17.5"
        y1="7.5"
        x2="11.9361"
        y2="6.76428"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FF7828" />
        <stop offset="0.34" stop-color="#FF3246" />
        <stop offset="0.68" stop-color="#AA32DC" />
        <stop offset="1" stop-color="#28AAFF" />
      </linearGradient>
    </defs>
  </svg>
);

const RadiantMagicIcon: React.FC<IconProps> = (props: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.7034 6.73972C10.5945 6.4454 10.3138 6.25 10 6.25C9.68617 6.25 9.40552 6.4454 9.29661 6.73972L8.78076 8.13378C8.08054 10.0261 7.79598 10.7489 7.27245 11.2725C6.74893 11.796 6.02612 12.0805 4.13378 12.7808L2.73972 13.2966C2.4454 13.4055 2.25 13.6862 2.25 14C2.25 14.3138 2.4454 14.5945 2.73972 14.7034L4.13378 15.2192C6.02612 15.9195 6.74893 16.204 7.27245 16.7275C7.79598 17.2511 8.08054 17.9739 8.78077 19.8662L9.29661 21.2603C9.40552 21.5546 9.68617 21.75 10 21.75C10.3138 21.75 10.5945 21.5546 10.7034 21.2603L11.2192 19.8662C11.9195 17.9739 12.204 17.2511 12.7275 16.7275C13.2511 16.204 13.9739 15.9195 15.8662 15.2192L17.2603 14.7034C17.5546 14.5945 17.75 14.3138 17.75 14C17.75 13.6862 17.5546 13.4055 17.2603 13.2966L15.8662 12.7808C13.9739 12.0805 13.2511 11.796 12.7275 11.2725C12.204 10.7489 11.9195 10.0261 11.2192 8.13378L10.7034 6.73972ZM18.7034 2.73972C18.5945 2.4454 18.3138 2.25 18 2.25C17.6862 2.25 17.4055 2.4454 17.2966 2.73972L17.0755 3.33717C16.7618 4.18495 16.6705 4.38548 16.528 4.528C16.3855 4.67053 16.1849 4.76183 15.3372 5.07553L14.7397 5.29661C14.4454 5.40552 14.25 5.68617 14.25 6C14.25 6.31383 14.4454 6.59448 14.7397 6.70339L15.3372 6.92447C16.185 7.23817 16.3855 7.32947 16.528 7.472C16.6705 7.61452 16.7618 7.81505 17.0755 8.66282L17.2966 9.26028C17.4055 9.5546 17.6862 9.75 18 9.75C18.3138 9.75 18.5945 9.5546 18.7034 9.26028L18.9245 8.66283C19.2382 7.81505 19.3295 7.61452 19.472 7.47199C19.6145 7.32947 19.8151 7.23817 20.6628 6.92447L21.2603 6.70339C21.5546 6.59448 21.75 6.31383 21.75 6C21.75 5.68617 21.5546 5.40552 21.2603 5.29661L20.6628 5.07553C19.8151 4.76183 19.6145 4.67053 19.472 4.528C19.3295 4.38548 19.2382 4.18495 18.9245 3.33717L18.7034 2.73972Z"
      fill="url(#paint0_linear_10717_687851)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_10717_687851"
        x1="21.75"
        y1="21.75"
        x2="0.0508993"
        y2="18.8807"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FF7828" />
        <stop offset="0.34" stop-color="#FF3246" />
        <stop offset="0.68" stop-color="#AA32DC" />
        <stop offset="1" stop-color="#28AAFF" />
      </linearGradient>
    </defs>
  </svg>
);

const UserGroupIcon: React.FC<IconProps> = (props: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width ? props.width : 16}
    height={props.height ? props.height : 16}
    viewBox="0 0 16 16"
    fill="none"
    {...props}
  >
    <path
      d="M11.667 3.33301C12.2648 3.67882 12.667 4.25939 12.667 4.99968C12.667 5.73996 12.2648 6.32053 11.667 6.66634M12.667 9.33301C13.8537 9.99281 14.667 11.4403 14.667 12.9997"
      stroke="black"
      stroke-opacity="0.87"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M4.33301 3.33301C3.73521 3.67881 3.33301 4.25939 3.33301 4.99968C3.33301 5.73996 3.73521 6.32053 4.33301 6.66634M3.33301 9.33301C2.14635 9.99281 1.33301 11.4403 1.33301 12.9997"
      stroke="black"
      stroke-opacity="0.87"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M8.58301 9.33301H7.41634C5.16118 9.33301 3.33301 11.2731 3.33301 13.6663H12.6663C12.6663 11.2731 10.8381 9.33301 8.58301 9.33301Z"
      stroke="black"
      stroke-opacity="0.87"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M8.00033 6.99967C9.28899 6.99967 10.3337 5.95501 10.3337 4.66634C10.3337 3.37768 9.28899 2.33301 8.00033 2.33301C6.71166 2.33301 5.66699 3.37768 5.66699 4.66634C5.66699 5.95501 6.71166 6.99967 8.00033 6.99967Z"
      stroke="black"
      stroke-opacity="0.87"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export {
  Undo03Icon,
  Redo03Icon,
  SentIcon,
  MentionIcon,
  MagicIcon,
  RadiantMagicIcon,
  UserGroupIcon,
};
