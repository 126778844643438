import styles from "./Forecast.module.css";
import { formatHours } from "../../helpers/styles";

export default function Metrics(props: {
  metrics: {
    label: string;
    value: number;
    isPercentage?: boolean;
  }[];
}) {
  return (
    <div className={styles.stats}>
      {props.metrics.map((metric) => (
        <Metric
          key={metric.label}
          label={metric.label}
          value={metric.value}
          isPercentage={metric.isPercentage}
          isRounded={metric.isRounded}
        />
      ))}
    </div>
  );
}

function Metric({
  label,
  value,
  isPercentage,
  isRounded,
}: {
  label: string;
  value: number;
  isPercentage?: boolean;
  isRounded?: boolean;
}) {
  return (
    <div>
      <div>{label}</div>
      <div>
        {value
          ? isRounded
            ? Math.round(value)
            : formatHours(value.toFixed(2))
          : "-"}
        {value ? (
          isPercentage ? (
            <span style={{ fontSize: 24 }}>%</span>
          ) : (
            ""
          )
        ) : (
          ""
        )}
      </div>
    </div>
  );
}
