import React, { useState } from "react";
import Moment from "moment-timezone";
import { css, select as $ } from "glamor";

import Button from "../toolBox/Button";

import { colors } from "../../helpers/styles";
import { getMonday, getWeek, capitalizeString } from "../../helpers/functions";
import OutsideAlerter from "../clickOutside/OutsideAlerter";

function DateRangePicker({
  startDate,
  endDate,
  rangeType,
  handleFocus,
  applyFunction,
  customStyle,
}) {
  const [rangeTypeSelection, selectRangeType] = useState(rangeType);
  const [selectType, setSelectType] = useState("end");
  const [calendarDate, selectCalendarDate] = useState(startDate);
  const [startDateSelection, selectStartDate] = useState(startDate);
  const [endDateSelection, selectEndDate] = useState(endDate);
  const nextMonth = Moment(calendarDate)
    .add(1, "month")
    .toISOString();

  function selectDate(date) {
    if (rangeTypeSelection === "day") {
      selectStartDate(
        Moment(date)
          .startOf("day")
          .toISOString()
      );
      selectEndDate(
        Moment(date)
          .endOf("day")
          .toISOString()
      );
    } else if (rangeTypeSelection === "week") {
      selectStartDate(
        Moment(date)
          .startOf("isoweek")
          .toISOString()
      );
      selectEndDate(
        Moment(date)
          .endOf("isoweek")
          .toISOString()
      );
    } else if (rangeTypeSelection === "month") {
      selectStartDate(
        Moment(date)
          .startOf("month")
          .toISOString()
      );
      selectEndDate(
        Moment(date)
          .endOf("month")
          .toISOString()
      );
    } else if (rangeTypeSelection === "custom") {
      if (selectType === "start") {
        if (Moment(date).isAfter(endDateSelection)) {
          selectEndDate(date);
          setSelectType("start");
        } else {
          selectStartDate(date);
          setSelectType("end");
        }
      } else {
        if (Moment(date).isBefore(startDateSelection)) {
          selectStartDate(date);
          setSelectType("end");
        } else {
          selectEndDate(date);
          setSelectType("start");
        }
      }
    }
  }

  const startMonth = Moment(calendarDate).isSame(new Date(), "year")
    ? Moment(calendarDate).format("MMMM")
    : Moment(calendarDate).format("MMMM YYYY");

  const endMonth = Moment(nextMonth).isSame(new Date(), "year")
    ? Moment(nextMonth).format("MMMM")
    : Moment(nextMonth).format("MMMM YYYY");

  const rangeCheck =
    rangeTypeSelection === "custom" &&
    Moment(startDateSelection)
      .add(1, "year")
      .endOf("month")
      .isBefore(Moment(calendarDate).add(2, "month"));

  return (
    <OutsideAlerter action={handleFocus}>
      <div
        {...css(
          {
            width: 624,
            height: 404,
            background: "white",
            boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
            borderRadius: 5,
            color: "rgba(0,0,0,0.87)",
            userSelect: "none",
          },
          customStyle
        )}
        data-testid="dateRangePicker"
      >
        <div
          {...css({
            float: "left",
            width: "100%",
            height: 56,
            padding: 12,
            boxSizing: "border-box",
          })}
        >
          <ArrowNav
            icon="bi_interface-left"
            selectCalendarDate={() =>
              selectCalendarDate(
                Moment(calendarDate)
                  .subtract(1, "month")
                  .toISOString()
              )
            }
          />
          <Month date={startMonth} customStyle={{ marginLeft: 12 }} />
          <ArrowNav
            icon="bi_interface-right"
            selectCalendarDate={() => {
              if (rangeCheck) {
                // don't go further
              } else {
                selectCalendarDate(
                  Moment(calendarDate)
                    .add(1, "month")
                    .toISOString()
                );
              }
            }}
            customStyle={{
              float: "right",
              color: rangeCheck && "rgba(0,0,0,0.38)",
              cursor: rangeCheck && "default",
            }}
          />
          <Month
            date={endMonth}
            customStyle={{ float: "right", marginRight: 12 }}
          />
        </div>
        <div {...css({ float: "left", width: "100%", marginLeft: 12 })}>
          <Calendar
            date={calendarDate}
            selectedStartDate={startDateSelection}
            selectedEndDate={endDateSelection}
            customStyle={{ marginRight: 12 }}
            selectDate={selectDate}
            rangeTypeSelection={rangeTypeSelection}
          />
          <Calendar
            date={nextMonth}
            selectedStartDate={startDateSelection}
            selectedEndDate={endDateSelection}
            selectDate={selectDate}
            rangeTypeSelection={rangeTypeSelection}
          />
        </div>
        <div
          {...css({
            float: "left",
            width: "100%",
            height: 56,
            marginTop: 6,
            borderTop: "1px solid #E2E2E2",
            paddingLeft: 12,
            paddingRight: 12,
            boxSizing: "border-box",
            lineHeight: "56px",
          })}
        >
          <Picker
            selectRangeType={(type) => {
              selectRangeType(type);
              if (type === "day") {
                selectEndDate(
                  Moment(startDateSelection)
                    .endOf("day")
                    .toISOString()
                );
              } else if (type === "week") {
                selectStartDate(
                  Moment(startDateSelection)
                    .startOf("isoweek")
                    .toISOString()
                );
                selectEndDate(
                  Moment(startDateSelection)
                    .endOf("isoweek")
                    .toISOString()
                );
              } else if (type === "month") {
                selectStartDate(
                  Moment(startDateSelection)
                    .startOf("month")
                    .toISOString()
                );
                selectEndDate(
                  Moment(startDateSelection)
                    .endOf("month")
                    .toISOString()
                );
              }
            }}
            rangeTypeSelection={rangeTypeSelection}
          />
          <DateRange
            selectedStartDate={startDateSelection}
            selectedEndDate={endDateSelection}
            selectType={selectType}
            setSelectType={setSelectType}
            rangeTypeSelection={rangeTypeSelection}
          />
          <div {...css({ float: "right", marginTop: 12 })}>
            <Button
              name="Cancel"
              size="medium"
              theme="grey-border"
              action={handleFocus}
              customStyle={{ marginRight: 12 }}
            />
            <Button
              name="Apply"
              size="medium"
              theme="blue-border"
              action={() =>
                applyFunction(
                  startDateSelection,
                  endDateSelection,
                  rangeTypeSelection
                )
              }
            />
          </div>
        </div>
      </div>
    </OutsideAlerter>
  );
}

export default DateRangePicker;

export function ArrowNav({ customStyle, icon, selectCalendarDate }) {
  return (
    <div
      {...css(
        {
          float: "left",
          width: 32,
          height: 32,
          borderRadius: 3,
          border: "1px solid #E2E2E2",
          lineHeight: "32px",
          fontSize: 18,
          textAlign: "center",
          color: "rgba(0,0,0,0.54)",
        },
        customStyle,
        $(":hover", {
          cursor: "pointer",
        })
      )}
      onClick={selectCalendarDate}
    >
      <span className={icon} />
    </div>
  );
}

function Month({ customStyle, date }) {
  return (
    <div
      {...css(
        {
          float: "left",
          width: 206,
          height: 32,
          lineHeight: "32px",
          textAlign: "center",
          fontSize: 16,
          fontWeight: 500,
        },
        customStyle
      )}
    >
      {date}
    </div>
  );
}

export function Calendar({
  date,
  selectedStartDate,
  selectedEndDate,
  selectDate,
  rangeTypeSelection,
  customStyle,
  today,
  events,
}) {
  return (
    <div {...css({ float: "left", width: 294 }, customStyle)}>
      <DaysRow />
      <Days
        date={date}
        selectedStartDate={selectedStartDate}
        selectedEndDate={selectedEndDate}
        selectDate={selectDate}
        rangeTypeSelection={rangeTypeSelection}
        today={today}
        events={events}
      />
    </div>
  );
}

function DaysRow() {
  const days = ["Mo", "Tu", "We", "Th", "Fr", "Sa", "Su"].map((day, i) => {
    return (
      <div
        key={i}
        {...css({
          float: "left",
          width: 42,
          fontSize: 12,
          color: "rgba(0,0,0,0.54)",
          lineHeight: "28px",
          textAlign: "center",
        })}
      >
        {day}
      </div>
    );
  });
  return (
    <div
      {...css({
        float: "left",
        width: "100%",
        borderTop: "1px solid #E2E2E2",
        borderBottom: "1px solid #E2E2E2",
        height: 28,
        boxSizing: "border-box",
      })}
    >
      {days}
    </div>
  );
}

function getRow({
  days = [],
  selectedStartDate,
  selectedEndDate,
  firstRow,
  selectDate,
  rangeTypeSelection,
  today,
  events,
}) {
  const row = days.map((day) => {
    let background = "white";
    let color = "rgba(0, 0, 0, 0.54)";
    let borderTopLeftRadius;
    let borderTopRightRadius;
    let borderBottomLeftRadius;
    let borderBottomRightRadius;
    let backgroundHasEvent = colors.blue;
    if (Moment(day).isBetween(selectedStartDate, selectedEndDate)) {
      background = "rgba(40, 170, 255, 0.1)";
      backgroundHasEvent = colors.blue;
    }
    if (Moment(day).isSame(selectedStartDate, "day")) {
      background =
        rangeTypeSelection === "custom"
          ? colors.blue
          : "rgba(40, 170, 255, 0.1)";
      color = rangeTypeSelection === "custom" && "white";
      borderTopLeftRadius = 3;
      borderBottomLeftRadius = 3;
      backgroundHasEvent = colors.blue;
    }
    if (Moment(day).isSame(selectedEndDate, "day")) {
      background =
        rangeTypeSelection === "custom"
          ? colors.blue
          : "rgba(40, 170, 255, 0.1)";
      color = rangeTypeSelection === "custom" && "white";
      borderBottomRightRadius = 3;
      borderTopRightRadius = 3;
      backgroundHasEvent = colors.blue;
    }
    if (today && Moment(day).isSame(new Date(), "day")) {
      background = colors.blue;
      color = "white";
      borderBottomRightRadius = 3;
      borderTopRightRadius = 3;
      borderTopLeftRadius = 3;
      borderBottomLeftRadius = 3;
      backgroundHasEvent = "white";
    }
    let hasEvent = false;
    if (events && events.length > 0) {
      events.forEach((event) => {
        if (Moment(event).isSame(day, "day")) {
          hasEvent = true;
        }
      });
    }
    return (
      <div
        key={day}
        {...css(
          {
            float: firstRow ? "right" : "left",
            width: 42,
            height: 42,
            color,
            textAlign: "center",
            lineHeight: "42px",
            boxSizing: "border-box",
            fontWeight: 300,
            background,
            borderTopLeftRadius,
            borderTopRightRadius,
            borderBottomLeftRadius,
            borderBottomRightRadius,
            position: "relative",
          },
          $(":hover", {
            background: "white",
            border: "1px solid " + colors.blue,
            color: colors.blue,
            cursor: "pointer",
            borderRadius: 3,
          })
        )}
        onClick={() => selectDate(day.toISOString())}
      >
        {Moment(day).format("D")}
        {hasEvent && (
          <div
            {...css({
              position: "absolute",
              bottom: 6,
              left: 19,
              width: 4,
              height: 4,
              borderRadius: 4,
              background: backgroundHasEvent,
            })}
          />
        )}
      </div>
    );
  });
  return (
    <div
      {...css({
        float: "left",
        width: 294,
        height: 42,
        fontSize: 14,
        color: "rgba(0,0,0,0.54)",
      })}
    >
      {row}
    </div>
  );
}

function getDays(date) {
  const week = getWeek(date);
  return week;
}

function Days({
  date,
  selectedStartDate,
  selectedEndDate,
  selectDate,
  rangeTypeSelection,
  today,
  events,
}) {
  const initialDate = getMonday(Moment(date).startOf("month"));
  const rowOneDays = getDays(initialDate).filter((day) =>
    Moment(day).isSame(date, "month")
  );
  const rowTwoDays = getDays(initialDate.add(7, "days"));
  const rowThreeDays = getDays(initialDate.add(7, "days"));
  const rowFourDays = getDays(initialDate.add(7, "days"));
  const rowFiveDays = getDays(initialDate.add(7, "days")).filter((day) =>
    Moment(day).isSame(date, "month")
  );
  const rowSixDays = getDays(initialDate.add(7, "days")).filter((day) =>
    Moment(day).isSame(date, "month")
  );

  const rowOneEvents = [];
  const rowTwoEvents = [];
  const rowThreeEvents = [];
  const rowFourEvents = [];
  const rowFiveEvents = [];
  const rowSixEvents = [];

  if (events && events.length > 0) {
    events.forEach((event) => {
      rowOneDays.forEach((day) => {
        if (Moment(day).isSame(event.startTime, "day")) {
          rowOneEvents.push(event.startTime);
        }
      });
      rowTwoDays.forEach((day) => {
        if (Moment(day).isSame(event.startTime, "day")) {
          rowTwoEvents.push(event.startTime);
        }
      });
      rowThreeDays.forEach((day) => {
        if (Moment(day).isSame(event.startTime, "day")) {
          rowThreeEvents.push(event.startTime);
        }
      });
      rowFourDays.forEach((day) => {
        if (Moment(day).isSame(event.startTime, "day")) {
          rowFourEvents.push(event.startTime);
        }
      });
      rowFiveDays.forEach((day) => {
        if (Moment(day).isSame(event.startTime, "day")) {
          rowFiveEvents.push(event.startTime);
        }
      });
      rowSixDays.forEach((day) => {
        if (Moment(day).isSame(event.startTime, "day")) {
          rowSixEvents.push(event.startTime);
        }
      });
      rowOneDays.forEach((day) => {
        if (Moment(day).isSame(event.startTime, "day")) {
          rowOneEvents.push(event.startTime);
        }
      });
    });
  }

  const rowOne = getRow({
    days: rowOneDays.reverse(),
    firstRow: true,
    selectedStartDate,
    selectedEndDate,
    selectDate,
    rangeTypeSelection,
    today,
    events: rowOneEvents,
  });
  const rowTwo = getRow({
    days: rowTwoDays,
    selectedStartDate,
    selectedEndDate,
    selectDate,
    rangeTypeSelection,
    today,
    events: rowTwoEvents,
  });
  const rowThree = getRow({
    days: rowThreeDays,
    selectedStartDate,
    selectedEndDate,
    selectDate,
    rangeTypeSelection,
    today,
    events: rowThreeEvents,
  });
  const rowFour = getRow({
    days: rowFourDays,
    selectedStartDate,
    selectedEndDate,
    selectDate,
    rangeTypeSelection,
    today,
    events: rowFourEvents,
  });
  const rowFive = getRow({
    days: rowFiveDays,
    selectedStartDate,
    selectedEndDate,
    selectDate,
    rangeTypeSelection,
    today,
    events: rowFiveEvents,
  });
  const rowSix = getRow({
    days: rowSixDays,
    selectedStartDate,
    selectedEndDate,
    selectDate,
    rangeTypeSelection,
    today,
    events: rowSixEvents,
  });

  return (
    <div {...css({ float: "left", width: 294, height: 252, marginTop: 6 })}>
      {rowOne}
      {rowTwo}
      {rowThree}
      {rowFour}
      {rowFive}
      {rowSixDays.length > 0 && rowSix}
    </div>
  );
}

function Picker({ selectRangeType, rangeTypeSelection }) {
  const [showList, setList] = useState(false);
  return (
    <div
      {...css({
        float: "left",
        position: "relative",
        fontSize: 14,
        fontWeight: 300,
        marginRight: 12,
      })}
    >
      <div
        {...css({ float: "left", cursor: "pointer" })}
        onClick={() => setList(true)}
      >
        <div {...css({ float: "left" })}>
          {capitalizeString(rangeTypeSelection)}{" "}
        </div>
        <div
          {...css({
            float: "left",
            marginLeft: 6,
            color: "rgba(0,0,0,0.54)",
            lineHeight: "59px",
          })}
          className="bi_interface-bottom"
        />
      </div>
      {showList && (
        <List
          rangeTypeSelection={rangeTypeSelection}
          selectRangeType={selectRangeType}
          handleFocus={() => setList(false)}
        />
      )}
    </div>
  );
}

function OutsideList({ handleFocus, rangeTypeSelection, selectRangeType }) {
  const list = [
    {
      id: 1,
      name: "Day",
      action: () => selectRangeType("day"),
      selected: rangeTypeSelection === "day",
    },
    {
      id: 2,
      name: "Week",
      action: () => selectRangeType("week"),
      selected: rangeTypeSelection === "week",
    },
    {
      id: 3,
      name: "Month",
      action: () => selectRangeType("month"),
      selected: rangeTypeSelection === "month",
    },
    {
      id: 4,
      name: "Custom",
      action: () => selectRangeType("custom"),
      selected: rangeTypeSelection === "custom",
    },
  ].map((item) => {
    return (
      <li
        key={item.id}
        id="date-range-list"
        {...css(
          {
            color: item.selected ? colors.blue : "rgba(0,0,0,0.54)",
            fontWeight: item.selected ? 500 : 300,
            fontSize: 14,
            width: "100%",
            height: 26,
            lineHeight: "20px",
            padding: "3px 6px",
            boxSizing: "border-box",
            borderRadius: 3,
            background: item.selected ? "rgba(0, 0, 0, 0.05)" : "white",
          },
          $(":hover", {
            background: "rgba(0, 0, 0, 0.05)",
            color: colors.blue,
            fontWeight: 500,
            cursor: "pointer",
          })
        )}
        onClick={() => {
          item.action();
          handleFocus();
        }}
      >
        {item.name}
      </li>
    );
  });
  return (
    <OutsideAlerter action={handleFocus}>
      <div
        {...css({
          position: "absolute",
          bottom: 12,
          width: 76,
          left: 0,
          background: "white",
          borderRadius: "5px",
          zIndex: 1,
          color: "rgba(0,0,0,0.54)",
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
        })}
      >
        <ul {...css({ margin: 0, padding: "9px 6px", listStyle: "none" })}>
          {list}
        </ul>
      </div>
    </OutsideAlerter>
  );
}

const List = OutsideList

function DateRange({
  selectType,
  setSelectType,
  selectedStartDate,
  selectedEndDate,
  rangeTypeSelection,
}) {
  return (
    <div {...css({ float: "left", fontSize: 14, fontWeight: 300 })}>
      <span
        {...css({
          textDecoration:
            selectType === "start" &&
            rangeTypeSelection === "custom" &&
            "underline",
          cursor: rangeTypeSelection === "custom" && "pointer",
        })}
        onClick={() => setSelectType("start")}
      >
        {Moment(selectedStartDate).format("ddd, D MMM")}
        {!Moment(selectedStartDate).isSame(new Date(), "year") &&
          Moment(selectedStartDate).format(" YYYY")}
      </span>{" "}
      {rangeTypeSelection !== "day" && (
        <span>
          –{" "}
          <span
            {...css({
              textDecoration:
                selectType === "end" &&
                rangeTypeSelection === "custom" &&
                "underline",
              cursor: rangeTypeSelection === "custom" && "pointer",
            })}
            onClick={() => setSelectType("end")}
          >
            {Moment(selectedEndDate).format("ddd, D MMM")}
            {!Moment(selectedEndDate).isSame(new Date(), "year") &&
              Moment(selectedEndDate).format(" YYYY")}
          </span>
        </span>
      )}
      {["day", "week"].includes(rangeTypeSelection) && (
        <span>
          <span {...css({ color: "#E2E2E2" })}> |</span> wk{" "}
          {Moment(selectedStartDate).format("W")}
        </span>
      )}
      <span {...css({ color: "#E2E2E2" })}> |</span> CEST
    </div>
  );
}
