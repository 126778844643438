import { useContext, useState } from "react";
import { startChatIntercom } from "../../helpers/functions";
import OptionsMenuSubtitle from "./OptionsMenuSubtitle";
import ToolTipWrapper from "./ToolTipWrapper";
import styles from "./ToolBox.module.css";
import { FeedbackForm } from "./Feedback";
import { UserContext } from "../App";
import { useApolloClient } from "@apollo/client";
import { GET_TEAM_BILLING_PLAN_QUERY } from "../Router";

const SCREENS: {
  [key: string]: {
    videos: {
      id: string;
      audience?: {
        teamClass: string;
        rights?: string;
      }[];
    }[];
    help_articles: {
      url: string;
      audience?: {
        teamClass: string;
        rights: string;
      }[];
    }[];
  };
} = {
  ["WORKLOAD"]: {
    videos: [],
    help_articles: [
      {
        url: "https://help.soon.works/",
      },
    ],
  },
  ["EVENT_DETAILS"]: {
    videos: [
      {
        id: "event_details_explainer",
        audience: [
          {
            teamClass: "ADMIN",
            rights: "write",
          },
          {
            teamClass: "SUPERADMIN",
            rights: "write",
          },
        ],
      },
    ],
    help_articles: [
      {
        url: "https://intercom.help/soonapp",
        audience: [
          {
            teamClass: "ADMIN",
            rights: "write",
          },
        ],
      },
    ],
  },
  ["EVENT_DETAILS_MY_SCHEDULE"]: {
    videos: [],
    help_articles: [
      {
        url: "https://help.soon.works/",
      },
    ],
  },
  ["USERS"]: {
    videos: [
      {
        id: "users_explainer",
        audience: [
          {
            teamClass: "SUPERADMIN",
          },
          {
            teamClass: "ADMIN",
          },
        ],
      },
    ],
    help_articles: [
      {
        url: "https://help.soon.works/",
      },
    ],
  },
  ["ACCOUNT"]: {
    videos: [],
    help_articles: [
      {
        url: "https://help.soon.works/",
      },
    ],
  },
  ["LEAVE"]: {
    videos: [],
    help_articles: [
      {
        url: "https://help.soon.works/",
      },
    ],
  },
  ["FORECASTS_OVERVIEW"]: {
    videos: [],
    help_articles: [
      {
        url: "https://help.soon.works/",
      },
    ],
  },
  ["FORECASTS_DATASET"]: {
    videos: [],
    help_articles: [
      {
        url: "https://help.soon.works/",
      },
    ],
  },
  ["BOARD"]: {
    videos: [
      {
        id: "board_explainer",
        audience: [
          {
            teamClass: "SUPERADMIN",
            rights: "write",
          },
          {
            teamClass: "ADMIN",
            rights: "write",
          },
        ],
      },
    ],
    help_articles: [
      {
        url: "https://help.soon.works/",
      },
    ],
  },
  ["BOARD_SETTINGS"]: {
    videos: [],
    help_articles: [
      {
        url: "https://help.soon.works/",
      },
    ],
  },
  ["MY_SCHEDULE"]: {
    videos: [
      {
        id: "my_schedule_explainer",
      },
    ],
    help_articles: [
      {
        url: "https://help.soon.works/",
      },
    ],
  },
};

export default function SupportCircle(props: { screen?: string }) {
  const currentUser = useContext(UserContext);
  const client = useApolloClient();
  const cachedData = client.readQuery({
    query: GET_TEAM_BILLING_PLAN_QUERY,
  });

  const [menu, setMenu] = useState("");
  const isEnterprise = cachedData?.getTeamBillingPlan?.plan === "enterprise";
  let screenDetails;
  if (props.screen) {
    screenDetails = SCREENS[props.screen as keyof typeof SCREENS];
  }

  let videoId;
  let helpArticleUrl = "";
  if (screenDetails) {
    if (screenDetails.videos) {
      videoId = screenDetails.videos.find((video) => {
        if (video.audience) {
          return video.audience.some((audience) => {
            const hasTeamClass = audience.teamClass === currentUser?.teamClass;
            const hasRights = audience.rights
              ? audience.rights === currentUser?.access?.rights
              : true;
            return hasTeamClass && hasRights;
          });
        }
        return true;
      })?.id;
    }
    if (screenDetails.help_articles) {
      helpArticleUrl =
        screenDetails.help_articles.find((article) => {
          if (article.audience) {
            return article.audience.some((audience) => {
              return (
                audience.teamClass === currentUser.teamClass &&
                audience.rights === currentUser?.access?.rights
              );
            });
          }
          return true;
        })?.url || "https://help.soon.works/";
    }
  }

  let items = [];
  if (!isEnterprise) {
    items.push({
      id: "ask-question",
      title: "Ask question",
      action: () => startChatIntercom(),
    });
  }
  items.push({
    id: "help-articles",
    title: "Help articles",
    action: () => {
      window.open(helpArticleUrl, "_blank");
    },
  });
  if (videoId) {
    items.push({
      id: "demo-video",
      title: "Demo video",
      action: () => {
        // // add a hash with video id to the current url
        // window.location.href = `${window.location.href}#${videoId}`;
        // use local or session storage to store the video id
        localStorage.setItem("explainer_video_id", videoId);
        window.dispatchEvent(new Event("storage"));
        // window.location.href = `${window.location.href}#${videoId}`;
      },
    });
  }
  items.push({
    id: "share-feedback",
    title: "Share feedback",
    action: () => {
      setMenu("feedback");
    },
    disableHandleFocus: true,
  });

  return (
    <div
      style={{
        position: "relative",
        display: "flex",
      }}
    >
      <div
        onClick={() => setMenu("help")}
        className={styles["support-circle"]}
        style={{
          background: menu === "help" ? "rgba(0, 0, 0, 0.05)" : "#fff",
        }}
      >
        <ToolTipWrapper
          text="Help and Resources"
          customStyle={{
            top: "30px !important",
            right: "-12px",
          }}
          customArrowStyle={{
            left: "auto",
            right: "16px",
          }}
        >
          <div
            style={{
              width: 16,
              height: 16,
              fontSize: 12,
              fontWeight: 900,
              borderRadius: "50%",
              background: "#e2e2e2",
              textAlign: "center",
              lineHeight: "16px",
            }}
          >
            ?
          </div>
        </ToolTipWrapper>
      </div>
      {menu === "help" && (
        <OptionsMenuSubtitle
          items={items}
          loading={false}
          name="Help and Resources"
          handleFocus={() => {
            setMenu("");
          }}
          customStyle={{
            position: "absolute",
            top: 37,
            left: "auto",
            width: 228,
            right: 0,
          }}
        />
      )}
      {menu === "feedback" && (
        <>
          <div
            style={{
              position: "absolute",
              top: 37,
              left: "auto",
              width: 264,
              right: 0,
              zIndex: 1000,
            }}
          >
            <FeedbackForm
              hasSwitcher={true}
              handleDismiss={() => setMenu("")}
              screen={props.screen}
              isFloating={true}
            />
          </div>
          <div
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              zIndex: 999,
              background: "transparent",
              cursor: "default",
            }}
            onClick={(e) => {
              e.stopPropagation();
              setMenu("");
            }}
          />
        </>
      )}
    </div>
  );
}
