import { useContext, useRef, useState } from "react";
import { gql, useQuery } from "@apollo/client";
import { useHistory, useLocation } from "react-router-dom";
import Moment from "moment-timezone";

import styles from "./Forecast.module.css";
import { colors } from "../../helpers/styles";

import LineChart from "./LineChart";
import DateRangeSwitcher from "./DateRangeSwitcher";
import PeriodSwitcher from "../toolBox/PeriodSwitcher";
import Check from "../toolBox/Check";
import Filter from "../toolBox/Filter";
import ToolTip from "../toolBox/ToolTip";

import { unixToISOString } from "../../helpers/functions";
import utils from "./utils";
import { TeamContext } from "../App";
import { useWindowSize } from "../../helpers/hooks";

export default function Overview(props: {
  format12h: boolean;
  displayTimezone: string;
}) {
  const history = useHistory();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const team = useContext(TeamContext);
  const userGroups = team.groups;

  const usersResponse = useQuery(GET_USERS, {
    variables: {
      teamId: team.id,
    },
  });

  let users: {
    id: string;
    name: string;
    teamClasses: {
      userIs: string;
    }[];
  }[] = usersResponse.data?.Users || [];
  users = users.filter(
    (user) =>
      user.teamClasses.length > 0 &&
      !user.teamClasses[0].userIs.includes("DEACTIVATED")
  );

  function handleUpdateSearchParams(newSearchParams: {
    startDate?: string;
    endDate?: string;
    granularity?: string;
    exclude?: any[];
    only?: any[];
  }) {
    const searchParams = new URLSearchParams(location.search);
    if (newSearchParams.startDate) {
      searchParams.set("startDate", newSearchParams.startDate);
    }
    if (newSearchParams.endDate) {
      searchParams.set("endDate", newSearchParams.endDate);
    }
    if (newSearchParams.granularity) {
      searchParams.set("granularity", newSearchParams.granularity);
    }
    if (newSearchParams.exclude) {
      searchParams.set("exclude", newSearchParams.exclude.join(","));
    }
    if (newSearchParams.only) {
      searchParams.set("only", newSearchParams.only.join(","));
    }
    history.push(`/forecasts/overview?${searchParams.toString()}`);
  }

  const startDate =
    unixToISOString(searchParams.get("startDate")) ||
    Moment().startOf("day").toISOString();
  const endDate =
    unixToISOString(searchParams.get("endDate")) ||
    Moment().endOf("day").toISOString();
  const granularity = searchParams.get("granularity") || "day";
  const exclude = searchParams.get("exclude") || "";
  const only = searchParams.get("only") || "";
  const filter = {
    exclude: exclude ? exclude.split(",") : [],
    only: only ? only.split(",") : [],
  };

  const response = useQuery(GET_FORECASTS, {
    variables: {
      startDate: startDate,
      endDate: endDate,
      filter,
    },
  });

  return (
    <Chart
      startDate={startDate}
      endDate={endDate}
      granularity={granularity}
      handleUpdateSearchParams={handleUpdateSearchParams}
      response={response}
      filter={filter}
      users={users}
      userGroups={userGroups}
      format12h={props.format12h}
      timezone={props.displayTimezone}
    />
  );
}

function Chart(props: {
  startDate: string;
  endDate: string;
  granularity: string;
  handleUpdateSearchParams: (params: any) => void;
  response: any;
  filter: {
    exclude: string[];
    only: string[];
  };
  users: {
    id: string;
    name: string;
    teamClasses: {
      userIs: string;
    }[];
  }[];
  userGroups: {
    id: string;
    name: string;
    description: string;
    users: { id: string }[];
  }[];
  format12h: boolean;
  timezone: string;
}) {
  const windowSize = useWindowSize();
  const [linesHidden, setLinesHidden] = useState<string[]>([]);
  const [isBreakdown, setIsBreakdown] = useState(false);
  const [filteredUserGroups, setFilteredUserGroups] = useState<string[]>([]);

  function toggleLine(lines: string[]) {
    let newLinesHidden = [...linesHidden];
    for (const line of lines) {
      if (linesHidden.includes(line)) {
        newLinesHidden = newLinesHidden.filter((l) => l !== line);
      } else {
        newLinesHidden = [...newLinesHidden, line];
      }
    }
    setLinesHidden(newLinesHidden);
  }

  const scrollHeaderRef = useRef<HTMLDivElement>(null);

  const result = props.response?.data?.getForecastOverview || {};
  const data = result.data || [];
  const interval = utils.getInterval(result.interval);

  const placeholderData = utils.getPlaceholderData(
    props.startDate,
    props.endDate,
    props.granularity
  );

  let datasets = [placeholderData];
  let staffingDatasets: {
    id: string;
    label: string;
    name: string;
    color: string;
    backgroundColor: string;
    staffing: { ds: string; agentsNeeded: number }[];
  }[] = [];
  const breakdownColors = [
    {
      color: "#F44336", // Red
      lighter: "#FDECEA", // Very Light Red (10%)
    },
    {
      color: "#03A9F4", // Light Blue
      lighter: "#E5F6FD", // Very Light Blue (10%)
    },
    {
      color: "#FFC107", // Amber
      lighter: "#FFF8E6", // Very Light Amber (10%)
    },
    {
      color: "#E91E63", // Pink
      lighter: "#FCE8EF", // Very Light Pink (10%)
    },
    {
      color: "#00BCD4", // Cyan
      lighter: "#E5F8FA", // Very Light Cyan (10%)
    },
    {
      color: "#9C27B0", // Purple
      lighter: "#F5E9F7", // Very Light Purple (10%)
    },
    {
      color: "#009688", // Teal
      lighter: "#E5F4F3", // Very Light Teal (10%)
    },
    {
      color: "#FF9800", // Orange
      lighter: "#FFF4E5", // Very Light Orange (10%)
    },
    {
      color: "#673AB7", // Deep Purple
      lighter: "#EFEBF7", // Very Light Deep Purple (10%)
    },
    {
      color: "#4CAF50", // Green
      lighter: "#EDF7ED", // Very Light Green (10%)
    },
    {
      color: "#FF5722", // Deep Orange
      lighter: "#FFEEE8", // Very Light Deep Orange (10%)
    },
    {
      color: "#3F51B5", // Indigo
      lighter: "#EBEDF7", // Very Light Indigo (10%)
    },
    {
      color: "#8BC34A", // Light Green
      lighter: "#F3F9EC", // Very Light Light Green (10%)
    },
    {
      color: "#795548", // Brown
      lighter: "#F1EEEC", // Very Light Brown (10%)
    },
    {
      color: "#2196F3", // Blue
      lighter: "#E8F4FD", // Very Light Blue (10%)
    },
    {
      color: "#CDDC39", // Lime
      lighter: "#FAFBEB", // Very Light Lime (10%)
    },
    {
      color: "#607D8B", // Blue Grey
      lighter: "#EFF2F3", // Very Light Blue Grey (10%)
    },
  ];
  if (isBreakdown) {
    const allDatasets = data;
    let colorIndex = 0;
    for (const dataset of allDatasets) {
      if (dataset.integration === "sum") continue;
      if (props.filter.exclude.includes(dataset.id)) continue;
      if (
        props.filter.only.length > 0 &&
        !props.filter.only.includes(dataset.id)
      )
        continue;

      const datasetId = dataset.name.replaceAll(" ", "").toLowerCase();
      const forecast = {
        id: "forecast" + datasetId,
        label: "Forecast " + dataset.name,
        data: dataset.forecast.map((item: { ds: string; forecast: number }) => {
          return {
            x: Moment.utc(item.ds, "YYYY-MM-DD HH:mm:ss").toDate(),
            y: item.forecast,
          };
        }),
        borderColor: breakdownColors[colorIndex].color,
        borderWidth: 2,
        pointRadius: 0,
        hidden: linesHidden.includes("forecast" + datasetId),
      };

      datasets.push(forecast);

      const actual = {
        id: "actual" + datasetId,
        label: "Actual " + dataset.name,
        data: dataset.actuals.map((item: { ds: string; y: number }) => {
          return {
            x: Moment.utc(item.ds, "YYYY-MM-DD HH:mm:ss").toDate(),
            y: item.y,
          };
        }),
        borderColor: breakdownColors[colorIndex].color,
        borderDash: [10, 5],
        borderWidth: 2,
        pointRadius: 0,
        hidden: linesHidden.includes("actual" + datasetId),
      };
      datasets.push(actual);

      // Staffing requirements
      staffingDatasets.push({
        id: "forecast" + datasetId,
        label: "Forecast " + dataset.name,
        name: dataset.name,
        staffing: dataset.staffing,
        color: breakdownColors[colorIndex].color,
        backgroundColor: breakdownColors[colorIndex].lighter,
      });

      colorIndex++;
    }
  } else {
    const sum = data.find(
      (item: { integration: string }) => item.integration === "sum"
    );

    const summedForecast = sum
      ? sum.forecast.map((item: { ds: string; forecast: number }) => {
          return {
            x: Moment.utc(item.ds, "YYYY-MM-DD HH:mm:ss").toDate(),
            y: item.forecast,
          };
        })
      : [];

    const summedActual = sum
      ? sum.actuals.map((item: { ds: string; y: number }) => {
          // temp because of data issues
          if (["day", "week"].includes(props.granularity)) {
            return {
              x: Moment.utc(item.ds, "YYYY-MM-DD HH:mm:ss").toDate(),
              y: item.y,
            };
          }
          return {
            x: Moment.utc(item.ds, "YYYY-MM-DD HH:mm:ss").toDate(),
            y: item.y,
          };
        })
      : [];

    datasets = [
      placeholderData,
      {
        id: "forecast",
        label: "Forecast",
        data: summedForecast,
        borderColor: colors.violet,
        borderWidth: 2,
        pointRadius: 0,
        hidden: linesHidden.includes("forecast"),
      },
      {
        id: "actual",
        label: "Actual",
        data: summedActual,
        borderColor: colors.orange,
        borderWidth: 2,
        pointRadius: 0,
        hidden: linesHidden.includes("actual"),
      },
    ];

    staffingDatasets = [
      {
        id: "forecast",
        label: "Forecast",
        name: "Forecast",
        staffing: sum ? sum.staffing : [],
        color: colors.violet,
        backgroundColor: colors.violet,
      },
    ];
  }

  const granularity = props.granularity;
  const rangeType = props.granularity;

  const teamUsers = new Set<string>();
  for (const userGroupId of filteredUserGroups) {
    const userGroup = props.userGroups.find(
      (userGroup) => userGroup.id === userGroupId
    );
    if (userGroup) {
      for (const user of userGroup.users) {
        teamUsers.add(user.id);
      }
    }
  }

  let teamCount = props.users.length;
  if (filteredUserGroups.length > 0) {
    teamCount = teamUsers.size;
  }

  let isSmallScreen = false;
  const windowSizeTreshold = 1330;
  if (windowSize?.width && windowSize?.width < windowSizeTreshold) {
    isSmallScreen = true;
  }
  return (
    <div
      style={{
        padding: 24,
        paddingBottom: 0,
        display: "flex",
        flexDirection: "column",
        height: "calc(100vh - 132px)",
        overflow: "hidden",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          paddingBottom: 24,
        }}
        ref={scrollHeaderRef}
      >
        <div style={{ display: "flex", gap: 24 }}>
          <DateRangeSwitcher
            startDate={props.startDate}
            endDate={props.endDate}
            granularity={granularity}
            handleUpdateSearchParams={props.handleUpdateSearchParams}
            isSmallScreen={isSmallScreen}
          />
          <div>
            <PeriodSwitcher
              setDateRange={(dateRange: {
                startDate: string;
                endDate: string;
                rangeType: string;
              }) => {
                props.handleUpdateSearchParams({
                  startDate: Moment(dateRange.startDate)
                    .startOf("day")
                    .unix()
                    .toString(),
                  endDate: Moment(dateRange.endDate)
                    .endOf("day")
                    .unix()
                    .toString(),
                  granularity: dateRange.rangeType,
                });
              }}
              startDate={props.startDate}
              endDate={props.endDate}
              switchMonths={(direction: "forward" | "back") => {
                utils.handleSwitchDateRange({
                  direction,
                  startDate: props.startDate,
                  endDate: props.endDate,
                  granularity: props.granularity,
                  handleUpdateSearchParams: props.handleUpdateSearchParams,
                });
              }}
              timezone={Moment.tz(props.timezone).format("z")}
              rangeType={rangeType}
              setRangeType={(rangeType: string) => {
                props.handleUpdateSearchParams({
                  granularity: rangeType,
                });
              }}
              updateUser={() => {}}
            />
          </div>
        </div>
        <div style={{ display: "flex", gap: 12 }}>
          <FilterUserGroups
            userGroups={props.userGroups}
            filteredUserGroups={filteredUserGroups}
            setFilteredUserGroups={setFilteredUserGroups}
          />
          <FilterDatasets
            datasets={data}
            filteredDatasets={[]}
            handleUpdateSearchParams={props.handleUpdateSearchParams}
          />
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: 8,
              fontWeight: 300,
              fontSize: 14,
            }}
          >
            <Check
              handleClick={() => setIsBreakdown(!isBreakdown)}
              checked={isBreakdown}
            />
            Breakdown datasets
          </div>

          <div className={styles["forecast-meta-data"]}>
            <ToolTip icon="i" />
            Interval: <strong>{interval}</strong>
          </div>
        </div>
      </div>
      <div
        className={styles["forecast-scroll-container"]}
        onScroll={(e) => {
          if (e.target.scrollTop > 0) {
            if (scrollHeaderRef.current) {
              scrollHeaderRef.current.style.borderBottom = "1px solid #E0E0E0";
            }
          } else {
            if (scrollHeaderRef.current) {
              scrollHeaderRef.current.style.borderBottom = "none";
            }
          }
        }}
      >
        <LineChart
          info={{
            name: "Volume",
            units: "units",
          }}
          datasets={datasets}
          linesHidden={linesHidden}
          toggleLine={toggleLine}
          loading={props.response.loading}
          granularity={granularity}
          format12h={props.format12h}
          range={{
            startDate: props.startDate,
            endDate: props.endDate,
            rangeType: rangeType,
          }}
          key={isBreakdown + "overview-volume"}
          isStacked={false}
        />
        {["day", "week"].includes(rangeType) && (
          <StaffingRequirementsOverview
            startDate={props.startDate}
            endDate={props.endDate}
            datasets={staffingDatasets}
            isBreakdown={isBreakdown}
            teamCount={teamCount}
            format12h={props.format12h}
            granularity={granularity}
            range={{
              startDate: props.startDate,
              endDate: props.endDate,
              rangeType: rangeType,
            }}
          />
        )}
      </div>
    </div>
  );
}

function FilterUserGroups(props: {
  userGroups: { id: string; name: string; description: string }[];
  filteredUserGroups: any[];
  setFilteredUserGroups: (filteredUserGroups: any[]) => void;
}) {
  let amount = props.filteredUserGroups.length;
  const noneSelected = props.filteredUserGroups.length === 0;

  if (noneSelected) {
    amount = 0;
  }

  const options = props.userGroups.map((group) => {
    return {
      id: group.id,
      name: group.name,
      action: () => {
        let userGroupsSelected = props.filteredUserGroups;
        const isSelected = props.filteredUserGroups.includes(group.id);
        if (isSelected) {
          userGroupsSelected = props.filteredUserGroups.filter(
            (id) => id !== group.id
          );
        } else {
          userGroupsSelected = [...props.filteredUserGroups, group.id];
        }
        props.setFilteredUserGroups(userGroupsSelected);
      },
      selected: noneSelected
        ? false
        : props.filteredUserGroups.includes(group.id),
    };
  });
  return (
    <Filter
      name="User Groups"
      menuName="Filter User Groups"
      icon="icon-chart_line_outline"
      clearAll={() => {
        props.setFilteredUserGroups([]);
      }}
      customDropdownStyle={{
        width: 228,
        left: "auto",
        right: 0,
      }}
      options={options}
      amount={amount}
      search={true}
      emptyStateType="user groups"
      emptyStateIcon="icon-chart_line_outline"
      userId={""}
    />
  );
}

function FilterDatasets(props: {
  datasets: any[];
  filteredDatasets: any[];
  handleUpdateSearchParams: (params: any) => void;
}) {
  const [filteredOutDatasets, setFilteredOutDatasets] = useState<string[]>(
    props.filteredDatasets
  );

  const datasets: { id: string; name: string }[] = [];
  for (const dataset of props.datasets) {
    if (dataset.integration === "sum") continue;
    datasets.push({
      id: dataset.id,
      name: dataset.name,
    });
  }

  let amount = filteredOutDatasets.length;
  const noneSelected = filteredOutDatasets.length === 0;
  const allSelected = filteredOutDatasets.length === datasets.length;
  if (noneSelected || allSelected) {
    amount = 0;
  }

  const options = datasets
    .map((dataset) => {
      return {
        id: dataset.id,
        name: dataset.name,
        action: () => {
          let datasetsSelected = filteredOutDatasets;
          const isSelected = filteredOutDatasets.includes(dataset.id);
          if (isSelected) {
            datasetsSelected = filteredOutDatasets.filter(
              (id) => id !== dataset.id
            );
          } else {
            datasetsSelected = [...filteredOutDatasets, dataset.id];
          }
          setFilteredOutDatasets(datasetsSelected);
        },
        selected: noneSelected
          ? false
          : filteredOutDatasets.includes(dataset.id),
      };
    })
    .sort((a, b) => a.name.localeCompare(b.name));

  return (
    <Filter
      name="Datasets"
      menuName="Filter Datasets"
      icon="icon-chart_line_outline"
      clearAll={() => {
        setFilteredOutDatasets([]);
      }}
      customDropdownStyle={{
        width: 228,
        left: "auto",
        right: 0,
      }}
      options={options}
      amount={amount}
      search={true}
      handleApply={() => {
        props.handleUpdateSearchParams({
          exclude:
            allSelected || noneSelected
              ? []
              : datasets
                  .map((d) => d.id)
                  .filter((id) => !filteredOutDatasets.includes(id)),
        });
      }}
      handleClearApply={() => {
        props.handleUpdateSearchParams({
          exclude: [],
        });
      }}
      emptyStateType="datasets"
      emptyStateIcon="icon-chart_line_outline"
      userId={""}
    />
  );
}

function StaffingRequirementsOverview(props: {
  startDate: string;
  endDate: string;
  isBreakdown: boolean;
  datasets: {
    staffing: { ds: string; agentsNeeded: number }[];
    name: string;
    color: string;
    backgroundColor: string;
  }[];
  teamCount: number;
  granularity: string;
  format12h: boolean;
  range: {
    startDate: string;
    endDate: string;
    rangeType: string;
  };
}) {
  const [linesHidden, setLinesHidden] = useState<string[]>([]);

  function toggleLine(lines: string[]) {
    let newLinesHidden = [...linesHidden];
    for (const line of lines) {
      if (linesHidden.includes(line)) {
        newLinesHidden = newLinesHidden.filter((l) => l !== line);
      } else {
        newLinesHidden = [...newLinesHidden, line];
      }
    }
    setLinesHidden(newLinesHidden);
  }

  const placeholderData = utils.getPlaceholderData(
    props.startDate,
    props.endDate,
    props.granularity
  );
  placeholderData.stack = "none";

  const teamSize = props.teamCount;

  const teamSizeData = [];

  teamSizeData.push({
    x: Moment(props.startDate).toDate(),
    y: teamSize,
  });
  teamSizeData.push({
    x: Moment(props.endDate).toDate(),
    y: teamSize,
  });

  const datasets = [placeholderData];

  for (const dataset of props.datasets) {
    const datasetId = dataset.name.replaceAll(" ", "").toLowerCase();
    datasets.push({
      id: datasetId,
      label: dataset.name === "sum" ? "Forecast" : dataset.name,
      data: dataset.staffing.map((item) => {
        return {
          x: Moment.utc(item.ds, "YYYY-MM-DD HH:mm:ss").toDate(),
          y: item.agentsNeeded,
        };
      }),
      borderColor: dataset.color,
      backgroundColor: dataset.backgroundColor,
      fill: props.isBreakdown,
      pointRadius: 0,
      stepped: true,
      borderWidth: 2,
      stack: "stack",
      hidden: linesHidden.includes(
        dataset.name === "sum" ? "forecast" : datasetId
      ),
    });
  }

  datasets.push({
    id: "teamsize",
    label: "Team Size",
    data: teamSizeData,
    borderColor: colors.red,
    borderWidth: 2,
    borderDash: [10, 5],
    pointRadius: 0,
    stack: "none",
    hidden: linesHidden.includes("teamsize"),
  });

  return (
    <div>
      <LineChart
        info={{
          name: "Staffing Requirements",
          units: "people",
        }}
        datasets={datasets}
        isStacked={true}
        linesHidden={linesHidden}
        toggleLine={toggleLine}
        loading={false}
        key={props.isBreakdown + "overview-staffing"}
        granularity={props.granularity}
        format12h={props.format12h}
        range={props.range}
      />
    </div>
  );
}

const GET_FORECASTS = gql`
  query getForecasts(
    $startDate: DateTime!
    $endDate: DateTime!
    $filter: JSON
  ) {
    getForecastOverview(
      startDate: $startDate
      endDate: $endDate
      filter: $filter
    )
  }
`;

const GET_USERS = gql`
  query getUsers($teamId: ID!) {
    Users(teamId: $teamId) {
      id
      name
      teamClasses {
        id
        userIs
      }
    }
  }
`;
