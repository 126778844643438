import React from "react";
import { css, select as $ } from "glamor";
import { colors } from "../../helpers/styles";

interface EmptyStateProps {
  title: string;
  message: string | JSX.Element;
  component?: JSX.Element;
  customStyle?: any;
  iconStyle?: any;
  size?: "small" | "large";
}

export default function EmptyState({
  title,
  message,
  icon,
  component,
  customStyle,
  iconStyle,
  size,
}: EmptyStateProps) {
  return (
    <div
      {...css(
        {
          float: "left",
          width: "100%",
          border: "1px solid #E2E2E2",
          borderTop: 0,
          borderBottomLeftRadius: 5,
          borderBottomRightRadius: 5,
          textAlign: "center",
          paddingTop: size === "small" ? 24 : 48,
          paddingBottom: size === "small" ? 36 : 48,
          boxSizing: "border-box",
          cursor: "default",
        },
        customStyle
      )}
    >
      <div
        {...css({
          display: "inline-block",
          width: size === "small" ? 40 : 60,
          height: size === "small" ? 40 : 60,
          lineHeight: size === "small" ? "40px" : "60px",
          borderRadius: size === "small" ? 40 : 60,
          background: "#F9F9F9",
          fontSize: size === "small" ? 16 : 25,
          color: colors.blue,
          boxSizing: "border-box",
        })}
      >
        {component ? (
          <div style={{ paddingTop: 5 }}>{component}</div>
        ) : (
          <span className={icon} {...css(iconStyle)} />
        )}
      </div>
      <div
        {...css({
          float: "left",
          width: "100%",
          fontSize: size === "small" ? 14 : 20,
          lineHeight: size === "small" ? "17px" : "23px",
          marginTop: 12,
          fontWeight: 500,
          color: "rgba(0,0,0,0.87)",
        })}
      >
        {title}
      </div>
      <div
        {...css(
          {
            float: "left",
            width: "100%",
            fontSize: size === "small" ? 14 : 16,
            lineHeight: size === "small" ? "17px" : "19px",
            marginTop: size === "small" ? 12 : 24,
            fontWeight: 300,
            color: "rgba(0,0,0,0.87)",
          },
          $(" a", {
            color: "rgba(0,0,0,0.87)",
          }),
          $(" a:hover", {
            textDecoration: "none",
          })
        )}
      >
        {message}
      </div>
    </div>
  );
}
