import Moment from "moment";
import { useState } from "react";

import { ModalContainer, ModalHeader } from "../toolBox/MenuModal";
import Check from "../toolBox/Check";
import DatePicker from "../toolBox/DatePicker";
import InputField from "../toolBox/InputField";
import Button from "../toolBox/Button";

import styles from "./Forecast.module.css";
import toast from "react-hot-toast";

function getInterval(granularity: string) {
  if (granularity === "15T") {
    return "15-min";
  } else if (granularity === "H") {
    return "1-hr";
  } else if (granularity === "D") {
    return "1-day";
  }
}

export default function ExportToCSV(props: {
  handleClose: () => void;
  datasetId: string;
  datasetName: string;
  timezone: string;
  granularity: string;
}) {
  const [loading, setLoading] = useState(false);
  const [granularity, setGranularity] = useState(
    props.granularity === "day"
      ? "15T"
      : props.granularity === "week"
        ? "H"
        : "D"
  );
  const [timezone, setTimezone] = useState(props.timezone);
  const [dateRange, setDateRange] = useState("custom");
  const [startDate, setStartDate] = useState(
    Moment().startOf("month").toISOString()
  );
  const [endDate, setEndDate] = useState(Moment().endOf("month").toISOString());

  async function handleDownload() {
    setLoading(true);
    const datasetId = props.datasetId;
    const unixStartDate = Moment(startDate).unix();
    const unixEndDate = Moment(endDate).unix();

    const params = new URLSearchParams();
    params.append("dataset_id", datasetId);
    params.append("dataset_name", props.datasetName);
    params.append("granularity", granularity);
    if (dateRange === "custom") {
      if (unixStartDate) {
        params.append("start_date", unixStartDate.toString());
      }
      if (unixEndDate) {
        params.append("end_date", unixEndDate.toString());
      }
    }
    if (timezone) {
      params.append("time_zone", timezone);
    }

    const response = await fetch(
      `https://api.soon.works/api/v1/forecast/download?${params.toString()}`,
      {
        method: "GET",
      }
    );
    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;

    const interval = getInterval(granularity);
    const timezoneAbbreviation =
      timezone === "UTC" ? "UTC" : Moment.tz(timezone).format("z");
    let filename = `${props.datasetName}_${interval}_${timezoneAbbreviation}`;
    if (dateRange === "custom") {
      if (startDate) {
        filename += `_${Moment(startDate).format("DD-MM-YYYY")}`;
      }
      if (endDate) {
        filename += `_${Moment(endDate).format("DD-MM-YYYY")}`;
      }
    }

    filename += ".csv";
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    setLoading(false);
    toast.success("Data exported successfully");
    props.handleClose();
  }

  const interval = getInterval(granularity);

  return (
    <>
      <ModalContainer>
        <ModalHeader>
          <div>Export to CSV</div>
        </ModalHeader>
        <div className={styles["modal-content"]}>
          <div className={styles["model-item"]}>
            <div>Forecast interval</div>
            <div>
              Select the forecast interval you want to export the data for.
            </div>
          </div>
          <InputField
            label="Interval"
            value={interval}
            options={[
              {
                id: 1,
                name: "15-min",
                selected: interval === "15-min",
                action: () => {
                  setGranularity("15T");
                },
              },
              {
                id: 2,
                name: "1-hr",
                selected: interval === "1-hr",
                action: () => {
                  setGranularity("H");
                },
              },
              {
                id: 3,
                name: "1-day",
                selected: interval === "1-day",
                action: () => {
                  setGranularity("D");
                },
              },
            ]}
          />

          <div className={styles["model-item"]}>
            <div>Date range</div>
            <div>Select the range for which you want to export the data.</div>
          </div>
          <div className={styles["model-item-checkbox"]}>
            <div onClick={() => setDateRange("custom")}>
              <Check
                checked={dateRange === "custom"}
                handleClick={() => {
                  setDateRange("custom");
                }}
              />
              <div>Custom</div>
            </div>
            <div onClick={() => setDateRange("all")}>
              <Check
                checked={dateRange === "all"}
                handleClick={() => {
                  setDateRange("all");
                }}
              />
              <div>All</div>
            </div>
          </div>
          {dateRange === "custom" && (
            <DatePicker
              allDay={true}
              startDate={startDate}
              endDate={endDate}
              setDate={(date: string, type: string) => {
                if (type === "startDate") {
                  setStartDate(Moment(date).startOf("day").toISOString());
                } else {
                  setEndDate(Moment(date).startOf("day").toISOString());
                }
              }}
              timezone={timezone}
              customStyle={{
                marginBottom: 0,
              }}
            />
          )}
          <div className={styles["model-item"]}>
            <div>Time zone</div>
            <div>
              Select the time zone in which you want to export the data.
            </div>
          </div>
          <div className={styles["model-item-checkbox"]}>
            <div onClick={() => setTimezone("UTC")}>
              <Check
                checked={timezone === "UTC"}
                handleClick={() => {
                  setTimezone("UTC");
                }}
              />
              <div>UTC</div>
            </div>
            <div onClick={() => setTimezone(props.timezone)}>
              <Check
                checked={timezone === props.timezone}
                handleClick={() => {
                  setTimezone(props.timezone);
                }}
              />
              <div>
                Display Time Zone ({Moment.tz(props.timezone).format("z")})
              </div>
            </div>
          </div>
        </div>

        <div className={styles["modal-footer"]}>
          <Button
            theme="grey-border"
            size="large"
            action={props.handleClose}
            name="Cancel"
          />
          <Button
            theme="blue-border"
            size="large"
            action={handleDownload}
            name="Download"
            loading={loading}
          />
        </div>
      </ModalContainer>
      <div className="overlay" onClick={props.handleClose} />
    </>
  );
}
