export const REMOVABLE_STATUSES = [
  "CANCELLED",
  "DECLINED_CONFIRMED",
  "COVER_DECLINED",
  "DECLINED",
  "COVERED",
];

export const APP_VERSION = "V6.5.12";

export const LAUNCH_DATES = {
  autoScheduling: "2024-02-08",
  templateGenerator: "2024-09-18",
};

export const FORECASTS_WHITELISTED_TEAMS = [
  "clezjrxcz467501m5dxz6oar9",
  "cjd4q8p37ed5c01712fkpu0bx",
  "cl4qu9pxd31536201nuf84p5qkx", // stadgenoot
  "cjg3eb2zvtb630995d0cu2o8a", // knab
];
