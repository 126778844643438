import React from "react";
import { css, select as $ } from "glamor";
import matchSorter from "match-sorter";
import { colors } from "../../helpers/styles";
import switchActive from "../../assets/images/switch-active.png";
import switchInactive from "../../assets/images/switch-inactive.png";
import EmptyState from "./EmptyState";
import Button from "./Button";
import OutsideAlerter from "../clickOutside/OutsideAlerter";

const List = ({
  menuName,
  icon,
  options,
  clearAll,
  amount,
  search,
  input,
  handleChange,
  handleApply,
  handleFocus,
  emptyStateType,
  emptyStateIcon,
  customDropdownStyle,
}) => {
  const list = options.map((o) => {
    return (
      <li
        key={o.id}
        {...css(
          {
            float: "left",
            width: "100%",
            boxSizing: "border-box",
            padding: "3px 6px",
            paddingLeft: 1,
            lineHeight: "20px",
            fontSize: 14,
            fontWeight: 300,
            color: o.selected ? colors.blue : "rgba(0, 0, 0, 0.87)",
          },
          $(":hover", {
            background: "rgba(0, 0, 0, 0.05)",
            borderRadius: 3,
            cursor: "pointer",
            fontWeight: 500,
          })
        )}
        onClick={o.action}
      >
        <div
          {...css({
            float: "left",
            width: 27,
            textAlign: "right",
            boxSizing: "border-box",
            marginRight: 3,
            fontSize: 16,
          })}
        >
          {o.selected ? (
            <span
              className="icon-tick_checked"
              {...css({ color: colors.blue })}
            />
          ) : (
            <span
              className="icon-tick_unchecked"
              {...css({ color: "rgba(0,0,0,0.38)" })}
            />
          )}
        </div>
        <div
          {...css({
            float: "left",
            width: "calc(100% - 32px)",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          })}
        >
          {o.name}
        </div>
      </li>
    );
  });
  return (
    <OutsideAlerter action={handleFocus}>
      <div
        {...css({
          position: "absolute",
          width: search ? 352 : "204px",
          background: "white",
          borderRadius: "5px",
          zIndex: 1,
          color: "rgba(0,0,0,0.87)",
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
          top: 50,
          left: 0,
        })}
        style={customDropdownStyle}
        className="noselect"
      >
        {menuName && (
          <div
            {...css({
              float: "left",
              width: "100%",
              lineHeight: "20px",
              fontWeight: 500,
              padding: 12,
              boxSizing: "border-box",
              borderBottom: "1px solid #E2E2E2",
              height: search ? 44 : "auto",
            })}
          >
            {icon && <span {...css({ marginRight: "5px" })} className={icon} />}
            <span {...css({ fontSize: "14px" })}>{menuName}</span>
            <img
              {...css({ float: "right", cursor: "pointer" })}
              onClick={clearAll}
              src={amount > 0 ? switchActive : switchInactive}
              width={search ? 32 : "35"}
              height={search ? 20 : "23"}
              alt="Switch"
            />
          </div>
        )}
        {search && (
          <div
            {...css({
              float: "left",
              width: "100%",
              height: 32,
              padding: "6px 12px",
              boxSizing: "border-box",
              borderBottom: "1px solid #E2E2E2",
            })}
          >
            <span
              {...css({
                float: "left",
                fontSize: 16.66,
                marginRight: 3,
                color: "rgba(0,0,0,0.38)",
              })}
              className="bi_interface-search"
            />{" "}
            <input
              autoComplete="off"
              {...css(
                {
                  float: "left",
                  width: "calc(100% - 32px)",
                  fontWeight: 300,
                  border: 0,
                  fontFamily: "Museo Sans",
                  fontSize: 14,
                  height: 20,
                  lineHeight: "20px",
                },
                $(":focus", {
                  outline: 0,
                })
              )}
              placeholder="Search"
              value={input}
              onChange={handleChange}
            />
          </div>
        )}
        {list.length > 0 ? (
          <ul
            {...css(
              {
                float: "left",
                width: "100%",
                maxHeight: search ? 220 : "auto",
                listStyle: "none",
                padding: "3px 6px 6px 6px",
                boxSizing: "border-box",
                margin: 0,
                overflowY: "scroll",
              },
              $("::-webkit-scrollbar", {
                display: "none",
              })
            )}
          >
            {list}
          </ul>
        ) : (
          <EmptyState
            title={input ? "No results" : `No ${emptyStateType}`}
            icon={input ? "bi_interface-search" : emptyStateIcon}
            message={
              input
                ? `No ${emptyStateType} found matching your search.`
                : `There are no ${emptyStateType} available to you`
            }
            size="small"
            customStyle={{ border: 0 }}
          />
        )}
        {search && handleApply && (
          <div
            {...css({
              float: "left",
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              height: 56,
              padding: 12,
              boxSizing: "border-box",
              borderTop: "1px solid #E2E2E2",
              lineHeight: "32px",
            })}
          >
            <Button
              size="medium"
              name="Cancel"
              theme="grey-border"
              action={handleFocus}
            />
            <Button
              size="medium"
              name="Apply"
              theme="blue-border"
              action={() => {
                handleApply(() => handleFocus());
              }}
            />
          </div>
        )}
      </div>
    </OutsideAlerter>
  );
};

class Filter extends React.Component {
  state = {
    open: false,
    input: "",
  };
  render() {
    const {
      name,
      menuName,
      margin,
      icon,
      clearAll,
      amount,
      search,
      handleApply,
      handleClearApply,
      customStyle,
      emptyStateType,
      emptyStateIcon,
      customDropdownStyle,
    } = this.props;
    const { open } = this.state;
    const options = matchSorter(this.props.options, this.state.input, {
      keys: ["name"],
    });

    return (
      <div
        {...css({ float: "left", margin, position: "relative" }, customStyle)}
      >
        <div
          {...css({
            float: "left",
            borderRadius: 23,
            border: "1px solid #E2E2E2",
            lineHeight: "44px",
            height: 44,
            fontSize: 14,
            fontWeight: 300,
            paddingLeft: 16,
            paddingRight: 16,
          })}
        >
          <span
            {...css(
              { cursor: "pointer" },
              $(":hover > span", {
                color: amount < 1 ? "rgba(0,0,0,0.87) !important" : "",
              })
            )}
            onClick={() => {
              this.setState({ open: true });
            }}
          >
            <span
              {...css(
                { textDecoration: open ? "underline" : "none" },
                $(":hover", {
                  textDecoration: "underline",
                })
              )}
            >
              {name}
            </span>{" "}
            {amount > 0 && (
              <span
                {...css({
                  background: colors.blue,
                  color: "white",
                  borderRadius: 20,
                  fontSize: "12px",
                  display: "inline-block",
                  width: 20,
                  lineHeight: "20px",
                  textAlign: "center",
                  fontWeight: 500,
                })}
              >
                {amount}
              </span>
            )}{" "}
            {amount < 1 && (
              <span
                className="bi_interface-bottom"
                {...css({
                  color: "rgba(0,0,0,0.54)",
                })}
              />
            )}
          </span>
          {amount > 0 && (
            <span
              className="bi_interface-cross"
              onClick={() => {
                clearAll();
                if (handleClearApply) {
                  handleClearApply();
                }
              }}
              {...css(
                {
                  color: "rgba(0,0,0,0.54)",
                  cursor: "pointer",
                },
                $(":hover", {
                  color: "rgba(0,0,0,0.87)",
                })
              )}
            />
          )}
        </div>
        {open && (
          <List
            handleFocus={() => this.setState({ open: false })}
            name={name}
            menuName={menuName ? menuName : name}
            icon={icon}
            options={options}
            clearAll={clearAll}
            amount={amount}
            search={search}
            input={this.state.input}
            handleChange={(e) => this.setState({ input: e.target.value })}
            handleApply={handleApply}
            emptyStateType={emptyStateType}
            emptyStateIcon={emptyStateIcon}
            customDropdownStyle={customDropdownStyle}
          />
        )}
      </div>
    );
  }
}

export default Filter;
