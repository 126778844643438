import styles from "./Forecast.module.css";
import Moment from "moment-timezone";
import DateRangePicker from "../toolBox/DateRangePicker";
import { useState } from "react";
import OptionsMenuSubtitle from "../toolBox/OptionsMenuSubtitle";
import { capitalizeString } from "../../helpers/functions";

export default function DateRangeSwitcher(props: {
  startDate: string;
  endDate: string;
  granularity: string;
  handleUpdateSearchParams: (params: any) => void;
  isSmallScreen?: boolean;
}) {
  const [showDateRangePicker, setShowDateRangePicker] = useState(false);

  const [startDate, setStartDate] = useState(props.startDate);
  const [endDate, setEndDate] = useState(props.endDate);
  const [rangeType, setRangeType] = useState("custom");

  function setDate(date: string) {
    setStartDate(date);
    setEndDate(date);
  }

  function handleSetGranularity(granularity: string) {
    if (granularity === "day") {
      const newStartDate = Moment(props.startDate).startOf("day");
      const newEndDate = Moment(newStartDate).endOf("day");

      props.handleUpdateSearchParams({
        startDate: newStartDate.unix().toString(),
        endDate: newEndDate.unix().toString(),
        granularity: "day",
      });
    }
    if (granularity === "week") {
      const newStartDate = Moment(props.startDate).startOf("isoWeek");
      const newEndDate = Moment(newStartDate).endOf("isoWeek");

      props.handleUpdateSearchParams({
        startDate: newStartDate.unix().toString(),
        endDate: newEndDate.unix().toString(),
        granularity: "week",
      });
    }
    if (granularity === "month") {
      const newStartDate = Moment(props.startDate).startOf("month");
      const newEndDate = Moment(newStartDate).endOf("month");

      props.handleUpdateSearchParams({
        startDate: newStartDate.unix().toString(),
        endDate: newEndDate.unix().toString(),
        granularity: "month",
      });
    }
    if (granularity === "quarter") {
      const newStartDate = Moment(props.startDate).startOf("quarter");
      const newEndDate = Moment(newStartDate).endOf("quarter");

      props.handleUpdateSearchParams({
        startDate: newStartDate.unix().toString(),
        endDate: newEndDate.unix().toString(),
        granularity: "quarter",
      });
    }
    if (granularity === "year") {
      const newStartDate = Moment(props.startDate).startOf("year");
      const newEndDate = Moment(newStartDate).endOf("year");

      props.handleUpdateSearchParams({
        startDate: newStartDate.unix().toString(),
        endDate: newEndDate.unix().toString(),
        granularity: "year",
      });
    }
    if (granularity === "custom") {
      setShowDateRangePicker(true);
    }
  }

  if (props.isSmallScreen) {
    return (
      <div style={{ position: "relative" }}>
        <DateRangeSwitcherSmallScreen
          currentValue={props.granularity}
          setCurrentValue={(granularity: string) => {
            handleSetGranularity(granularity);
          }}
        />
        {showDateRangePicker && (
          <DateRangePicker
            startDate={startDate}
            endDate={endDate}
            rangeType={rangeType}
            setDate={setDate}
            setRangeType={setRangeType}
            customStyle={{
              position: "absolute",
              zIndex: 1,
              top: -2,
              left: 0,
            }}
            handleFocus={() => setShowDateRangePicker(false)}
            exclude="date-range-list"
            applyFunction={(
              startDate: string,
              endDate: string,
              rangeType: string
            ) => {
              let granularity = rangeType;
              // duration in hours
              const duration = Moment(endDate).diff(Moment(startDate), "hours");
              if (duration <= 24) {
                granularity = "day";
              }
              props.handleUpdateSearchParams({
                startDate: Moment(startDate).unix().toString(),
                endDate: Moment(endDate).unix().toString(),
                granularity: granularity,
              });
              setShowDateRangePicker(false);
            }}
          />
        )}
      </div>
    );
  }

  return (
    <div className={styles["forecast-date-range-switcher"]}>
      <button
        onClick={() => {
          handleSetGranularity("day");
        }}
        disabled={props.granularity === "day"}
      >
        Day
      </button>
      <div className={styles["forecast-date-range-switcher-separator"]}>|</div>
      <button
        onClick={() => {
          handleSetGranularity("week");
        }}
        disabled={props.granularity === "week"}
      >
        Week
      </button>
      <div className={styles["forecast-date-range-switcher-separator"]}>|</div>
      <button
        onClick={() => {
          handleSetGranularity("month");
        }}
        disabled={props.granularity === "month"}
      >
        Month
      </button>
      <div className={styles["forecast-date-range-switcher-separator"]}>|</div>
      <button
        onClick={() => {
          handleSetGranularity("quarter");
        }}
        disabled={props.granularity === "quarter"}
      >
        Quarter
      </button>
      <div className={styles["forecast-date-range-switcher-separator"]}>|</div>
      <button
        onClick={() => {
          handleSetGranularity("year");
        }}
        disabled={props.granularity === "year"}
      >
        Year
      </button>
      <div className={styles["forecast-date-range-switcher-separator"]}>|</div>
      <button
        onClick={() => {
          handleSetGranularity("custom");
        }}
        disabled={props.granularity === "custom"}
      >
        Custom
      </button>
      {showDateRangePicker && (
        <DateRangePicker
          startDate={startDate}
          endDate={endDate}
          rangeType={rangeType}
          setDate={setDate}
          setRangeType={setRangeType}
          customStyle={{
            position: "absolute",
            zIndex: 1,
            top: -2,
            left: 280,
          }}
          handleFocus={() => setShowDateRangePicker(false)}
          exclude="date-range-list"
          applyFunction={(
            startDate: string,
            endDate: string,
            rangeType: string
          ) => {
            let granularity = rangeType;
            // duration in hours
            const duration = Moment(endDate).diff(Moment(startDate), "hours");
            if (duration <= 24) {
              granularity = "day";
            }
            props.handleUpdateSearchParams({
              startDate: Moment(startDate).unix().toString(),
              endDate: Moment(endDate).unix().toString(),
              granularity: granularity,
            });
            setShowDateRangePicker(false);
          }}
        />
      )}
    </div>
  );
}

function DateRangeSwitcherSmallScreen(props: {
  currentValue: string;
  setCurrentValue: (value: string) => void;
}) {
  const [menu, setMenu] = useState(false);
  const items = [
    {
      id: "day",
      title: "Day",
      selected: props.currentValue === "day",
      action: () => {
        setMenu(false);
        props.setCurrentValue("day");
      },
    },
    {
      id: "week",
      title: "Week",
      selected: props.currentValue === "week",
      action: () => {
        setMenu(false);
        props.setCurrentValue("week");
      },
    },
    {
      id: "month",
      title: "Month",
      selected: props.currentValue === "month",
      action: () => {
        setMenu(false);
        props.setCurrentValue("month");
      },
    },
    {
      id: "quarter",
      title: "Quarter",
      selected: props.currentValue === "quarter",
      action: () => {
        setMenu(false);
        props.setCurrentValue("quarter");
      },
    },
    {
      id: "year",
      title: "Year",
      selected: props.currentValue === "year",
      action: () => {
        setMenu(false);
        props.setCurrentValue("year");
      },
    },
    {
      id: "custom",
      title: "Custom",
      selected: props.currentValue === "custom",
      action: () => {
        props.setCurrentValue("custom");
      },
    },
  ];
  return (
    <div style={{ position: "relative" }}>
      <div
        className={styles["forecast-compare-actuals"]}
        onClick={(e) => {
          const className = e.target.className;
          if (className.includes("bi_interface-cross")) {
            setMenu(false);
          } else {
            setMenu(true);
          }
        }}
      >
        {capitalizeString(props.currentValue)}
        <span className="bi_interface-bottom" />
      </div>
      {menu && (
        <OptionsMenuSubtitle
          name="Zoom level"
          items={items}
          loading={false}
          handleFocus={() => {
            setMenu(false);
          }}
          disableActionAutoClose={true}
          customStyle={{ width: 228, top: 48, left: 0 }}
        />
      )}
    </div>
  );
}
