import { useContext } from "react";
import { Route, Switch } from "react-router-dom";
import { gql, useQuery } from "@apollo/client";

import Datasets from "./Datasets";
import Overview from "./Overview";

import Header from "../header/Header";
import TabMenu from "../toolBox/TabMenu";
import SupportCircle from "../toolBox/SupportCircle";
import { UserContext } from "../App";

export default function Forecasts(props: {
  match: any;
  history: any;
  billingPlan: any;
}) {
  const currentUser = useContext(UserContext);

  const { data, loading, error } = useQuery(GET_DATASETS);

  if (error) return <p>Error: {error.message}</p>;

  const datasets = data?.getDatasets || [];
  const format12h = currentUser.timeFormat === "12-hour";

  const location = window.location.pathname;
  const isOverview = location.includes("overview");

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <Header
        headerTitle="Forecasts"
        userId={currentUser.id}
        match={props.match}
        history={props.history}
        billingPlan={props.billingPlan}
      />
      <TabMenu
        customStyle={{ paddingLeft: 24 }}
        items={[
          {
            name: "Overview",
            link: `/forecasts/overview`,
          },
          {
            name: "Datasets",
            link: `/forecasts/datasets`,
          },
        ]}
      >
        <div style={{ float: "right", marginRight: 12, marginTop: 7 }}>
          <SupportCircle
            screen={isOverview ? "FORECASTS_OVERVIEW" : "FORECASTS_DATASET"}
          />
        </div>
      </TabMenu>
      {!loading && (
        <Switch>
          <Route
            path="/forecasts/datasets"
            render={(props) => (
              <Datasets
                {...props}
                datasets={datasets}
                format12h={format12h}
                displayTimezone={currentUser.timezone}
              />
            )}
          />
          <Route
            path="/forecasts/overview"
            render={(props) => (
              <Overview
                {...props}
                format12h={format12h}
                displayTimezone={currentUser.timezone}
              />
            )}
          />
        </Switch>
      )}
    </div>
  );
}

// get datasets
const GET_DATASETS = gql`
  query getDatasets {
    getDatasets {
      id
      name
      status
      source
      uploadInterval
      datasetTimezone
      forecastTimezone
      lastDataPoint
      lastForecastRun
      forecastConfiguration
      staffingParameters
    }
  }
`;
