import { Line } from "react-chartjs-2";
import Moment from "moment";
import styles from "./Forecast.module.css";
import Spinner from "../toolBox/Spinner";
import EmptyState from "../toolBox/EmptyState";

import { crossHair, tooltip } from "../toolBox/chart/Plugins";
import { cloneDeep } from "lodash";

const base = {
  responsive: true,
  maintainAspectRatio: false,
  interaction: {
    intersect: false,
    mode: "x",
  },
  plugins: {
    legend: {
      display: false,
    },
    tooltip: tooltip,
    tooltipData: {
      noVolumeStaffing: true,
      format12h: false,
    },
    customXAxisLabels: {
      range: "day",
      format12h: false,
    },
  },
  layout: {
    padding: {
      left: 48,
      right: 30,
      bottom: 30,
      top: 6,
    },
  },
  onHover: crossHair,
  scales: {
    x: {
      type: "time",
      time: {
        unit: "hour",
        displayFormats: {
          hour: "HH:mm",
          day: "MMM D",
        },
        stepSize: 1,
        isoWeekday: true,
      },
      position: "bottom",
      grid: {
        display: false,
      },
      border: {
        display: false,
      },
      ticks: {
        display: false,
      },
    },
    y: {
      // stacked: false,
      beginAtZero: true,
      grid: {
        display: false,
        color: "#eee",
      },
      border: {
        display: false,
      },
      ticks: {
        display: false,
      },
    },
  },
};

export default function LineChart(props: {
  info: {
    name: string;
    units: string;
  };
  datasets: {
    id: string;
    label: string;
    data: { x: Date; y: number }[];
    borderColor: string;
  }[];
  comparisonStartDate?: string;
  comparisonEndDate?: string;
  loading: boolean;
  isStacked: boolean;
  toggleLine: (line: string[]) => void;
  linesHidden: string[];
  emptyState: {
    icon?: string;
    component?: JSX.Element;
    title: string;
    description: string;
  } | null;
  granularity: string;
  format12h: boolean;
  range: {
    startDate: string;
    endDate: string;
    rangeType: string;
  };
}) {
  const data = {
    datasets: props.datasets,
  };
  const linesHidden = props.linesHidden || [];

  const format12h = props.format12h;
  const chartOptions = cloneDeep(base);
  if (props.isStacked && props.isStacked === true) {
    chartOptions.scales.y.stacked = true;
  } else {
    chartOptions.scales.y.stacked = false;
  }

  let granularity = props.granularity;
  let timeOptions = {
    unit: "hour",
    range: "day",
    stepSize: 1,
  };

  if (props.granularity === "week") {
    timeOptions.unit = "day";
    timeOptions.range = "isoweek";
  } else if (props.granularity === "month") {
    timeOptions.unit = "day";
    timeOptions.range = "month";
  } else if (props.granularity === "quarter") {
    timeOptions.unit = "week";
    timeOptions.range = "quarter";
  } else if (props.granularity === "year") {
    timeOptions.unit = "month";
    timeOptions.range = "year";
  } else {
    // get amount of hours between start and end date
    const hours = Moment(props.range.endDate).diff(
      Moment(props.range.startDate),
      "hours"
    );

    // if it's more than 24 hours, but less than 2 weeks, set unit to week
    if (hours > 23) {
      if (hours < 14 * 24) {
        if (hours < 48) {
          timeOptions.unit = "day";
          timeOptions.range = "week";
          granularity = "week";
        } else {
          timeOptions.unit = "day";
          timeOptions.range = "week";
          granularity = "week";
        }
      }
      if (hours >= 14 * 24 && hours < 32 * 24) {
        timeOptions.unit = "day";
        timeOptions.range = "month";
        granularity = "month";
      }
      if (hours >= 14 * 24 && hours < 60 * 24) {
        timeOptions.unit = "week";
        timeOptions.range = "month";
        granularity = "month";
      }
      if (hours >= 60 * 24 && hours < 180 * 24) {
        timeOptions.unit = "week";
        timeOptions.range = "year";
        granularity = "quarter";
      }
      if (hours >= 180 * 24) {
        timeOptions.unit = "month";
        timeOptions.range = "year";
        granularity = "year";
      }
    }
  }

  chartOptions.scales.x.time.unit = timeOptions.unit;
  chartOptions.scales.x.time.stepSize = timeOptions.stepSize;
  chartOptions.plugins.tooltipData.format12h = format12h;
  chartOptions.plugins.tooltipData.granularity = granularity;
  chartOptions.scales.x.time.displayFormats.hour = format12h
    ? "h:mm a"
    : "HH:mm";
  chartOptions.plugins.customXAxisLabels.range = granularity;
  chartOptions.plugins.customXAxisLabels.format12h = format12h;
  chartOptions.layout.padding.bottom = ["day", "year"].includes(granularity)
    ? 16
    : 30;

  return (
    <div
      style={{
        border: "1px solid #F3F3F3",
        borderRadius: 5,
        padding: "24px 0px",
        position: "relative",
        paddingTop: 48,
      }}
    >
      <div
        style={{
          position: "absolute",
          top: 24,
          left: 24,
          display: "flex",
          flexDirection: "column",
          gap: 6,
        }}
      >
        <div
          style={{
            height: 20,
            fontSize: 16,
            fontWeight: 500,
            lineHeight: "20px",
            display: "flex",
            alignItems: "center",
          }}
        >
          {props.info.name}
          {props.loading ? (
            <Spinner
              spinnerStyle={{
                left: 60,
                top: 0,
                transform: "none",
              }}
              spinnerSize={20}
            />
          ) : null}
        </div>
        <div
          style={{
            height: 17,
            fontSize: 14,
            fontWeight: 300,
            lineHeight: "17px",
          }}
        >
          # of {props.info.units}
        </div>
      </div>
      {!props.emptyState && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            gap: 24,
            height: 23,
            marginBottom: 10,
          }}
        >
          {data.datasets
            .filter((dataset) => {
              if (dataset.data.length === 0) return false;
              if (dataset.id === "previousActual") {
                const comparisonIsActive =
                  props.comparisonStartDate && props.comparisonEndDate;
                if (!comparisonIsActive) {
                  return false;
                }
              }
              return !["placeholder", "forecastLower"].includes(dataset.id);
            })
            .map((dataset, index) => {
              let isUpperBound = false;
              if (dataset.id === "forecastUpper") {
                isUpperBound = true;
              }

              let name = dataset.label;
              if (isUpperBound) {
                name = "Forecast Upper & Lower Bound";
              }
              return (
                <div
                  key={index}
                  onClick={() => {
                    if (isUpperBound) {
                      props.toggleLine(["forecastUpper", "forecastLower"]);
                    } else {
                      props.toggleLine([dataset.id]);
                    }
                  }} // Toggle visibility
                  style={{
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    opacity: linesHidden.includes(dataset.id) ? 0.5 : 1, // Change opacity when hidden
                  }}
                >
                  {/* Color box for legend */}
                  <div
                    style={{
                      width: 8,
                      height: 8,
                      borderRadius: "100%",
                      backgroundColor: dataset.borderDash
                        ? dataset.borderColor + "10"
                        : dataset.borderColor,
                      marginRight: 12,
                      border: dataset.borderDash
                        ? `1px dashed ${dataset.borderColor}`
                        : "none",
                      boxSizing: "border-box",
                    }}
                  ></div>
                  {/* Legend text */}
                  <span style={{ fontSize: 14, fontWeight: 300 }}>{name}</span>
                </div>
              );
            })}
        </div>
      )}
      <div style={{ height: 350, width: "100%", position: "relative" }}>
        {props.emptyState ? (
          <EmptyState
            icon={props.emptyState.icon}
            component={props.emptyState.component}
            title={props.emptyState.title}
            message={props.emptyState.description}
            customStyle={{
              border: 0,
              marginTop: 68,
            }}
          />
        ) : (
          <Line
            data={data}
            options={chartOptions}
            key={props.granularity + props.info.name}
          />
        )}
        {props.loading && (
          <div
            className={styles["forecast-chart-loading-overlay"]}
            style={{
              height: ["day", "year"].includes(props.granularity)
                ? "calc(100% - 30px)"
                : "calc(100% - 45px)",
            }}
          />
        )}
      </div>
    </div>
  );
}
