import loader from "../../assets/images/loader.svg";

const Loader = (props: {
  background?: boolean;
  backgroundStyle?: React.CSSProperties;
  spinnerStyle?: React.CSSProperties;
  spinnerSize?: number;
}) => {
  let size = props.spinnerSize || 25;
  return (
    <div>
      <div
        style={{
          position: "absolute",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
          zIndex: "2",
          lineHeight: "initial",
          ...props.spinnerStyle,
        }}
      >
        <img width={size} height={size} src={loader} alt="Loading Soon" />
      </div>
      {props.background && (
        <div
          style={{
            position: "fixed",
            width: "100%",
            height: "100%",
            background: "white",
            top: "60px",
            opacity: "0.6",
            zIndex: 1,
            ...props.backgroundStyle,
          }}
          onClick={() => {}}
        />
      )}
    </div>
  );
};

export default Loader;
