import styles from "./Forecast.module.css";
import { colors } from "../../helpers/styles";

export default function StatusLabel({
  status,
  customStyle,
}: {
  status: string;
  customStyle?: React.CSSProperties;
}) {
  return (
    <div
      className={styles["forecast-header-status"]}
      style={{
        backgroundColor:
          status === "active" ? colors.light_green : colors.light_red,
        color: status === "active" ? colors.dark_green : colors.dark_red,
        ...customStyle,
      }}
    >
      <span
        style={{
          width: 8,
          height: 8,
          borderRadius: "50%",
          backgroundColor: "#f9f9f9",
          background: status === "active" ? colors.green : colors.red,
          display: "inline-block",
          marginRight: 6,
          marginTop: 2,
        }}
      />
      {status || "Inactive"}
    </div>
  );
}
