import React from "react";
import Moment from "moment-timezone";
import { css, select as $, media } from "glamor";

import Calendar from "../calendar/Calendar2";
import InputField from "./InputField";

import { colors } from "../../helpers/styles";

import { getDuration } from "../leave/functions";
import OutsideAlerter from "../clickOutside/OutsideAlerter";

const DayPickerOutside = ({
  selectDay,
  initialDate,
  calendarStyle,
  handleFocus,
}) => {
  return (
    <OutsideAlerter action={handleFocus}>
      <div
        {...css(
          {
            position: "absolute",
            width: 342,
            height: 302,
            background: "white",
            textAlign: "center",
            zIndex: 1,
            borderRadius: 5,
            boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.25)",
            padding: 8,
            boxSizing: "border-box",
            top: 54,
          },
          calendarStyle
        )}
      >
        <Calendar
          initialDate={new Date(initialDate)}
          specificDaysSelected={[new Date(initialDate)]}
          setSpecificDay={(date) => {
            selectDay(date);
          }}
        />
      </div>
    </OutsideAlerter>
  );
};

const CalenderPicker = DayPickerOutside;

class DayPicker extends React.Component {
  state = {
    picker: false,
  };
  render() {
    const {
      customStyle,
      label,
      value,
      setDate,
      error,
      disabled,
      calendarStyle,
    } = this.props;
    return (
      <div {...css(customStyle, { position: "relative" })}>
        <InputField
          label={label}
          value={value}
          noTyping={true}
          handleClick={() => this.setState({ picker: true })}
          error={error}
          disabled={disabled}
          externalFocus={this.state.picker}
          cursor="pointer"
        />
        {this.state.picker && (
          <CalenderPicker
            handleFocus={() => this.setState({ picker: false })}
            selectDay={(date, type) => {
              setDate(date, type);
              this.setState({ picker: false });
            }}
            initialDate={value}
            calendarStyle={calendarStyle}
          />
        )}
      </div>
    );
  }
}

class DatePicker extends React.Component {
  state = {
    valid: true,
    metaText: "",
  };

  componentDidMount() {
    const { startDate, endDate } = this.props;
    const duration = getDuration(startDate, endDate);
    const metaText = (
      <span
        {...css({
          fontWeight: 300,
          color: colors.ff,
        })}
      >
        Duration: {duration.asDays} day{duration.asDays > 1 && "s"}
      </span>
    );
    this.setState({ metaText });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.startDate !== this.props.startDate) {
      if (Moment(this.props.startDate).isSameOrAfter(this.props.endDate)) {
        this.props.setDate(
          Moment(this.props.startDate).add(1, "day").toISOString(),
          "endDate"
        );
      }
    }
    if (prevProps.endDate !== this.props.endDate) {
      if (Moment(this.props.startDate).isSameOrAfter(this.props.endDate)) {
        this.props.setDate(
          Moment(this.props.endDate).subtract(1, "day").toISOString(),
          "startDate"
        );
      }
    }

    if (
      prevProps.startDate !== this.props.startDate ||
      prevProps.endDate !== this.props.endDate ||
      prevProps.allDay !== this.props.allDay
    ) {
      const { startDate, endDate } = this.props;
      const duration = getDuration(startDate, endDate);
      let metaText;
      if (Moment(endDate).isBefore(startDate)) {
        metaText = (
          <span
            {...css({
              fontWeight: 500,
              color: colors.red,
            })}
          >
            End date can't be before start date
          </span>
        );
        this.setState({ metaText });
      } else if (Moment(endDate).isSame(startDate, "day")) {
        metaText = (
          <span
            {...css({
              fontWeight: 500,
              color: colors.red,
            })}
          >
            End date can't be equal to start date
          </span>
        );
        this.setState({ metaText });
      } else {
        metaText = (
          <span
            {...css({
              fontWeight: 300,
              color: colors.ff,
            })}
          >
            Duration: {duration.asDays} day{duration.asDays > 1 && "s"}
          </span>
        );
        this.setState({ metaText });
      }
    }
  }

  render() {
    const {
      allDay,
      startDate,
      endDate,
      setDate,
      disabled,
      timezone,
      customStyle,
    } = this.props;
    const { metaText } = this.state;

    const metaBox = {
      float: "left",
      width: "100%",
      height: 20,
      lineHeight: "20px",
      fontSize: 14,
    };

    return (
      <div>
        <div
          {...css({ float: "left", width: "100%", marginBottom: 24 })}
          style={customStyle}
        >
          <div {...css({ float: "left", width: "100%" })}>
            <div
              {...css(
                media("(max-width: 500px)", {
                  display: "none",
                })
              )}
            >
              <DayPicker
                label="Start date"
                customStyle={{
                  ...css(
                    {
                      float: "left",
                      width: "calc(50% - 8px)",
                      marginRight: 16,
                    },
                    media("(max-width: 500px)", {
                      width: "calc(50% - 6px)",
                      marginRight: 12,
                    })
                  ),
                }}
                value={Moment(startDate).format("dddd, D MMM YYYY")}
                setDate={(date) => {
                  const newDate = Moment.tz(
                    Moment(date).format("DD-MM-YYYY, HH:mm"),
                    "DD-MM-YYYY, HH:mm",
                    timezone
                  );
                  setDate(newDate.toISOString(), "startDate");
                }}
                error={!this.state.valid}
                disabled={disabled}
                calendarStyle={{ left: 0 }}
              />{" "}
              <DayPicker
                label="End date"
                customStyle={{
                  ...css(
                    { float: "left", width: "calc(50% - 8px)" },
                    media("(max-width: 500px)", {
                      width: "calc(50% - 6px)",
                    })
                  ),
                }}
                value={Moment(endDate)
                  .subtract(1, "day")
                  .format("dddd, D MMM YYYY")}
                setDate={(date) => {
                  const newDate = Moment.tz(
                    Moment(date).add(1, "day").format("DD-MM-YYYY, HH:mm"),
                    "DD-MM-YYYY, HH:mm",
                    timezone
                  );
                  setDate(newDate.toISOString(), "endDate");
                }}
                error={!this.state.valid}
                disabled={disabled}
                calendarStyle={{ right: 0 }}
              />
            </div>
          </div>
          <div {...css(metaBox)}>{metaText}</div>
        </div>
      </div>
    );
  }
}

export default DatePicker;

function getNewDate(time, date) {
  let hours = 0;
  let minutes = 0;
  if (time && time.length > 4) {
    hours = time.slice(0, 2);
    minutes = time.slice(3, 5);
  }
  return Moment(date).set({
    hours,
    minutes,
  });
}
